import React from "react";
import {
  LazyChangeEmail,
  LazyChangePassword,
  LazyChangeUsername,
  LazyConfirmNumber,
  LazyPhoneNumber,
  LazySecurity,
} from "../lazy/loader";

export const AuthScreens = ({ Navigator }: { Navigator: any }) => {
  return (
    <React.Fragment>
      <Navigator.Screen name="Security" component={LazySecurity} />
      <Navigator.Screen name="ChangePassword" component={LazyChangePassword} />
      <Navigator.Screen name="ChangeEmail" component={LazyChangeEmail} />
      <Navigator.Screen name="ChangeUsername" component={LazyChangeUsername} />
      <Navigator.Screen name="PhoneNumber" component={LazyPhoneNumber} />
      <Navigator.Screen name="ConfirmNumber" component={LazyConfirmNumber} />
    </React.Fragment>
  );
};

import { useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Image, StyleSheet, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { PageBody } from "src/pages/layout/pageBody";
import { languagePreference } from "src/redux/features/misc/slice";
import {
  SignUpCredentials,
  checkUsername,
  error,
  isFulfilled,
  setData,
  signupUser,
} from "src/redux/features/signUp/slice";
import { vars } from "src/styles";
import { StyledText, textStyles } from "src/styles/text";
import { CustomButton, CustomTextInput } from "src/uikit";
import CustomIcon from "src/uikit/CustomIcon/CustomIcon";
import * as Yup from "yup";
import logo from "../../../../assets/vertical.svg";
import formStyles from "../../../components/forms/authForm.styles";
import useStyles from "../common.styles";
import authStyles from "../layout/auth.styles";
import { passwordRegex, passwordStrengthMessage } from "../utils";

export default function SignUpScreen1({ navigation }: { navigation: any }) {
  const { colors } = useTheme();
  const commonStyles = useStyles();
  const dispatch = useDispatch();
  const language = useSelector(languagePreference);
  const err = useSelector(error);
  const fulfilled = useSelector(isFulfilled);
  const [usernameAvailable, setUsernameAvailable] = useState<
    undefined | boolean
  >(undefined);
  const passwordRequiredText = "Required";

  const initialValues = {
    fullname: "",
    username: "",
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()
        .max(50, "Must be 50 characters or less")
        .min(2, "Must be 2 characters or more")
        .required("Required"),
      username: Yup.string()
        .max(50, "Must be 50 characters or less")
        .min(2, "Must be 2 characters or more")
        .required("Required")

        .matches(
          /^[a-zA-Z0-9_]*$/,
          "Username can only contain letters, numbers and underscores",
        ),
      email: Yup.string().email("Invalid email").required("Required"),
      password: Yup.string()
        .required("Required")
        .matches(passwordRegex, passwordStrengthMessage)
        .required(passwordRequiredText),
    }),
    onSubmit: () => {
      if (usernameAvailable === true) {
        const signUpCreds: SignUpCredentials = {
          ...{ language },
          ...formik.values,
        };
        dispatch(setData(formik.values));
        dispatch(signupUser(signUpCreds));
      }
    },
  });

  useEffect(() => {
    if (fulfilled) {
      navigation.navigate("SignUpConfirmation");
    }
  }, [fulfilled, navigation]);

  const checkUserNameAvailability = async (text: string) => {
    if (text?.length < 5) {
      setUsernameAvailable(undefined);
      return;
    }
    const result = await dispatch(checkUsername(text));
    if ((result as any).payload === true) {
      setUsernameAvailable(true);
    } else {
      setUsernameAvailable(false);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <View>
      <LinearGradient
        colors={[colors.primaryBackground, colors.secondaryBackground]}
        start={{
          x: 0,
          y: 0,
        }}
        end={{
          x: 0,
          y: 1,
        }}
        style={{ height: "100%" }}
      >
        <WebFadeIn background={false}>
          <PageBody style={{ margin: "auto" }}>
            <View style={[authStyles.authPage]} testID="auth-page">
              <View>
                <Image
                  source={logo}
                  style={{
                    height: 122,
                    width: 81,
                  }}
                />
              </View>
              <View style={[formStyles.form]}>
                <StyledText
                  textStyle={{ color: colors.text }}
                  style={commonStyles.pageTitle}
                  variant="h1"
                >
                  Sign Up
                </StyledText>
                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    style={[formStyles.input, textStyles.inputText]}
                    testID="fullname"
                    placeholder="Full name"
                    onChangeText={formik.handleChange("fullname")}
                    onBlur={formik.handleBlur("fullname")}
                    value={formik.values.fullname}
                    onKeyPress={handleKeyDown}
                    autoCompleteType={"off"}
                    errorMessage={
                      formik.touched.fullname && formik.errors.fullname
                    }
                    icon={<CustomIcon iconName={"account-box-outline"} />}
                  />
                </View>
                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    testID="username"
                    placeholder="Username"
                    onChangeText={(e) => {
                      formik.handleChange("username")(e);
                      checkUserNameAvailability(e);
                    }}
                    onBlur={formik.handleBlur("username")}
                    value={formik.values.username}
                    onKeyPress={handleKeyDown}
                    autoCompleteType={"off"}
                    errorMessage={
                      formik.touched.username && formik.errors.username
                    }
                    icon={<CustomIcon iconName={"account-box-outline"} />}
                  />
                  {usernameAvailable === true && formik.isValid && (
                    <StyledText
                      style={formStyles.underFieldMessage}
                      variant="body1ok"
                      testID={"Available"}
                    >
                      Available
                    </StyledText>
                  )}
                  {usernameAvailable === false && (
                    <StyledText
                      style={formStyles.underFieldMessage}
                      variant="body1error"
                      testID={"Unavailable"}
                    >
                      Unavailable
                    </StyledText>
                  )}
                </View>

                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    testID="email"
                    placeholder="Email"
                    onChangeText={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    value={formik.values.email}
                    onKeyPress={handleKeyDown}
                    autoCompleteType={"off"}
                    errorMessage={formik.touched.email && formik.errors.email}
                    icon={<CustomIcon iconName={"email-outline"} />}
                  />
                </View>
                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    testID="password"
                    placeholder="Password"
                    onChangeText={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    value={formik.values.password}
                    onKeyPress={handleKeyDown}
                    autoCompleteType={"off"}
                    secureTextEntry
                    errorMessage={
                      formik.touched.password && formik.errors.password
                    }
                    icon={<CustomIcon iconName={"lock-outline"} />}
                  />
                </View>

                <View
                  style={[formStyles.fieldContainer, authStyles.spacedButton]}
                >
                  <CustomButton
                    disabled={!formik.isValid}
                    style={{ opacity: formik.isValid ? 1 : 0.4 }}
                    onPress={() => formik.handleSubmit()}
                    testID={"SignUp"}
                    title={"Sign up"}
                  />
                  <View>
                    <Text>
                      {err ? (
                        <>
                          <Text>
                            {err as string} Please choose a different email or{" "}
                          </Text>
                          <Text
                            style={styles.loginNavigator}
                            onPress={() => navigation.navigate("Login")}
                          >
                            Click here
                          </Text>
                          <Text> to login</Text>
                        </>
                      ) : (
                        ""
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </PageBody>
        </WebFadeIn>
      </LinearGradient>
    </View>
  );
}
const styles = StyleSheet.create({
  loginNavigator: {
    color: vars.primaryColor,
    cursor: "pointer",
  },
});

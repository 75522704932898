import { StyleSheet } from "react-native";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  tile: {
    // width: "47%",
    maxWidth: 402,
    maxHeight: 119,
    height: 119,
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 20,
    marginBottom: 10,
    marginTop: 10,
    backgroundColor: vars.white,
    borderRadius: 16,
    padding: 20,
    flexDirection: "row",
  },
  tileText: {
    lineHeight: 18.2,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "left",
    flexWrap: "wrap",
  },
  icon: {
    height: 110,
    width: 72,
    marginBottom: 30,
  },
  elevation: {
    elevation: 20,
    shadowColor: "red",
  },
});

export default styles;

import { StyleSheet } from "react-native";
import { maxPageWidth } from "src/components/forms/form.styles";
import { mobileBreak } from "src/navigation/header/drawerNavOptions.styles";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  checkboxes: {
    paddingVertical: 10,
    maxWidth: maxPageWidth > mobileBreak ? maxPageWidth : undefined,
    minWidth: 100,
    width: "100%",
  },
  checkbox: {
    paddingVertical: 6,
  },
  checkboxBorder: {
    borderColor: vars.primaryColor,
    alignItems: "flex-start",
  },
  checkboxCheckedBorder: {
    borderColor: "#EEF1F5",
    alignItems: "flex-start",
  },
  textStyle: {
    color: vars.black,
  },
  textChecked: {
    color: vars.primaryColor,
  },
});

export default styles;

import { Dimensions, StyleSheet } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { vars } from "src/styles";
import { themeDefault } from "src/theme";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    navOption: {
      backgroundColor: "#ffffff",
      borderRadius: 20,
      flexDirection: "row",
      alignItems: "center",
      alignContent: "space-between",
      padding: 10,
      marginHorizontal: 0,
      marginVertical: 10,
      minHeight: 50,
      width: windowWidth > mobileBreak ? "47%" : "100%",
      maxWidth: 402,
      maxHeight: 120,
      shadowColor: vars.lightBlue,
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 14,
      elevation: 8,
    },
    icon: {
      width: 24,
    },
    row: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
    },
    resourceImage: {
      width: 100,
      height: 100,
    },
    title: {
      color: themeDefault.colors.text,
      fontSize: 18,
    },
    textView: {
      height: 90,
      flex: 1,
      flexDirection: "column",
      justifyContent: "center", // ..will be "space-between" when more content on card r.g. progress bar
    },
    categoryView: {
      flexDirection: "row",
    },
    categoryText: {
      marginRight: 4,
    },
    textViewPadding: {
      height: 100,
      marginLeft: 10,
    },
  });
};

export default styles;

import { Dimensions, StyleSheet } from "react-native";
import { maxPageWidth } from "src/components/forms/form.styles";
import {
  avgDeviceSize,
  mobileBreak,
} from "src/navigation/header/drawerNavOptions";
import { useTheme } from "@react-navigation/native";

const styles = () => {
  const { colors } = useTheme();
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    page: {
      backgroundColor: "#ffffff",
      padding: 20,
    },
    backgroundImage: {
      height: "100%",
      backgroundColor: "#F6F8FC",
    },
    form: {
      margin: 20,
    },
    pageItems: {
      flexDirection: "row",
      maxWidth: maxPageWidth,
      flexWrap: "wrap",
      justifyContent: "space-between",
    },
    itemPage: {
      flexGrow: 1,
      zIndex: 999,
      borderWidth: 0,
      paddingVertical: windowWidth > mobileBreak ? 0 : 30,
      paddingHorizontal: windowWidth > avgDeviceSize ? 0 : 20,
      paddingTop: 0,
    },
    itemPageHeader: {
      backgroundColor: "#ffffff",
      marginTop: -45,
      borderTopStartRadius: 30,
      borderTopEndRadius: 30,
      paddingHorizontal: windowWidth > avgDeviceSize ? 100 : 20,
      paddingTop: windowWidth > mobileBreak ? 0 : 30,
    },
    styledText: {
      maxWidth: "100%",
    },
    cardShadow: {
      shadowColor: colors.primary,
      shadowOffset: { width: -6, height: 8 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 8,
    },
  });
};

export default styles;

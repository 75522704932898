import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ActivityIndicator, View, useWindowDimensions } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import FadeIn from "src/components/fadeIn/fadeIn";
import useStyles from "src/components/forms/form.styles";
import {
  default as styles,
  default as useNavOptionStyles,
} from "src/components/navOption/navOption.styles";
import ResourceEditNavOption from "src/components/resourceEditNavOption/resourceEditNavOption";
import ResourceViewNavOption from "src/components/resourceViewNavOption/resourceViewNavOption";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { BasicParams } from "src/redux/domain/features/adminViewUser/useAdminViewUserSearchCollection";
import { vars } from "src/styles";
import { StyledText } from "src/styles/text";
import { useTranslation } from "src/translations/useTranslation";
import { QuizProgress } from "src/uikit";
import { initialLoadSize, rootLanguage } from "src/utils";
import { PageBody } from "./pageBody";

export const ListPageBody = ({
  navigation,
  route,
  listItems,
  loading,
  style,
  basicParams,
  loadMore,
  doLoad,
  TextSearch,
  orderBy,
  iconPlaceHolder,
  destination,
  mode = "edit",
  paramKey,
  showIcon = false,
  getIconPath,
  languageSorting = true,
}: {
  navigation: any;
  route: any;
  listItems?: any[];
  loading: boolean;
  style: any;
  basicParams?: BasicParams;
  loadMore: () => void;
  doLoad: (p: BasicParams) => void;
  TextSearch?: ({
    // eslint-disable-next-line no-shadow
    navigation,
    disabled,
    loadAdminViewUsers,
    placeholder,
    // eslint-disable-next-line no-shadow
    loadSize,
    // eslint-disable-next-line no-shadow
    style,
  }: {
    navigation: any;
    disabled?: boolean | undefined;
    loadAdminViewUsers: (a: any) => void;
    placeholder?: string | undefined;
    loadSize?: number | undefined;
    style?: any;
    // eslint-disable-next-line no-undef
  }) => JSX.Element;
  orderBy: string[];
  iconPlaceHolder?: string;
  destination: string;
  mode?: "edit" | "view";
  paramKey: string;
  showIcon?: boolean;
  getIconPath?: (listItem: any) => string;
  languageSorting?: boolean;
}) => {
  const navOptionStyles = useNavOptionStyles();
  const formStyles = useStyles();
  const [loadingDelay, setLoadingDelay] = useState(false);
  const [canStopLoading, setCanStopLoading] = useState(false);
  const { width } = useWindowDimensions();
  const { text } = useTranslation();
  const scrollableRef = useRef<KeyboardAwareScrollView>(null);
  const lastSearchRef = useRef<BasicParams | undefined>();

  useEffect(() => {
    if (loading && (!listItems || listItems?.length === 0)) {
      setLoadingDelay(true);
      setTimeout(() => {
        //todo: check loading here and if still loading do recursive set delay
        // setLoadingDelay(false);
        setCanStopLoading(true);
      }, 800);
    }

    if (!loading && canStopLoading) {
      setLoadingDelay(false);
      setCanStopLoading(false);
    }
  }, [loading, listItems, canStopLoading]);

  //TODO: factor out to utils
  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }: {
    layoutMeasurement: { height: number };
    contentOffset: { y: number };
    contentSize: { height: number };
  }) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  //todo: stop catering for different options here (quick fixes need undoing)!
  const getText = (user: any) => {
    const _originalText = `${
      user.Title || user.Name || user.Text || user.Question
    }${user?.email ? "\r\n" + user.email : ""}`;
    let _text = _originalText?.substring(0, 200);
    if (_originalText?.length > 200) {
      _text += "...";
    }
    return _text;
  };

  // eslint-disable-next-line no-undef
  const getSubText = (item: any): string | JSX.Element | undefined => {
    if (item?.category?.Name) {
      return (
        <>
          <StyledText variant="body1" style={styles.mr}>
            {text("categoryViewList.title")}:
          </StyledText>
          <StyledText variant="body1black">{item.category.Name}</StyledText>
        </>
      );
    } else if (item?.quizResults) {
      return (
        <QuizProgress
          total={item?.quizResults?.TotalQuizes}
          progress={item?.quizResults?.PassedQuizes}
        />
      );
    } else if (
      item?.Introduction ||
      item?.Description ||
      item?.Text ||
      item?.Article
    ) {
      const text =
        item?.Introduction || item?.Description || item?.Text || item?.Article;
      return (
        <>
          <StyledText
            variant="body1truncated"
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {text.replace(/\n/g, " ")}
          </StyledText>
        </>
      );
    }
  };

  const Spinner: React.FunctionComponent = () => {
    return (
      <>
        {loadingDelay && (
          <View
            style={{
              flex: 1,
              justifyContent: "flex-start",
            }}
          >
            <ActivityIndicator
              size="large"
              color={vars.primaryColor}
              style={{ marginTop: 50 }}
            />
          </View>
        )}
      </>
    );
  };

  const renderListItem = (listItem: any) => {
    const props = {
      key: listItem.Id,
      text: getText(listItem),
      subText: getSubText(listItem),
      navigation,
      destination,
      iconPath: getIconPath ? getIconPath(listItem) : undefined,
      iconPlaceHolder,
      params: {
        ...route.params,
        ...{ [paramKey]: listItem.Id },
      },
      showIcon,
    };

    return mode === "edit" ? (
      <ResourceEditNavOption {...props} />
    ) : (
      <ResourceViewNavOption {...props} showIcon={false} />
    );
  };

  return (
    <>
      <View
        style={[
          formStyles.form,
          styles.navOptionsListOnly,
          {
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          },
        ]}
      >
        {TextSearch && (
          <TextSearch
            navigation={navigation}
            loadAdminViewUsers={doLoad}
            loadSize={initialLoadSize}
            style={{
              container: { maxWidth: width > mobileBreak ? "49%" : "100%" },
            }}
          />
        )}
      </View>
      <PageBody
        style={{ style }}
        scrollableRef={scrollableRef}
        keyboardAwareScrollViewProps={{
          onScroll: (a: any) => {
            if (isCloseToBottom(a.nativeEvent)) {
              if (
                !_.isEqual(
                  {
                    offset: listItems?.length!,
                    limit: basicParams?.limit,
                    filter: basicParams?.filter,
                  },
                  basicParams,
                )
              ) {
                loadMore();
              }
              lastSearchRef.current = basicParams;
            }
          },
        }}
      >
        <>
          <Spinner />
          {!loadingDelay && listItems && (
            <FadeIn
              style={[
                formStyles.form,
                navOptionStyles.navOptions,
                { justifyContent: "center" },
              ]}
            >
              {listItems
                .filter((o) =>
                  languageSorting ? o.Language === rootLanguage : true,
                )
                ?.map(renderListItem)}
            </FadeIn>
          )}
        </>
      </PageBody>
    </>
  );
};

import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../auth/slice";
import { clearUserInfoItems, getUserInfo, getUserInfoSelector } from "./slice";

export const useUserInfo = () => {
  const dispatch = useDispatch();

  // const loading = useSelector(userInfoLoading);
  let resAuth = useSelector(authSelector) || undefined;
  let resInfo = useSelector(getUserInfoSelector) || {};

  const getInfo = useCallback(async () => {
    await dispatch(clearUserInfoItems());
    await dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (!resAuth?.userId && !resInfo?.userId) {
      getInfo();
    }
  }, [getInfo, resAuth, resAuth?.userId, resInfo?.userId]);

  return {
    userId: resAuth.userId || resInfo.userId,
    public_username: resAuth.username || resInfo.public_username,
    roles: resAuth.roles || resInfo.roles,
    language: resAuth.language || resInfo.language,
    userTheme: resAuth.userTheme || resInfo.userTheme,
    name: resAuth?.name || resInfo.name,
    getUserInfo: getInfo,
  };
};

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  qrText: {
    marginTop: 14,
    marginBottom: 10,
  },
});

export default styles;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { QuizPublishedCollectionState } from "./quiz-published-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Quizpublished } from "../../../../../../types/domain/flat-types";
const initialState: QuizPublishedCollectionState = {
  items: undefined,
  status: "idle",
};

export const getQuizPublisheds = createAsyncThunk(
  "get/quiz-publisheds",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-published?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchQuizPublisheds = createAsyncThunk(
  "get/quiz-publisheds",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`quiz-published-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getQuizPublished = createAsyncThunk(
  "get/quiz-published",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-published/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const quizPublishedsSlice = createSlice({
  name: "quizPublisheds",
  initialState,
  reducers: {
    clearQuizPublishedItems(state: QuizPublishedCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizPublisheds.pending || searchQuizPublisheds.pending, (state: QuizPublishedCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getQuizPublisheds.fulfilled || searchQuizPublisheds.fulfilled, (state: QuizPublishedCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getQuizPublisheds.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getQuizPublished.pending, (state: QuizPublishedCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getQuizPublished.fulfilled, (state: QuizPublishedCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getQuizPublished.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const quizPublishedSelector = (id: number) => (state: AppState) => {
  return state.quizPublisheds?.items?.find((o) => o.Id === id);
}; 

export const quizPublishedLoading = createSelector(
  (state: AppState) => state.quizPublisheds.status,
  status => status === 'pending'
);

export const quizPublishedErrorSelector = createSelector(
  (state: AppState) => state.quizPublisheds,
  status => status.error
);


export const quizPublishedsSelector = createSelector(
  (state: AppState) => state.quizPublisheds,
  state => state.items
);

export const quizPublishedsLoading = createSelector(
  (state: AppState) => state.quizPublisheds.status,
  status => status === 'pending'
);

export const quizPublishedsErrorSelector = createSelector(
  (state: AppState) => state.quizPublisheds,
  status => status.error
);

export const { clearQuizPublishedItems } = quizPublishedsSlice.actions;

export default quizPublishedsSlice.reducer;

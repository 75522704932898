import React from "react";
import { View } from "react-native";
import styles from "./customCheckbox.styles";
import CustomButton, { CustomButtonTheme } from "../customButton/customButton";
import { vars } from "src/styles";
import { useTheme } from "@react-navigation/native";

export interface CheckBoxItem {
  Id: number;
  Value: string | number;
}

interface Props {
  items: CheckBoxItem[];
  selectedItems: CheckBoxItem[];
  theme?: CustomButtonTheme;
  onSelect: (item: CheckBoxItem) => void;
  style: any;
}

const CustomCheckbox: React.FunctionComponent<Props> = ({
  items,
  selectedItems,
  onSelect,
  theme = "primary",
  style,
}) => {
  const { colors } = useTheme();
  return (
    <View style={[styles.checkboxes]}>
      {items.map((item: CheckBoxItem, key: number) => (
        <View key={key} style={styles.checkbox}>
          <CustomButton
            title={item.Value as string}
            onPress={() => onSelect(item)}
            icon={
              selectedItems.find((i) => i.Id === item.Id)
                ? "checked-filled"
                : "checked-outline"
            }
            theme={theme}
            position="left"
            withOpacity={false}
            textStyle={
              selectedItems.find((i) => i.Id === item.Id)
                ? [styles.textChecked, { color: colors.primary }]
                : styles.textStyle
            }
            iconColor={
              selectedItems.find((i) => i.Id === item.Id)
                ? colors.primary
                : "#A6B1C2"
            }
            style={
              selectedItems.find((i) => i.Id === item.Id)
                ? [
                    styles.checkboxBorder,
                    { borderColor: colors.primary },
                    style,
                  ]
                : [styles.checkboxCheckedBorder, style]
            }
          />
        </View>
      ))}
    </View>
  );
};

export default CustomCheckbox;

import React from "react";
import { View, Text, Platform } from "react-native";
import usePageStyles from "../../layout/page.styles";
import { useDispatch, useSelector } from "react-redux";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { PageHeader } from "../../layout/pageheader";
import useStyles from "src/components/forms/form.styles";
import { PageBody } from "src/pages/layout/pageBody";
import { useSnackBar } from "src/components/snackBar/snackBar";
import { useTranslation } from "src/translations/useTranslation";
import { CustomButton } from "src/uikit/index";
import { deleteAccountAction } from "src/redux/features/auth/account-slice";

const DeleteAccountScreen = (props: any) => {
  const { navigation, route } = props;
  const pageStyles = usePageStyles();
  const formStyles = useStyles();
  const dispatch = useDispatch();
  const { Snack } = useSnackBar();
  const { t } = useTranslation();

  function deleteAccount() {
    dispatch(deleteAccountAction(route?.params?.userId));
  }

  return (
    <WebFadeIn background={Platform.OS === "android" ? false : true}>
      <View style={pageStyles.page}>
        <PageHeader
          title={t("security.deleteTitle")}
          style={[formStyles.form]}
          navigation={navigation}
        />
        <PageBody>
          <View
            style={{
              flex: 1,
              alignItems: "center",
              width: "90%",
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 24,
                  fontWeight: "500",
                  color: "#FB5951",
                  lineHeight: 50,
                }}
              >
                {t("security.deleteTitle")}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "400",
                  lineHeight: 50,
                }}
              >
                {t("security.deleteWarning")}
              </Text>
            </View>
            <View style={{ width: "95%" }}>
              <CustomButton
                disabled={true}
                title={t("security.deleteCheckListText")}
                icon={"checked-filled"}
                position="left"
                withOpacity={false}
                textStyle={{
                  fontSize: 16,
                  fontWeight: "400",
                  lineHeight: 50,
                  color: "#A6B1C2",
                }}
                iconColor={"#FB5951"}
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#fff",
                  marginBottom: 32,
                }}
              />
            </View>
            <View style={{ marginBottom: 10, width: "95%" }}>
              <CustomButton
                onPress={deleteAccount}
                title={t("security.deleteTitle")}
                style={{
                  backgroundColor: "#FB5951",
                  borderColor: "#FB5951",
                }}
              />
            </View>
            <View style={{ width: "95%" }}>
              <CustomButton
                onPress={() => navigation.navigate("PersonalDetails")}
                title={t("security.deleteCancelBtn")}
                theme="secondary"
              />
            </View>
          </View>
        </PageBody>
        <Snack />
      </View>
    </WebFadeIn>
  );
};

export default DeleteAccountScreen;

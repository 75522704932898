
import { default as adminViewUsers } from './adminViewUser/collection-slice';
import { default as settingss } from './settings/collection-slice';
import { default as languages } from './language/collection-slice';
import { default as lessons } from './lesson/collection-slice';
import { default as lessonResources } from './lessonResource/collection-slice';
import { default as categoryAssignments } from './categoryAssignment/collection-slice';
import { default as categorys } from './category/collection-slice';
import { default as quizs } from './quiz/collection-slice';
import { default as quizQuestions } from './quizQuestion/collection-slice';
import { default as quizAnswers } from './quizAnswer/collection-slice';
import { default as quizAttempts } from './quizAttempt/collection-slice';
import { default as quizAttemptAnswers } from './quizAttemptAnswer/collection-slice';
import { default as tokens } from './token/collection-slice';
import { default as lessonPublisheds } from './lessonPublished/collection-slice';
import { default as lessonPublishedFulls } from './lessonPublishedFull/collection-slice';
import { default as topics } from './topic/collection-slice';
import { default as topicResources } from './topicResource/collection-slice';
import { default as categoryPublisheds } from './categoryPublished/collection-slice';
import { default as categoryPublishedFulls } from './categoryPublishedFull/collection-slice';
import { default as topicPublisheds } from './topicPublished/collection-slice';
import { default as topicPublishedFulls } from './topicPublishedFull/collection-slice';
import { default as quizPublisheds } from './quizPublished/collection-slice';
import { default as quizAnswerPublics } from './quizAnswerPublic/collection-slice';
import { default as categoryResources } from './categoryResource/collection-slice';
import { default as quizAttemptResults } from './quizAttemptResult/collection-slice';
import { default as lessonPublishedByCategorys } from './lessonPublishedByCategory/collection-slice';
import { default as lessonPublishedByCategoryFulls } from './lessonPublishedByCategoryFull/collection-slice';
import { default as lessonMediaKeys } from './lessonMediaKey/collection-slice';
import { default as topicMediaKeys } from './topicMediaKey/collection-slice';
import { default as categoryMediaKeys } from './categoryMediaKey/collection-slice';
import { default as progressStatss } from './progressStats/collection-slice';
import { default as customerEmails } from './customerEmail/collection-slice';
import { default as customerEmailResources } from './customerEmailResource/collection-slice';
import { default as customerEmailMediaKeys } from './customerEmailMediaKey/collection-slice';

export default {
  adminViewUsers,
  settingss,
  languages,
  lessons,
  lessonResources,
  categoryAssignments,
  categorys,
  quizs,
  quizQuestions,
  quizAnswers,
  quizAttempts,
  quizAttemptAnswers,
  tokens,
  lessonPublisheds,
  lessonPublishedFulls,
  topics,
  topicResources,
  categoryPublisheds,
  categoryPublishedFulls,
  topicPublisheds,
  topicPublishedFulls,
  quizPublisheds,
  quizAnswerPublics,
  categoryResources,
  quizAttemptResults,
  lessonPublishedByCategorys,
  lessonPublishedByCategoryFulls,
  lessonMediaKeys,
  topicMediaKeys,
  categoryMediaKeys,
  progressStatss,
  customerEmails,
  customerEmailResources,
  customerEmailMediaKeys,
};

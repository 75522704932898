import React from "react";
import { Image, View } from "react-native";
import logo from "../../../assets/horizontal.png";

const Logo: React.FunctionComponent<{
  iconColor?: string;
  iconWidth?: string;
  iconHeight?: string;
}> = ({ iconColor, iconWidth, iconHeight }) => {
  return (
    <View>
      <Image
        source={logo}
        style={{
          height: iconHeight || 45,
          width: iconWidth || 86,
        }}
      />
    </View>
  );
};

export default Logo;

import { Dimensions, Platform, StyleSheet } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { vars } from "src/styles";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    dropdown: {
      paddingVertical: 10,
      paddingHorizontal: windowWidth > mobileBreak ? 10 : 0,
      minWidth: 100,
      zIndex: 9999,
    },
    label: {
      color: "#C3C0D1",
    },
    dwopdown: {
      // backgroundColor: 'red',
      borderWidth: 0,
      flexDirection: "row",
      alignItems: "center",
    },
    containerStyle: {
      paddingHorizontal:
        windowWidth > mobileBreak ? 4 : Platform.OS === "web" ? 14 : 4,
    },
    textStyle: {},
    labelStyle: {
      fontSize: 16,
      color: vars.black,
    },
    dropdownContainerStyle: {
      borderWidth: 0.5,
      borderRadius: 0,
      borderColor: "#ddd",
      backgroundColor: vars.white,
      padding: 10,
    },
    modalContentContainerStyle: {
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      alignSelf: "center",
      width: "70%",
    },
    listItemContainerStyle: {
      flexDirection: "row",
      minHeight: 20,
    },
    listItemLabelStyle: {
      flex: 0,
      flexBasis: "auto",
      flexDirection: "row",
      marginRight: 10,
      paddingTop: 2,
    },
    selectListItem: {},
    iconStyle: {
      justifyContent: "flex-start",
      width: 20,
      height: 20,
    },
    closeIconStyle: {
      justifyContent: "flex-end",
    },
  });
};

export default styles;

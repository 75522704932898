import React from "react";
import { Path } from "react-native-svg";

const Camera: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M17.24 22.75H6.75999C3.95999 22.75 2.17999 21.08 2.01999 18.29L1.49999 10.04C1.41999 8.79 1.84999 7.59 2.70999 6.68C3.55999 5.77 4.75999 5.25 5.99999 5.25C6.31999 5.25 6.62999 5.06 6.77999 4.76L7.49999 3.33C8.08999 2.16 9.56999 1.25 10.86 1.25H13.15C14.44 1.25 15.91 2.16 16.5 3.32L17.22 4.78C17.37 5.06 17.67 5.25 18 5.25C19.24 5.25 20.44 5.77 21.29 6.68C22.15 7.6 22.58 8.79 22.5 10.04L21.98 18.3C21.8 21.13 20.07 22.75 17.24 22.75ZM10.86 2.75C10.12 2.75 9.17999 3.33 8.83999 4L8.11999 5.44C7.69999 6.25 6.88999 6.75 5.99999 6.75C5.15999 6.75 4.37999 7.09 3.79999 7.7C3.22999 8.31 2.93999 9.11 2.99999 9.94L3.51999 18.2C3.63999 20.22 4.72999 21.25 6.75999 21.25H17.24C19.26 21.25 20.35 20.22 20.48 18.2L21 9.94C21.05 9.11 20.77 8.31 20.2 7.7C19.62 7.09 18.84 6.75 18 6.75C17.11 6.75 16.3 6.25 15.88 5.46L15.15 4C14.82 3.34 13.88 2.76 13.14 2.76H10.86V2.75Z"
        fill={props?.iconColor}
      />
      <Path
        d="M13.5 8.75H10.5C10.09 8.75 9.75 8.41 9.75 8C9.75 7.59 10.09 7.25 10.5 7.25H13.5C13.91 7.25 14.25 7.59 14.25 8C14.25 8.41 13.91 8.75 13.5 8.75Z"
        fill={props?.iconColor}
      />
      <Path
        d="M12 18.75C9.79 18.75 8 16.96 8 14.75C8 12.54 9.79 10.75 12 10.75C14.21 10.75 16 12.54 16 14.75C16 16.96 14.21 18.75 12 18.75ZM12 12.25C10.62 12.25 9.5 13.37 9.5 14.75C9.5 16.13 10.62 17.25 12 17.25C13.38 17.25 14.5 16.13 14.5 14.75C14.5 13.37 13.38 12.25 12 12.25Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default Camera;

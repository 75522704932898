/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { QuizQuestionCollectionState } from "./quiz-question-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Quizquestion } from "../../../../../../types/domain/flat-types";
const initialState: QuizQuestionCollectionState = {
  items: undefined,
  status: "idle",
};

export const getQuizQuestions = createAsyncThunk(
  "get/quiz-questions",
  async (options?: { quiz?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-question?quiz=${options?.quiz}&language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchQuizQuestions = createAsyncThunk(
  "get/quiz-questions",
  async ({ quiz, search, language, offset, limit, filter }: { quiz?: number, search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`quiz-question-search?quiz=${quiz}&language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);



  export const createQuizQuestion = createAsyncThunk(
    "post/quiz-question",
    async (quizQuestion: Partial<Quizquestion>) => {
      const client = await fetchClient();
      const { data } = await client.post(`quiz-question/`, { quizQuestion });
      return data;
    }
  );


export const updateQuizQuestion = createAsyncThunk(
  "put/quiz-question",
  async (quizQuestion: Partial<Quizquestion>) => {
    const client = await fetchClient();
    const { data } = await client.put(`quiz-question/`, { quizQuestion });
    return data;
  }
);

export const getQuizQuestion = createAsyncThunk(
  "get/quiz-question",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-question/${id}`);
    return data;
  }
);

export const deleteQuizQuestion = createAsyncThunk(
  "delete/quiz-question",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`quiz-question/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: QuizQuestionCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Quiz : undefined;

//   if(currentStateConstraint && actionArgs.quiz && Number(actionArgs.quiz) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Quiz : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const quizQuestionsSlice = createSlice({
  name: "quizQuestions",
  initialState,
  reducers: {
    clearQuizQuestionItems(state: QuizQuestionCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizQuestions.pending || searchQuizQuestions.pending, (state: QuizQuestionCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getQuizQuestions.fulfilled || searchQuizQuestions.fulfilled, (state: QuizQuestionCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getQuizQuestions.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getQuizQuestion.pending, (state: QuizQuestionCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteQuizQuestion.pending, (state: QuizQuestionCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateQuizQuestion.pending, (state: QuizQuestionCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getQuizQuestion.fulfilled, (state: QuizQuestionCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteQuizQuestion.fulfilled, (state: QuizQuestionCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateQuizQuestion.fulfilled, (state: QuizQuestionCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createQuizQuestion.pending, (state: QuizQuestionCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createQuizQuestion.fulfilled, (state: QuizQuestionCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getQuizQuestion.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteQuizQuestion.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateQuizQuestion.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const quizQuestionSelector = (id: number) => (state: AppState) => {
  return state.quizQuestions?.items?.find((o) => o.Id === id);
}; 

export const quizQuestionLoading = createSelector(
  (state: AppState) => state.quizQuestions.status,
  status => status === 'pending'
);

export const quizQuestionErrorSelector = createSelector(
  (state: AppState) => state.quizQuestions,
  status => status.error
);


export const quizQuestionsSelector = (quiz?: number) => (state: AppState) => {
  if (!quiz) {
    return undefined;
  }
  return state.quizQuestions?.items?.filter((q) => q.Quiz === quiz);
}; 

export const quizQuestionsLoading = createSelector(
  (state: AppState) => state.quizQuestions.status,
  status => status === 'pending'
);

export const quizQuestionsErrorSelector = createSelector(
  (state: AppState) => state.quizQuestions,
  status => status.error
);

export const { clearQuizQuestionItems } = quizQuestionsSlice.actions;

export default quizQuestionsSlice.reducer;

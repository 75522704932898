/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { LessonCollectionState } from "./lesson-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Lesson } from "../../../../../../types/domain/flat-types";
const initialState: LessonCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessons = createAsyncThunk(
  "get/lessons",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchLessons = createAsyncThunk(
  "get/lessons",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`lesson-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);



  export const createLesson = createAsyncThunk(
    "post/lesson",
    async (lesson: Partial<Lesson>) => {
      const client = await fetchClient();
      const { data } = await client.post(`lesson/`, { lesson });
      return data;
    }
  );


export const updateLesson = createAsyncThunk(
  "put/lesson",
  async (lesson: Partial<Lesson>) => {
    const client = await fetchClient();
    const { data } = await client.put(`lesson/`, { lesson });
    return data;
  }
);

export const getLesson = createAsyncThunk(
  "get/lesson",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson/${id}`);
    return data;
  }
);

export const deleteLesson = createAsyncThunk(
  "delete/lesson",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`lesson/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const lessonsSlice = createSlice({
  name: "lessons",
  initialState,
  reducers: {
    clearLessonItems(state: LessonCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessons.pending || searchLessons.pending, (state: LessonCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessons.fulfilled || searchLessons.fulfilled, (state: LessonCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessons.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLesson.pending, (state: LessonCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteLesson.pending, (state: LessonCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateLesson.pending, (state: LessonCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLesson.fulfilled, (state: LessonCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteLesson.fulfilled, (state: LessonCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateLesson.fulfilled, (state: LessonCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createLesson.pending, (state: LessonCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createLesson.fulfilled, (state: LessonCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getLesson.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteLesson.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateLesson.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonSelector = (id: number) => (state: AppState) => {
  return state.lessons?.items?.find((o) => o.Id === id);
}; 

export const lessonLoading = createSelector(
  (state: AppState) => state.lessons.status,
  status => status === 'pending'
);

export const lessonErrorSelector = createSelector(
  (state: AppState) => state.lessons,
  status => status.error
);


export const lessonsSelector = createSelector(
  (state: AppState) => state.lessons,
  state => state.items
);

export const lessonsLoading = createSelector(
  (state: AppState) => state.lessons.status,
  status => status === 'pending'
);

export const lessonsErrorSelector = createSelector(
  (state: AppState) => state.lessons,
  status => status.error
);

export const { clearLessonItems } = lessonsSlice.actions;

export default lessonsSlice.reducer;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { LessonMediaKeyCollectionState } from "./lesson-media-key-collection-state";

import { Lessonmediakey } from "../../../../../../types/domain/flat-types";
const initialState: LessonMediaKeyCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessonMediaKeys = createAsyncThunk(
  "get/lesson-media-keys",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-media-key?offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createLessonMediaKey = createAsyncThunk(
    "post/lesson-media-key",
    async (lessonMediaKey: Partial<Lessonmediakey>) => {
      const client = await fetchClient();
      const { data } = await client.post(`lesson-media-key/`, { lessonMediaKey });
      return data;
    }
  );


export const updateLessonMediaKey = createAsyncThunk(
  "put/lesson-media-key",
  async (lessonMediaKey: Partial<Lessonmediakey>) => {
    const client = await fetchClient();
    const { data } = await client.put(`lesson-media-key/`, { lessonMediaKey });
    return data;
  }
);

export const getLessonMediaKey = createAsyncThunk(
  "get/lesson-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-media-key/${id}`);
    return data;
  }
);

export const deleteLessonMediaKey = createAsyncThunk(
  "delete/lesson-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`lesson-media-key/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const lessonMediaKeysSlice = createSlice({
  name: "lessonMediaKeys",
  initialState,
  reducers: {
    clearLessonMediaKeyItems(state: LessonMediaKeyCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonMediaKeys.pending, (state: LessonMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessonMediaKeys.fulfilled, (state: LessonMediaKeyCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessonMediaKeys.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLessonMediaKey.pending, (state: LessonMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteLessonMediaKey.pending, (state: LessonMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateLessonMediaKey.pending, (state: LessonMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLessonMediaKey.fulfilled, (state: LessonMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteLessonMediaKey.fulfilled, (state: LessonMediaKeyCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateLessonMediaKey.fulfilled, (state: LessonMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createLessonMediaKey.pending, (state: LessonMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createLessonMediaKey.fulfilled, (state: LessonMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getLessonMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteLessonMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateLessonMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonMediaKeySelector = (id: number) => (state: AppState) => {
  return state.lessonMediaKeys?.items?.find((o) => o.Id === id);
}; 

export const lessonMediaKeyLoading = createSelector(
  (state: AppState) => state.lessonMediaKeys.status,
  status => status === 'pending'
);

export const lessonMediaKeyErrorSelector = createSelector(
  (state: AppState) => state.lessonMediaKeys,
  status => status.error
);


export const lessonMediaKeysSelector = createSelector(
  (state: AppState) => state.lessonMediaKeys,
  state => state.items
);

export const lessonMediaKeysLoading = createSelector(
  (state: AppState) => state.lessonMediaKeys.status,
  status => status === 'pending'
);

export const lessonMediaKeysErrorSelector = createSelector(
  (state: AppState) => state.lessonMediaKeys,
  status => status.error
);

export const { clearLessonMediaKeyItems } = lessonMediaKeysSlice.actions;

export default lessonMediaKeysSlice.reducer;

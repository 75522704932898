import React from "react";
import { Path } from "react-native-svg";

const EmailOutline: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M22.1406 19.5625C22.6153 19.5625 23 20.0372 23 19.5625V9.28021C23 8.1026 22.408 7.02049 21.4162 6.38536L19.3477 5.06088V1.85938C19.3477 1.3847 18.963 1 18.4883 1H5.51172C5.03705 1 4.65234 1.3847 4.65234 1.85938V5.05719L2.58514 6.37949C1.5925 7.01445 1 8.09706 1 9.27534V19.5625C1 21.458 2.54201 23 4.4375 23H19.5625C21.458 23 23 21.458 23 19.5625C23 19.0878 22.6153 18.7031 22.1406 18.7031C21.666 18.7031 21.2812 19.0878 21.2812 19.5625C21.2812 20.5102 20.5102 21.2812 19.5625 21.2812H4.4375C3.48984 21.2812 2.71875 20.5102 2.71875 19.5625V10.3945L10.1847 15.037C10.7447 15.3851 11.3723 15.5592 12 15.5592C12.6276 15.5592 13.2555 15.3851 13.8153 15.037L21.2812 10.3945V19.5625C21.2812 20.0372 21.666 19.5625 22.1406 19.5625ZM20.4893 7.83287C20.7488 7.99904 20.9528 8.22664 21.0891 8.48999L19.3477 9.57294V7.10173L20.4893 7.83287ZM3.51132 7.8275L4.65234 7.09753V9.57294L2.90892 8.48882C3.04538 8.22362 3.25032 7.99434 3.51132 7.8275ZM12.9075 13.5774C12.3478 13.9255 11.6522 13.9255 11.0925 13.5774L6.37109 10.6416V2.71875H17.6289V10.6416L12.9075 13.5774ZM12.8594 7.57422C12.8594 8.04889 12.4747 8.43359 12 8.43359C11.5253 8.43359 11.1406 8.04889 11.1406 7.57422C11.1406 7.09955 11.5253 6.71484 12 6.71484C12.4747 6.71484 12.8594 7.09955 12.8594 7.57422ZM15.9531 7.57422C15.9531 8.04889 15.5684 8.43359 15.0938 8.43359C14.6191 8.43359 14.2344 8.04889 14.2344 7.57422C14.2344 7.09955 14.6191 6.71484 15.0938 6.71484C15.5684 6.71484 15.9531 7.09955 15.9531 7.57422ZM9.72266 7.57422C9.72266 8.04889 9.33795 8.43359 8.86328 8.43359C8.38861 8.43359 8.00391 8.04889 8.00391 7.57422C8.00391 7.09955 8.38861 6.71484 8.86328 6.71484C9.33795 6.71484 9.72266 7.09955 9.72266 7.57422Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default EmailOutline;

import { StyleSheet } from "react-native";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  tile: {
    width: 200,
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: vars.white,
    borderRadius: 16,
    paddingVertical: 26,
    paddingHorizontal: 20,
  },
  tilePrice: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    textAlign: "left",
    flexWrap: "wrap",
    color: vars.black,
  },
  icon: {
    height: 25,
    width: 25,
  },
});

export default styles;

import React, { createRef, useEffect, useState } from "react";
import { Pressable, Text, TextInput, View } from "react-native";
import authStyles from "../layout/auth.styles";
import formStyles from "../../../components/forms/authForm.styles";
import styles from "./twoFactor.styles";
import { StyledText, textStyles } from "src/styles/text";
import { CustomButton } from "src/uikit";
import { useTranslation } from "src/translations/useTranslation";

export const CodeInput = ({
  navigation,
  formik,
  error,
}: {
  navigation: any;
  formik: any;
  error?: string | false;
}) => {
  const [bcode, setbcode] = useState<string[]>(["", "", "", "", "", ""]);
  const [elRefs, setElRefs] = React.useState([]);
  const { t } = useTranslation();

  const handleCodeChange = (index: number, value: string) => {
    const newbcode = bcode;
    newbcode[index] = value;
    setbcode(newbcode);
    formik.setFieldValue("code", newbcode.join(""), false);
    if (index < 5 && value !== "") {
      (elRefs[index + 1] as any).current.focus();
    }
  };

  React.useEffect(() => {
    setElRefs((_elRefs) =>
      Array(bcode.length)
        .fill({})
        .map((_, i) => _elRefs[i] || createRef()),
    );
  }, [bcode.length]);

  useEffect(() => {
    if (formik.values.code === "      ") {
      setbcode(["", "", "", "", "", ""]);
    }
  }, [formik]);

  return (
    <>
      <View style={[formStyles.fieldContainer, styles.code]}>
        {[0, 1, 2, 3, 4, 5].map((v, i) => {
          return (
            <TextInput
              key={`code${i}`}
              ref={elRefs[i]}
              keyboardType="numeric"
              style={[textStyles.inputText, styles.codeInput]}
              testID={`code${i}`}
              onChangeText={(e) => handleCodeChange(i, e)}
              value={bcode[i]}
              maxLength={1}
            />
          );
        })}
      </View>
      <View style={authStyles.authAction}>
        <Pressable onPress={() => navigation.navigate("NoCode")}>
          <StyledText variant={"linkText"}>{t("security.noCode")}</StyledText>
        </Pressable>
      </View>
      <View style={[formStyles.fieldContainer, styles.confirmButton]}>
        <CustomButton
          testID={"Confirm"}
          onPress={() => formik.handleSubmit()}
          title={t("security.confirm")}
        />
        <View>
          <Text>{error ? (error as string) : ""}</Text>
        </View>
      </View>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { fetchClient } from "../../../api/axios/axiosApi";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { AuthHeader } from "../layout/auth-header";
import { View } from "react-native";
import { StyledText } from "src/styles/text";
import authStyles from "../layout/auth.styles";
import formStyles from "src/components/forms/authForm.styles";
import { AppOrWebLoginButtons } from "src/components/buttons/appOrWebLoginButtons";
import { AxiosResponse } from "axios";

const ChangeEmailVerificationScreen: React.FunctionComponent<{
  navigation: any;
  route: any;
}> = ({ navigation, route }) => {
  const token = route.params.token;
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [err, setErr] = useState("");

  const verifyEmail = async () => {
    const client = await fetchClient();

    setLoading(true);
    client
      .post("/auth/verify/email-change", { token })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res: AxiosResponse) => {
        setLoading(false);
        setShowSuccessMessage(true);
      })
      // eslint-disable-next-line no-shadow
      .catch((err: AxiosResponse) => {
        setLoading(false);
        if (err.data?.message || err) {
          setErr(err.data?.message || err);
        }
      });
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <WebFadeIn>
      <View style={[authStyles.authPage]} testID="email-verification">
        <View style={[formStyles.form]}>
          <AuthHeader title={"Bumba Academy"} preamble={""} />

          {loading && !err && (
            <StyledText variant="body1">Processing...</StyledText>
          )}
          {showSuccessMessage && (
            <>
              <StyledText variant="body1">
                You have successfully verified your email.
              </StyledText>
              <AppOrWebLoginButtons navigation={navigation} />
            </>
          )}
          {!!err && (
            <View>
              <StyledText variant="body1">Email verification failed</StyledText>
              {/* <StyledText variant="body1">{err.toString()}</StyledText> */}
            </View>
          )}
        </View>
      </View>
    </WebFadeIn>
  );
};

export default ChangeEmailVerificationScreen;

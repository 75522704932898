/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { CustomerEmailCollectionState } from "./customer-email-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Customeremail } from "../../../../../../types/domain/flat-types";
const initialState: CustomerEmailCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCustomerEmails = createAsyncThunk(
  "get/customer-emails",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchCustomerEmails = createAsyncThunk(
  "get/customer-emails",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`customer-email-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);



  export const createCustomerEmail = createAsyncThunk(
    "post/customer-email",
    async (customerEmail: Partial<Customeremail>) => {
      const client = await fetchClient();
      const { data } = await client.post(`customer-email/`, { customerEmail });
      return data;
    }
  );


export const updateCustomerEmail = createAsyncThunk(
  "put/customer-email",
  async (customerEmail: Partial<Customeremail>) => {
    const client = await fetchClient();
    const { data } = await client.put(`customer-email/`, { customerEmail });
    return data;
  }
);

export const getCustomerEmail = createAsyncThunk(
  "get/customer-email",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email/${id}`);
    return data;
  }
);

export const deleteCustomerEmail = createAsyncThunk(
  "delete/customer-email",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`customer-email/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const customerEmailsSlice = createSlice({
  name: "customerEmails",
  initialState,
  reducers: {
    clearCustomerEmailItems(state: CustomerEmailCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerEmails.pending || searchCustomerEmails.pending, (state: CustomerEmailCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCustomerEmails.fulfilled || searchCustomerEmails.fulfilled, (state: CustomerEmailCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getCustomerEmails.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCustomerEmail.pending, (state: CustomerEmailCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteCustomerEmail.pending, (state: CustomerEmailCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateCustomerEmail.pending, (state: CustomerEmailCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCustomerEmail.fulfilled, (state: CustomerEmailCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteCustomerEmail.fulfilled, (state: CustomerEmailCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateCustomerEmail.fulfilled, (state: CustomerEmailCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createCustomerEmail.pending, (state: CustomerEmailCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createCustomerEmail.fulfilled, (state: CustomerEmailCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getCustomerEmail.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteCustomerEmail.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateCustomerEmail.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const customerEmailSelector = (id: number) => (state: AppState) => {
  return state.customerEmails?.items?.find((o) => o.Id === id);
}; 

export const customerEmailLoading = createSelector(
  (state: AppState) => state.customerEmails.status,
  status => status === 'pending'
);

export const customerEmailErrorSelector = createSelector(
  (state: AppState) => state.customerEmails,
  status => status.error
);


export const customerEmailsSelector = createSelector(
  (state: AppState) => state.customerEmails,
  state => state.items
);

export const customerEmailsLoading = createSelector(
  (state: AppState) => state.customerEmails.status,
  status => status === 'pending'
);

export const customerEmailsErrorSelector = createSelector(
  (state: AppState) => state.customerEmails,
  status => status.error
);

export const { clearCustomerEmailItems } = customerEmailsSlice.actions;

export default customerEmailsSlice.reducer;

/* eslint-disable react-native/no-inline-styles */
import * as DocumentPicker from "expo-document-picker";
import React, { useState } from "react";
import { useWindowDimensions, View } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { StyledText } from "src/styles/text";
import { themeDefault } from "src/theme";
import { useTranslation } from "src/translations/useTranslation";
import { uploadFile } from "../../imagekit/lib/imagekit";
import IkImageViewer from "../../imagekit/screens/Fetch/ikImageViewer";
import { supportedImage } from "../../imagekit/screens/Upload/supportedMimeTypes";
import useStyles from "./profileImage.styles";

interface Props {
  onUploaded?: (url: string, key: string, meta?: string) => Promise<void>;
  onUploading?: () => void;
  imageUrl: string;
  imageMeta: string;
  resourceLoading: boolean;
  viewOnly?: boolean;
}

const ProfileImage: React.FC<Props> = ({
  onUploaded,
  onUploading,
  imageUrl,
  imageMeta,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resourceLoading,
  viewOnly,
}) => {
  const styles = useStyles();
  const { width: windowWidth } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [freshUpload, setFreshUpload] = useState<any>();
  const { t } = useTranslation();

  const mediaKey = "Hero";

  const openFileSelector = viewOnly
    ? undefined
    : async (key: string) => {
        try {
          //https://docs.expo.dev/versions/latest/sdk/document-picker/#documentpickergetdocumentasyncnamedparameters
          var res = await DocumentPicker.getDocumentAsync({
            type: [...supportedImage],
          });

          console.log("key: ", key);
          console.log("res", res);

          setLoading(true);
          setFreshUpload(undefined);

          if (res.type === "cancel") {
            setLoading(false);
            return;
          }
          console.log("open file selector");

          await uploadFileToImagekit(res, key);
        } catch (err) {
          setLoading(false);
          console.log("upload error", err);
          throw err;
        }
      };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const uploadFileToImagekit = async (fileData: any, key: string) => {
    try {
      if (fileData.type === "cancel") {
        setLoading(false);
        return;
      }

      if (onUploading) {
        onUploading();
      }

      const uploadedFile: any = await uploadFile(fileData);
      if (onUploaded) {
        setFreshUpload(uploadedFile);
        //todo: pass other meta in uploadedFile i.e. fileId and original dimensions etc.

        await onUploaded(uploadedFile.url, JSON.stringify(uploadedFile));

        setLoading(false);
      }
      // setUploadFileUrl(uploadedFile.url);
    } catch (err) {
      //handle error in uploading file
      console.log(err);
    }
  };

  const meta = imageMeta ? JSON.parse(imageMeta) : "";

  // const getUploadButtonStyle = (key: MediaKey) => {
  //   if (key === "Hero") {
  //     return {};
  //   } else if (key === "Icon") {
  //     return buttonStyles.iconUpload;
  //   }
  // };

  return (
    <View testID="profile-image" style={styles.container}>
      {!loading ? (
        <View key={imageUrl}>
          <IkImageViewer
            imageStyles={styles.imageStyles}
            style={styles.imageContainer}
            uploadButtonProps={{
              icon: "camera",
              theme: "link",
              iconColor: themeDefault.colors.primary,
              style: styles.imageUploadButton,
            }}
            imagePath={meta?.filePath || freshUpload?.filePath}
            height={windowWidth > mobileBreak ? 200 : 140}
            width={windowWidth > mobileBreak ? 200 : 140}
            openFileSelector={
              openFileSelector ? () => openFileSelector(mediaKey) : undefined
            }
            transform
          />
        </View>
      ) : (
        <View style={styles.loading}>
          <StyledText variant="body1">{t("settings.loading")}</StyledText>
        </View>
      )}
    </View>
  );
};

export default ProfileImage;

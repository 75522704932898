import React from "react";
import { Path } from "react-native-svg";

const ChevronLeft: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M16.0607 5.06066C16.6464 4.47487 16.6464 3.52513 16.0607 2.93934C15.4749 2.35355 14.5251 2.35355 13.9393 2.93934L16.0607 5.06066ZM7 12L5.93934 10.9393C5.35355 11.5251 5.35355 12.4749 5.93934 13.0607L7 12ZM13.9393 21.0607C14.5251 21.6464 15.4749 21.6464 16.0607 21.0607C16.6464 20.4749 16.6464 19.5251 16.0607 18.9393L13.9393 21.0607ZM13.9393 2.93934L5.93934 10.9393L8.06066 13.0607L16.0607 5.06066L13.9393 2.93934ZM5.93934 13.0607L13.9393 21.0607L16.0607 18.9393L8.06066 10.9393L5.93934 13.0607Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default ChevronLeft;

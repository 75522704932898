import React from "react";
import { Path } from "react-native-svg";

const AppsFilled: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M20.4219 12.8594H15.4375C14.0159 12.8594 12.8594 14.0159 12.8594 15.4375V20.4219C12.8594 21.8435 14.0159 23 15.4375 23H20.4219C21.8435 23 23 21.8435 23 20.4219V15.4375C23 14.0159 21.8435 12.8594 20.4219 12.8594ZM8.5625 12.8594H3.57812C2.15655 12.8594 1 14.0159 1 15.4375V20.4219C1 21.8435 2.15655 23 3.57812 23H8.5625C9.98408 23 11.1406 21.8435 11.1406 20.4219V15.4375C11.1406 14.0159 9.98408 12.8594 8.5625 12.8594ZM8.5625 1H3.57812C2.15655 1 1 2.15655 1 3.57812V8.5625C1 9.98408 2.15655 11.1406 3.57812 11.1406H8.5625C9.98408 11.1406 11.1406 9.98408 11.1406 8.5625V3.57812C11.1406 2.15655 9.98408 1 8.5625 1ZM9.42188 8.5625C9.42188 9.03636 9.03636 9.42188 8.5625 9.42188H3.57812C3.10427 9.42188 2.71875 9.03636 2.71875 8.5625V3.57812C2.71875 3.10427 3.10427 2.71875 3.57812 2.71875H8.5625C9.03636 2.71875 9.42188 3.10427 9.42188 3.57812V8.5625ZM20.4219 1H15.4375C14.0159 1 12.8594 2.15655 12.8594 3.57812V8.5625C12.8594 9.98408 14.0159 11.1406 15.4375 11.1406H20.4219C21.8435 11.1406 23 9.98408 23 8.5625V3.57812C23 2.15655 21.8435 1 20.4219 1Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default AppsFilled;

import React from "react";
import {
  FlexStyle,
  NativeSyntheticEvent,
  NativeTouchEvent,
  StyleProp,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
  ViewStyle,
} from "react-native";
import { textStyles } from "src/styles/text";
import CustomIcon, { IconTypes } from "../customSvgIcon/customSvgIcon";
import useStyles from "./customButton.styles";

export type CustomButtonTheme =
  | "primary"
  | "secondary"
  | "red"
  | "redBordered"
  | "white"
  | "link";
export type CustomButtonPosition = "left" | "right" | "center";

export interface CustomButtonProps {
  icon?: IconTypes;
  title?: string;
  withOpacity?: boolean;
  theme?: CustomButtonTheme;
  position?: CustomButtonPosition;
  iconStyle?: StyleProp<FlexStyle>;
  onPress: (ev?: NativeSyntheticEvent<NativeTouchEvent>) => void;
  textStyle?: any;
  iconColor?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
}

const CustomButton: React.FunctionComponent<
  CustomButtonProps & TouchableOpacityProps
> = ({
  icon,
  title,
  onPress,
  iconStyle,
  withOpacity = true,
  theme = "primary",
  position = "center",
  style = {},
  textStyle,
  iconColor,
  testID,
  disabled,
  ...restProps
}) => {
  const textClass:
    | "primaryText"
    | "secondaryText"
    | "redText"
    | "redBorderedText"
    | "whiteText"
    | "linkText" = `${theme}Text`;
  const iconClass:
    | "primaryIcon"
    | "secondaryIcon"
    | "redIcon"
    | "redBorderedIcon"
    | "whiteIcon"
    | "linkIcon" = `${theme}Icon`;
  const positionClass:
    | "leftPos"
    | "centerPos"
    | "rightPos"
    | "whiteIcon" = `${position}Pos`;

  const styles = useStyles({ disabled });

  return (
    <TouchableOpacity
      style={[styles.container, styles.button, styles[theme], style]}
      onPress={onPress}
      activeOpacity={withOpacity ? 0.5 : 1}
      testID={testID}
      disabled={disabled}
      {...restProps}
    >
      <View style={[styles.buttonView, styles[positionClass]]}>
        {icon && (
          <View style={[styles.icon, styles[iconClass], iconStyle]}>
            <CustomIcon
              iconSize={16}
              iconType={icon}
              iconColor={iconColor || styles[iconClass].color}
            />
          </View>
        )}
        <Text
          style={[
            textStyles.buttonText,
            styles.text,
            styles[textClass],
            styles[positionClass],
            textStyle,
          ]}
        >
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default CustomButton;

import React from "react";
import { View, Image, Modal, ModalProps } from "react-native";
import { StyledText } from "src/styles/text";
import useStyles from "./customModal.styles";

// type CustomModalIconTypes = "check" | undefined;

interface CustomModalProps extends ModalProps {
  text?: string;
  title?: string;
  icon?: any;
  // eslint-disable-next-line no-undef
  acceptButton?: JSX.Element;
  // eslint-disable-next-line no-undef
  declineButton?: JSX.Element;
}

const CustomModal: React.FunctionComponent<CustomModalProps> = ({
  text,
  title,
  icon,
  acceptButton,
  declineButton,
  ...restProps
}) => {
  const styles = useStyles();
  const getIcon = (iconName?: string) => {
    switch (iconName) {
      case "check":
        return require(`../../../assets/be-images/check.png`);
    }
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        animationType="none"
        presentationStyle="overFullScreen"
        transparent={true}
        {...restProps}
      >
        <View style={styles.centeredInnerView}>
          <View style={styles.modalView}>
            {icon && (
              <Image
                source={getIcon(icon)}
                style={{
                  width: 100,
                  height: 100,
                }}
              />
            )}

            {title ? (
              <StyledText variant="modalTitle" style={styles.modalTitle}>
                {title}
              </StyledText>
            ) : null}

            {text ? (
              <StyledText variant="modalText" style={styles.modalText}>
                {text}
              </StyledText>
            ) : null}

            {acceptButton && (
              <View style={styles.modalButton}>{acceptButton}</View>
            )}
            {declineButton && (
              <View style={styles.modalButton}>{declineButton}</View>
            )}
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default CustomModal;

import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  authPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    height: "100%",
    width: "100%",
    padding: 20,
  },
  title: {
    marginTop: 92, // 176 - 64 (header) - 20 (padding)
    marginBottom: 14,
  },
  preamble: {
    marginBottom: 14,
  },
  authAction: {
    marginTop: 20,
    marginBottom: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  spacedButton: {
    marginTop: 40,
  },
  fixedForDynamicPreambleMessaging: {},
});

export default styles;

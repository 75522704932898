/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { QuizAnswerPublicCollectionState } from "./quiz-answer-public-collection-state";

import { Quizanswerpublic } from "../../../../../../types/domain/flat-types";
const initialState: QuizAnswerPublicCollectionState = {
  items: undefined,
  status: "idle",
};

export const getQuizAnswerPublics = createAsyncThunk(
  "get/quiz-answer-publics",
  async (options?: { quizquestion?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-answer-public?quizquestion=${options?.quizquestion}&language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);




export const getQuizAnswerPublic = createAsyncThunk(
  "get/quiz-answer-public",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-answer-public/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: QuizAnswerPublicCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].QuizQuestion : undefined;

//   if(currentStateConstraint && actionArgs.quizquestion && Number(actionArgs.quizquestion) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].QuizQuestion : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const quizAnswerPublicsSlice = createSlice({
  name: "quizAnswerPublics",
  initialState,
  reducers: {
    clearQuizAnswerPublicItems(state: QuizAnswerPublicCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizAnswerPublics.pending, (state: QuizAnswerPublicCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getQuizAnswerPublics.fulfilled, (state: QuizAnswerPublicCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getQuizAnswerPublics.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getQuizAnswerPublic.pending, (state: QuizAnswerPublicCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getQuizAnswerPublic.fulfilled, (state: QuizAnswerPublicCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getQuizAnswerPublic.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const quizAnswerPublicSelector = (id: number) => (state: AppState) => {
  return state.quizAnswerPublics?.items?.find((o) => o.Id === id);
}; 

export const quizAnswerPublicLoading = createSelector(
  (state: AppState) => state.quizAnswerPublics.status,
  status => status === 'pending'
);

export const quizAnswerPublicErrorSelector = createSelector(
  (state: AppState) => state.quizAnswerPublics,
  status => status.error
);


export const quizAnswerPublicsSelector = (quizQuestion?: number) => (state: AppState) => {
  if (!quizQuestion) {
    return undefined;
  }
  return state.quizAnswerPublics?.items?.filter((q) => q.QuizQuestion === quizQuestion);
}; 

export const quizAnswerPublicsLoading = createSelector(
  (state: AppState) => state.quizAnswerPublics.status,
  status => status === 'pending'
);

export const quizAnswerPublicsErrorSelector = createSelector(
  (state: AppState) => state.quizAnswerPublics,
  status => status.error
);

export const { clearQuizAnswerPublicItems } = quizAnswerPublicsSlice.actions;

export default quizAnswerPublicsSlice.reducer;

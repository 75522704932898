/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { TopicMediaKeyCollectionState } from "./topic-media-key-collection-state";

import { Topicmediakey } from "../../../../../../types/domain/flat-types";
const initialState: TopicMediaKeyCollectionState = {
  items: undefined,
  status: "idle",
};

export const getTopicMediaKeys = createAsyncThunk(
  "get/topic-media-keys",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-media-key?offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createTopicMediaKey = createAsyncThunk(
    "post/topic-media-key",
    async (topicMediaKey: Partial<Topicmediakey>) => {
      const client = await fetchClient();
      const { data } = await client.post(`topic-media-key/`, { topicMediaKey });
      return data;
    }
  );


export const updateTopicMediaKey = createAsyncThunk(
  "put/topic-media-key",
  async (topicMediaKey: Partial<Topicmediakey>) => {
    const client = await fetchClient();
    const { data } = await client.put(`topic-media-key/`, { topicMediaKey });
    return data;
  }
);

export const getTopicMediaKey = createAsyncThunk(
  "get/topic-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-media-key/${id}`);
    return data;
  }
);

export const deleteTopicMediaKey = createAsyncThunk(
  "delete/topic-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`topic-media-key/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const topicMediaKeysSlice = createSlice({
  name: "topicMediaKeys",
  initialState,
  reducers: {
    clearTopicMediaKeyItems(state: TopicMediaKeyCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopicMediaKeys.pending, (state: TopicMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getTopicMediaKeys.fulfilled, (state: TopicMediaKeyCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getTopicMediaKeys.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getTopicMediaKey.pending, (state: TopicMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteTopicMediaKey.pending, (state: TopicMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateTopicMediaKey.pending, (state: TopicMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getTopicMediaKey.fulfilled, (state: TopicMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteTopicMediaKey.fulfilled, (state: TopicMediaKeyCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateTopicMediaKey.fulfilled, (state: TopicMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createTopicMediaKey.pending, (state: TopicMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createTopicMediaKey.fulfilled, (state: TopicMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getTopicMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteTopicMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateTopicMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const topicMediaKeySelector = (id: number) => (state: AppState) => {
  return state.topicMediaKeys?.items?.find((o) => o.Id === id);
}; 

export const topicMediaKeyLoading = createSelector(
  (state: AppState) => state.topicMediaKeys.status,
  status => status === 'pending'
);

export const topicMediaKeyErrorSelector = createSelector(
  (state: AppState) => state.topicMediaKeys,
  status => status.error
);


export const topicMediaKeysSelector = createSelector(
  (state: AppState) => state.topicMediaKeys,
  state => state.items
);

export const topicMediaKeysLoading = createSelector(
  (state: AppState) => state.topicMediaKeys.status,
  status => status === 'pending'
);

export const topicMediaKeysErrorSelector = createSelector(
  (state: AppState) => state.topicMediaKeys,
  status => status.error
);

export const { clearTopicMediaKeyItems } = topicMediaKeysSlice.actions;

export default topicMediaKeysSlice.reducer;

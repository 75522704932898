import { Dimensions } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { vars } from "src/styles";
import { themeDefault } from "src/theme";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return {
    container: {
      width: windowWidth > mobileBreak ? 200 : 140,
      height: windowWidth > mobileBreak ? 200 : 140,
      borderRadius: windowWidth > mobileBreak ? 200 : 140,
      backgroundColor: vars.white,
      border: `1px solid ${themeDefault.colors.primary}`,
    },
    imageContainer: {
      flexDirection: "row",
      justifyContent: "center",
    },
    imageStyles: {
      position: "relative",
      borderRadius: windowWidth > mobileBreak ? 200 : 140,
    },
    imageUploadButton: {
      backgroundColor: vars.white,
      position: "absolute",
      flexDirection: "row",
      justifyContent: "center",
      zIndex: 2,
      width: 44,
      height: 44,
      borderRadius: 14,
      bottom: -22,
      shadowColor: "rgba(13, 12, 45, 0.08)",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    loading: {
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
  };
};

export default styles;

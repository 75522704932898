import { DrawerNavigationOptions } from "@react-navigation/drawer";
import { useLayoutEffect } from "react";
import { Dimensions, Platform, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { videoPlayerFullScreen } from "src/redux/features/misc/slice";
import { NavProps } from "../types";

export const mobileBreak = 768;
export const avgDeviceSize = 1024;

// https://github.com/react-navigation/react-navigation/issues/10210 ...still not fixed
// the drawer menu always tranlates based on teh initial width...meaning if orientation changes it doesn't work
// this is a fix until it does.
const windowWidthOnModuleLoad = Dimensions.get("window").width;

export const DrawerNavOptions = ({
  navigation,
}: NavProps): DrawerNavigationOptions => {
  const videoFullScreen = useSelector(videoPlayerFullScreen);
  const { width } = useWindowDimensions();
  // const isDrawerClosed = useDrawerStatus() !== "open";
  const isLargeScreen = width >= avgDeviceSize;

  useLayoutEffect(() => {
    navigation.closeDrawer();
  }, [navigation, width]);

  return {
    drawerType: isLargeScreen && !videoFullScreen ? undefined : "front",
    drawerStyle: {
      // opacity: drawerOpacity,
      width: 280,
      right: Platform.OS === "web" ? windowWidthOnModuleLoad - 280 : undefined,
      backgroundColor: "#F7F5FE",
    },
    headerShown: videoFullScreen ? false : true,
    drawerPosition: "right",
    headerStyle: {
      height: 1,
    },
    headerLeft: () => false,
    headerLeftLabelVisible: false,
    headerTitle: "",
    headerShadowVisible: false,
    headerTransparent: false, // true removes the header spacing and breaks safe area - keep this false!
    drawerContentStyle: { backgroundColor: "transparent" },
  };
};

import {
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from "@react-navigation/drawer";
import React from "react";
import { View } from "react-native";
import { useDispatch } from "react-redux";
import { invalidateAuth } from "src/redux/features/auth/slice";
import { AdminMenu } from "./admin/adminMenu";
import useStyles from "./nav.styles";
import { UserMenu } from "./user/userMenu";
import { useLogoutWorkaround } from "./utils/useLogoutWorkAround";

export const CustomDrawerContent = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { resetNav } = useLogoutWorkaround(props);

  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={styles.drawContainer}
      showsVerticalScrollIndicator={false}
    >
      <View>
        <DrawerItemList {...props} />
        <AdminMenu {...props} />
        <UserMenu {...props} />
      </View>
      <DrawerItem
        label="Logout"
        testID="user-logout-button"
        style={styles.logout}
        onPress={() => {
          // this reset works around a problem with the linking lib that throws an error
          // it might be neccessary to refactor the (web only) lazy chunk to avoid this
          resetNav();
          setTimeout(() => dispatch(invalidateAuth()), 0);
        }}
      />
    </DrawerContentScrollView>
  );
};

import ImageKit from "imagekit-javascript";
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../config/imagekit";
import * as FileSystem from "expo-file-system";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ImageKitOptions } from "imagekit-javascript/dist/src/interfaces";

let imagekit: ImageKit;

export const intialiseImageKit = async () => {
  const token =
    (await AsyncStorage.getItem("token")) ||
    (await AsyncStorage.getItem("signup")) ||
    "";

  var imagekitConfigOptions: ImageKitOptions = { urlEndpoint };
  if (publicKey) {
    imagekitConfigOptions.publicKey = publicKey;
  }
  if (authenticationEndpoint) {
    imagekitConfigOptions.authenticationEndpoint = `${authenticationEndpoint}/${token}`;
    imagekitConfigOptions.sdkVersion = undefined; //or it gets added to the url
  }

  imagekit = new ImageKit(imagekitConfigOptions);
};

export const getImagekitUrlFromSrc = function (imageSrc, transformationArr) {
  var ikOptions = {
    src: imageSrc,
    transformation: transformationArr,
  };
  var imageURL = imagekit.url(ikOptions);

  return decodeURIComponent(imageURL);
};

export const getImagekitUrlFromPath = function (
  imagePath,
  transformationArr,
  transformationPostion,
) {
  var ikOptions = {
    urlEndpoint,
    path: imagePath,
    transformation: transformationArr,
  };
  if (transformationPostion) {
    ikOptions.transformationPostion = transformationPostion;
  }

  var imageURL = imagekit.url(ikOptions);

  return decodeURIComponent(imageURL);
};

export const uploadFile = (file: { uri: string; name: any }) => {
  return new Promise((resolve, reject) => {
    // console.log("upload file", JSON.stringify(file));

    if (Platform.OS !== "web") {
      FileSystem.readAsStringAsync(file.uri, { encoding: "base64" }).then(
        (fileBase64) => {
          imagekit.upload(
            {
              file: fileBase64,
              fileName: file.name, //you can change this and generate your own name if required
              //@ts-ignore
              tags: ["priv-test", "not-from-web"], //change this or remove it if you want
              isPrivateFile: true,
            },
            function (err, result) {
              if (err) {
                console.log(err, result);
                reject(err);
              }
              resolve(result);
            },
          );
        },
      );
    } else {
      imagekit.upload(
        {
          file: file.uri,
          fileName: file.name, //you can change this and generate your own name if required
          //@ts-ignore
          tags: ["sample-tag-1", "from-web"], //change this or remove it if you want
          isPrivateFile: true,
          queryParameters: "some=data",
        },
        function (err, result) {
          if (err) {
            console.log(err, result);
            reject(err);
          }
          resolve(result);
        },
      );
    }
  });
};

import React, { ReactElement } from "react";
import { Text, View, Pressable, StyleProp, ViewStyle } from "react-native";
import {
  OpacityDecorator,
  NestableDraggableFlatList,
  NestableScrollContainer,
} from "react-native-draggable-flatlist";
import { CustomButton, CustomSvgIcon } from "src/uikit";
import { vars } from "src/styles";
import styles from "./orderableList.styles";

interface Props {
  data: any[];
  onDragEnd?: (params: any) => void;
  onDragBegin?: (params: any) => void;
  renderItem: (item: any) => ReactElement;
  style?: StyleProp<ViewStyle>;
  allowDelete?: boolean;
  onDelete?: (item: any) => void;
  keyExtractor?: (item: any, key: number) => string;
}

const OrderableList: React.FunctionComponent<Props> = (props) => {
  const renderItem = ({ item, drag, isActive }: any) => (
    <OpacityDecorator>
      <View style={[styles.rowItem, props?.style]}>
        <Pressable
          onPressIn={drag}
          disabled={isActive}
          style={styles.iconButton}
        >
          <View>
            <Text style={styles.icon}>
              <CustomSvgIcon
                iconType="dnd"
                iconSize={24}
                iconColor={vars.primaryColor}
              />
            </Text>
          </View>
        </Pressable>
        <View style={styles.textStyles}>{props?.renderItem(item)}</View>
        {props?.onDelete && (
          <View>
            <CustomButton
              iconStyle={styles.buttonIconStyle}
              onPress={() => props?.onDelete && props.onDelete(item)}
              icon="trash"
              theme="red"
            />
          </View>
        )}
      </View>
    </OpacityDecorator>
  );

  return (
    <NestableScrollContainer>
      <NestableDraggableFlatList
        data={props?.data}
        onDragBegin={props?.onDragBegin}
        onDragEnd={props?.onDragEnd}
        keyExtractor={
          props.keyExtractor || ((item: any, key: number) => `item-${key}`)
        }
        renderItem={renderItem}
      />
    </NestableScrollContainer>
  );
};

export default OrderableList;

import React from "react";
import {
  Text,
  View,
  TextInput,
  TextInputProps,
  InputAccessoryView,
  Platform,
  StyleProp,
  TextStyle,
} from "react-native";
import styles from "./customTextInput.styles";
import { randomStr } from "src/utils";
import { TouchableOpacity } from "react-native-gesture-handler";

interface Props extends TextInputProps {
  theme?: "primary";
  inputStyle?: StyleProp<TextStyle>;
  errorMessage?: string | boolean;
}

const CustomTextInput: React.FunctionComponent<Props> = ({
  multiline,
  style,
  inputStyle,
  errorMessage,
  theme = "primary",
  inputAccessoryViewID,
  icon,
  ...restProps
}) => {
  const customInputAccessoryViewID =
    multiline && Platform.OS === "ios" ? randomStr() : inputAccessoryViewID;
  const closeKeyboard = () => {};

  const _inputStyle: "primaryInput" = `${theme}Input`;

  return (
    <>
      <View style={[styles.inputContainer, styles[_inputStyle], style]}>
        {icon && icon}
        <TextInput
          multiline={multiline}
          inputAccessoryViewID={customInputAccessoryViewID}
          style={[
            styles.input,
            multiline && Platform.OS === "web"
              ? [{ resize: "vertical" }, [multiline ? styles.multiline : {}]]
              : [multiline ? styles.multiline : {}],
            inputStyle,
            { borderRadius: 22 },
          ]}
          autoCompleteType="off"
          textAlignVertical={multiline ? "top" : "center"}
          {...restProps}
        />

        {multiline && Platform.OS === "ios" && (
          <InputAccessoryView
            nativeID={customInputAccessoryViewID}
            backgroundColor="#f7f7f7"
          >
            <View style={styles.container}>
              <TouchableOpacity onPress={closeKeyboard}>
                <Text style={styles.textStyle}>Done</Text>
              </TouchableOpacity>
            </View>
          </InputAccessoryView>
        )}
      </View>
      <View>
        {errorMessage ? (
          <Text style={styles.errorText}>{errorMessage}</Text>
        ) : null}
      </View>
    </>
  );
};

export default CustomTextInput;

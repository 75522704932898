/* eslint-disable react-native/no-inline-styles */
import React, { useState, useEffect, useCallback } from "react";
import { View, Image, Platform } from "react-native";
import { getImagekitUrlFromSrc } from "../../lib/imagekit";
import { urlEndpoint } from "../../config/imagekit";
import { fetchClient } from "src/api/axios/axiosApi";
import styles from "./ikImageViewer.styles";
//@ts-ignore
import CachedImage, { CacheManager } from "expo-cached-image";
import * as FileSystem from "expo-file-system";
import { UploadButton } from "src/components/buttons/uploadButton";
import CustomButton, {
  CustomButtonProps,
} from "src/uikit/customButton/customButton";

//todo: images with a height less than that passed in will be scaled up and look poor
//problem is we can't get the height of the image without getting the orginal image!
//...which defeats the point of any transform data saving.
//... solution (post MVP?) would be to store original dimensions on cms load and prevent scale ups
//... image hight now available in meta...

export const IkImageViewer = ({
  imagePath,
  height,
  width,
  transform = false,
  style,
  uploadButtonStyle,
  openFileSelector,
  imageStyles,
  uploadButtonProps,
}: {
  imagePath: string;
  height?: number;
  width?: number;
  transform?: boolean;
  style?: any;
  uploadButtonStyle?: any;
  openFileSelector?: () => void;
  imageStyles?: any;
  uploadButtonProps?: Omit<CustomButtonProps, "onPress">;
}) => {
  var imageSrc = urlEndpoint + imagePath;

  const [signedImageUrl, setSignedImageUrl] = useState<string>();
  const [transformedImageUrl, setTransformedImageUrl] = useState<string>();

  const getSize = useCallback(() => {
    if (width && height) {
      return { width, height };
    } else if (!width && height) {
      return { height };
    } else if (width && !height) {
      return { width };
    }
  }, [height, width]);

  const cleanCacheKey = (key: string) => {
    const ckey = key
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/:/g, "")
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/"/g, "")
      .replace(/,/g, "");

    // console.log("cache key", ckey);
    return ckey;
  };

  const cachKey = imagePath
    ? cleanCacheKey(`__${imagePath}${JSON.stringify(getSize())}`)
    : undefined;

  const getSignedUrl = useCallback(
    async (_imageSrc: string) => {
      const client = await fetchClient();

      //don't try and get a signed url if we already have the cached image
      const uri = await CacheManager.getCachedUri({ key: cachKey });
      const metadata =
        Platform.OS !== "web" ? await FileSystem.getInfoAsync(uri) : null;
      if (!metadata?.exists || metadata?.size === 0) {
        // console.log("getting signed url");
        client
          .post(`media/sign`, {
            url: _imageSrc,
          })
          .then(({ data }) => {
            // console.log("setting signed url", data.signedUrl);
            setSignedImageUrl(data.signedUrl);
          })
          .catch((e) => {
            console.log(e);
            // setErr(e.toString());
          });
      } else {
        // console.log("image cached - skip url signing");
        setSignedImageUrl("cached");
      }
    },
    [cachKey],
  );

  useEffect(() => {
    function buildTransformedUrl() {
      var transformationArr = transform ? [getSize()] : [];

      const _transformedImageUrl = getImagekitUrlFromSrc(
        imageSrc,
        transformationArr,
      );

      setTransformedImageUrl(_transformedImageUrl);
    }
    if (imagePath) {
      buildTransformedUrl();
    }
  }, [
    getSize,
    height,
    imagePath,
    imageSrc,
    transform,
    transformedImageUrl,
    width,
  ]);

  useEffect(() => {
    if (transformedImageUrl) {
      // console.log("transformedImageUrl", transformedImageUrl);
      getSignedUrl(transformedImageUrl);
    }
  }, [getSignedUrl, transformedImageUrl]);

  return (
    <View
      style={[styles.imgContainer, getSize(), style, styles.widthConstraint]}
      testID="image-container"
    >
      {openFileSelector && (
        <>
          {uploadButtonProps ? (
            <CustomButton {...uploadButtonProps} onPress={openFileSelector} />
          ) : (
            <UploadButton
              style={[{ position: "absolute", zIndex: 1 }, uploadButtonStyle]}
              openFileSelector={openFileSelector}
            />
          )}
        </>
      )}
      {signedImageUrl && Platform.OS !== "web" && (
        <CachedImage
          source={{ uri: signedImageUrl }}
          cacheKey={cachKey}
          style={[getSize(), imageStyles]}
          resizeMode="contain"
        />
      )}
      {/* todo: web caching */}
      {signedImageUrl && Platform.OS === "web" && (
        <Image
          source={{ uri: signedImageUrl }}
          style={[getSize(), imageStyles]}
          resizeMode="contain"
        />
      )}
    </View>
  );
};

export default IkImageViewer;

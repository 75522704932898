/* eslint-disable react-native/no-inline-styles */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Animated } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

export default function FadeIn({
  children,
  style,
  shouldWait = false,
  waitFor,
}: {
  children: ReactNode;
  style?: any;
  shouldWait?: boolean;
  waitFor?: any;
}) {
  const [timing, setTiming] = useState<any>();
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useFocusEffect(
    React.useCallback(() => {
      setTiming(
        Animated.timing(fadeAnim, {
          useNativeDriver: true,
          toValue: 1,
          duration: 400,
        }),
      );
    }, [fadeAnim]),
  );

  useEffect(() => {
    if (shouldWait && waitFor !== undefined && timing) {
      timing.start();
    } else if (timing && !shouldWait) {
      timing.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldWait, timing?.start, waitFor]);

  return (
    <Animated.View
      style={[
        {
          opacity: fadeAnim,
          //   height: "100%",
          //   width: "100%",
        },
        style,
      ]}
    >
      {children}
    </Animated.View>
  );
}

import { useWindowDimensions } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { largePageWidth } from "src/pages/layout/cms.styles";
import { vars } from "src/styles";

export const maxPageWidth = 824;

export const getFormWidth = (windowWidth: number) => {
  const horizontalPadding = 40;

  if (windowWidth > mobileBreak) {
    if (windowWidth - horizontalPadding > maxPageWidth) {
      return maxPageWidth - horizontalPadding;
    }
    return maxPageWidth - horizontalPadding;
  } else {
    return windowWidth - horizontalPadding;
  }
};

const useStyles = () => {
  const { width: windowWidth } = useWindowDimensions();

  // const getFormWidth = () => {
  //   const __windowWidth = Dimensions.get("window").width;
  //   if (__windowWidth > mobileBreak) {
  //     const max = maxPageWidth;
  //     return __windowWidth - 320 - 70 > max
  //       ? max - 40
  //       : __windowWidth - 320 - 70; // -40 is scroll bar and padding ...will not be necessary post BAMEP-91
  //   } else {
  //     return __windowWidth - 40;
  //   }
  // };

  return {
    //this form style is key to more than just forms - i.e. headers and general layout... consider factoring out
    form: {
      width: "100%",
      // maxWidth: getFormWidth(), //breaks fullscreen
    },
    fieldContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: windowWidth > mobileBreak ? "flex-start" : "center",
      width: "100%",
      backgroundColor: vars.light,
      borderRadius: 14,
      // paddingVertical: 10,
      marginVertical: 10,
      minHeight: 50,
    },
    labelView: {
      // maxWidth: getFormWidth(),
      width: "100%",
      alignSelf: "flex-start",
      marginTop: 10,
      marginBottom: windowWidth > mobileBreak ? 0 : 4,
    },
    label: {
      //todo: for some reasom we cant merge the json object with textStyles.body1lite
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      lineHeight: 21,
      color: "#A6B1C2",
      paddingHorizontal: 14,
    },
    toggleLabel: {
      paddingLeft: 20,
      marginRight: 20,
    },
    inputContainer: {
      // maxWidth: getFormWidth(), //breaks fullscreen
    },
    genFormInput: {
      width: "100%",
      borderWidth: 0,
      // maxWidth: getFormWidth(),
      borderRadius: 20,
      backgroundColor: "transparent",
      paddingHorizontal: 4,
    },
    genFormTextarea: {
      width: "100%",
      borderWidth: 0,
      // maxWidth: getFormWidth(),
      borderRadius: 20,
      backgroundColor: "transparent",
      paddingHorizontal: 4,
    },
    input: {
      width: "100%",
      borderWidth: 0,
      // maxWidth: getFormWidth(),
      color: "#6B7079",
      fontSize: 16,
      borderRadius: 20,
      backgroundColor: "transparent",
      // minHeight: 60,
    },
    imageInput: {
      marginTop: 4,
      marginBottom: 0,
      // borderRadius: 12,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#6B7079",
      fontSize: 16,
      overflow: "hidden",
    },
    imageInputHero: {
      // maxWidth: getFormWidth(),
      width:
        windowWidth > largePageWidth
          ? largePageWidth
          : windowWidth > mobileBreak
          ? // ? playerWidth
            maxPageWidth // full container width
          : "100%",
    },
    imageInputIcon: {
      maxWidth: 100,
      width: 100,
      height: 100,
      minHeight: 100,
      overflow: "visible",
    },
    inputMultiLine: {
      textAlignVertical: "top",
      paddingTop: 0,
      minWidth: windowWidth > mobileBreak ? 400 : 250,
      // width: getFormWidth(),
      maxWidth: "100%",
    },
    inputCoverContainer: {
      zIndex: 1,
      width: "100%",
      height: 60,
      top: -61,
      marginBottom: -60,
    },
    inputCover: {
      top: 0,
      right: 0,
      position: "absolute",
      height: 50,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0)",
      zIndex: 1,
    },
    option: {
      //doesn't appear to have an effect
    },
    disabled: {
      opacity: 0.3,
    },
    fieldErrorMessage: {
      marginBottom: 6,
      justifyContent: "flex-end", //not working android
      textAlign: "right", //android ok
      display: "flex",
      color: "#6B7079",
      position: "absolute",
      top: 32,
      left: 0,
      zIndex: 100,
      backgroundColor: "rgba(0,0,0,0)",
      width: "100%",
      // maxWidth: getFormWidth(),
    },
    underFieldMessage: {
      position: "absolute",
      bottom: -22,
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    error: {},
    submitFormButton: {
      marginBottom: 10,
      maxWidth: windowWidth > mobileBreak ? "48%" : "100%",
    },
    deleteButton: {
      marginBottom: 10,
      maxWidth: windowWidth > mobileBreak ? "48%" : "100%",
    },
    buttons: {
      marginTop: 20,
      flexDirection: windowWidth > mobileBreak ? "row" : "column",
      justifyContent: "space-between",
    },
  };
};

export default useStyles;

import React from "react";
import { Path } from "react-native-svg";

const ChevronDown: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M5.03786 7.54201C4.43974 6.96881 3.49021 6.98902 2.91702 7.58713C2.34382 8.18525 2.36403 9.13478 2.96214 9.70797L5.03786 7.54201ZM12 16.2917L10.9621 17.3746C11.5423 17.9307 12.4577 17.9307 13.0379 17.3746L12 16.2917ZM21.0379 9.70797C21.636 9.13478 21.6562 8.18525 21.083 7.58713C20.5098 6.98902 19.5603 6.96881 18.9621 7.54201L21.0379 9.70797ZM2.96214 9.70797L10.9621 17.3746L13.0379 15.2087L5.03786 7.54201L2.96214 9.70797ZM13.0379 17.3746L21.0379 9.70797L18.9621 7.54201L10.9621 15.2087L13.0379 17.3746Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default ChevronDown;

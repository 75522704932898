import React from "react";
import { View, Text } from "react-native";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { AdminScreens } from "../admin/adminScreens";
import { UserScreens } from "../user/userScreens";
import { isAdmin } from "../utils/roleCheck";

const Loading = () => {
  return (
    <View>
      <Text>Loading</Text>
    </View>
  );
};

export const RoleScreens = ({
  Drawer,
  RootStack,
}: {
  Drawer: any;
  RootStack: any;
}) => {
  const { roles } = useUserInfo();

  if (!roles) {
    return <RootStack.Screen name="Loading" component={Loading} />; //try navigate here
  }

  if (isAdmin(roles)) {
    return AdminScreens({ Drawer, RootStack });
  } else {
    return UserScreens({ Drawer, RootStack });
  }
};

import React from "react";
import { Image, View } from "react-native";
import { StyledText } from "src/styles/text";
import customStyles from "../../../../styles/commonStyles";
import styles from "./statTile.styles";

export const StatTile = ({
  title,
  iconSrc,
  IconComponent,
  width,
  number,
}: {
  title: string;
  number: number;
  iconSrc?: any;
  // eslint-disable-next-line no-undef
  IconComponent?: () => JSX.Element;
  width?: number;
}) => {
  const cusStyles = customStyles();
  return (
    <View style={[styles.tile, { width }, cusStyles.cardShadow]}>
      {iconSrc && (
        <Image
          style={styles.icon}
          source={iconSrc || require("../../../../../assets/folder.png")}
        />
      )}
      {IconComponent && <IconComponent />}
      <View style={{ flex: 1, marginLeft: iconSrc || IconComponent ? 20 : 0 }}>
        <StyledText variant="h1">{number}</StyledText>
        <StyledText variant="body1" textStyle={styles.tileText}>
          {title}
        </StyledText>
      </View>
    </View>
  );
};

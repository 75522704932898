/* eslint-disable react-native/no-inline-styles */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Animated, ImageBackground, Platform } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import useStyles from "../../styles/commonStyles";

// fades in the page on web only to prevent screen jump (possibly due to slow style loading ?)
export default function WebFadeIn({
  children,
  style,
  off,
  shouldWait = false,
  waitFor,
  background = true,
}: {
  children: ReactNode;
  style?: any;
  off?: boolean;
  shouldWait?: boolean;
  waitFor?: any;
  background?: boolean;
}) {
  const commonStyles = useStyles();
  const [timing, setTiming] = useState<any>();
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useFocusEffect(
    React.useCallback(() => {
      setTiming(
        Animated.timing(fadeAnim, {
          useNativeDriver: true,
          toValue: 1,
          duration: Platform.OS === "web" && !off ? 200 : 0,
        }),
      );
    }, [fadeAnim, off]),
  );

  useEffect(() => {
    if (shouldWait && waitFor !== undefined && timing) {
      timing.start();
    } else if (timing && !shouldWait) {
      timing.start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldWait, timing?.start, waitFor]);

  return (
    <Animated.View
      style={[
        {
          opacity: fadeAnim,
          height: "100%",
          width: "100%",
        },
        style,
      ]}
      testID="web-fade-in"
    >
      {background ? (
        <ImageBackground
          source={require("../../../assets/be-images/Background.png")}
          resizeMode="cover"
          style={commonStyles.backgroundImage}
        >
          {children}
        </ImageBackground>
      ) : (
        <>{children}</>
      )}
    </Animated.View>
  );
}

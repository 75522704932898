import React from "react";
import { Path, Circle } from "react-native-svg";

const GearFilled: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M22.946 13.9076L21.9475 13.0857C21.2636 12.5227 21.2648 11.4762 21.9476 10.9143L22.946 10.0924C23.6565 9.50747 23.8382 8.50444 23.3781 7.70733L21.4066 4.29267C20.9464 3.49561 19.9867 3.15155 19.125 3.47433L17.914 3.92803C17.0845 4.23872 16.1789 3.7147 16.0335 2.84236L15.8209 1.5667C15.6696 0.658922 14.8918 0 13.9715 0H10.0286C9.10819 0 8.33035 0.658922 8.17908 1.56675L7.96646 2.84236C7.82082 3.71625 6.91397 4.2382 6.08602 3.92808L4.875 3.47433C4.0133 3.15155 3.05367 3.49566 2.59341 4.29267L0.621986 7.70728C0.161814 8.5043 0.343501 9.50737 1.05408 10.0924L2.05247 10.9143C2.73647 11.4773 2.73511 12.5237 2.05247 13.0856L1.05403 13.9075C0.343501 14.4925 0.161767 15.4956 0.621939 16.2926L2.59341 19.7073C3.05363 20.5043 4.01306 20.8484 4.875 20.5256L6.08597 20.0719C6.91561 19.7611 7.82105 20.2855 7.96641 21.1576L8.17903 22.4332C8.33035 23.3411 9.10819 24 10.0285 24H13.9714C14.8918 24 15.6696 23.3411 15.8209 22.4333L16.0335 21.1577C16.1791 20.284 17.0858 19.7617 17.9139 20.072L19.125 20.5257C19.9869 20.8485 20.9464 20.5044 21.4066 19.7073L23.3781 16.2926C23.8382 15.4956 23.6565 14.4925 22.946 13.9076ZM12 7.37498C9.44977 7.37498 7.37499 9.44977 7.37499 12C7.37499 14.5502 9.44977 16.625 12 16.625C14.5502 16.625 16.625 14.5502 16.625 12C16.625 9.44977 14.5502 7.37498 12 7.37498ZM12 14.75C10.4836 14.75 9.24999 13.5164 9.24999 12C9.24999 10.4836 10.4836 9.24998 12 9.24998C13.5164 9.24998 14.75 10.4836 14.75 12C14.75 13.5164 13.5164 14.75 12 14.75Z"
        fill={props?.iconColor}
      />
      <Circle
        cx="12"
        cy="12"
        r="5.25"
        fill="white"
        stroke={props?.iconColor}
        strokeWidth="1.5"
      />
    </>
  );
};

export default GearFilled;

import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  snack: {
    position: "absolute",
    bottom: 30,
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    padding: 0,
  },
  text: {
    paddingTop: Platform.OS === "ios" ? 7 : 0,
  },
  error: {
    backgroundColor: "#e13b3b",
  },
  ok: {
    backgroundColor: "#39b939",
  },
});

export default styles;

import { getFocusedRouteNameFromRoute } from "@react-navigation/native";
import React, { useEffect } from "react";
import {
  SafeAreaView,
  View,
  Pressable,
  useWindowDimensions,
  Text,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Logo from "src/assets/images/logo";
import { AnyProgress } from "src/components/progress/progress";
import useCommonStyles from "src/pages/auth/common.styles";
import { invalidateAuth } from "src/redux/features/auth/slice";
import {
  setLanguage,
  languagePreference,
  videoPlayerFullScreen,
  routeName,
  setRouteName,
} from "src/redux/features/misc/slice";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { useTranslation } from "src/translations/useTranslation";
import { CustomButton, CustomSvgIcon } from "src/uikit";
import { LogoCard } from "src/uikit/logoCard";
import useStyles from "../nav.styles";
import { avgDeviceSize } from "./drawerNavOptions";
import StaticWebHeader from "./staticWebHeader";
import { useTheme } from "@react-navigation/native";

// const getCurrentPageFromNavigation = (navigation: any) => {
//   const page = navigation
//     ?.getState()
//     .routes[0].state?.routes?.find((r: any) => r.params)?.name;
//   console.log("page", page);
//   return page;
// };

export const StackNavHeader = ({
  navigation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  route,
  screenProps,
}: {
  navigation: any;
  route?: any;
  screenProps: any;
}) => {
  const { colors } = useTheme();
  const { width: windowWidth } = useWindowDimensions();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const dispatch = useDispatch();
  const reduxLanguage = useSelector(languagePreference);
  const videoFullScreen = useSelector(videoPlayerFullScreen);
  const { text } = useTranslation();
  const { language } = useUserInfo();
  const navRouteName = useSelector(routeName);

  const whitePages = [
    "LessonEdit",
    "LessonPublishedView",
    "LessonPublishedFullView",
    "LessonPublishedByCategoryView",
    "CategoryEdit",
    "CategoryPublishedView",
    "QuizEdit",
    "QuizAnswerEdit",
    "QuizQuestionEdit",
    "QuizUserScreen",
    "TopicEdit",
    "TopicPublishedView",
    "UserView",
    "CustomerEmailEdit",
  ]; //perhaps this is all edit screens ?

  // This is the first approach for showing / hiding progress that works smoothly across platforms
  // It is the responsability of the screen that uses the progress to show initial and set values
  const progressPages = ["QuizUserScreen"];

  const onLanguageChange = React.useCallback(() => {
    if (language !== reduxLanguage) {
      dispatch(setLanguage(language));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, reduxLanguage]);

  useEffect(() => {
    onLanguageChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  if (videoFullScreen) {
    return null;
  }

  return (
    <SafeAreaView style={[commonStyles.header, { backgroundColor: "#fff" }]}>
      <View style={commonStyles.headerLeftColumn}>
        {windowWidth > avgDeviceSize ? (
          <LogoCard isOnlyLogo={true} />
        ) : !screenProps.navigation.canGoBack() ? (
          <LogoCard isOnlyLogo={true} />
        ) : (
          <CustomButton
            icon="chevron-left"
            theme="secondary"
            onPress={() => {
              navigation.goBack();
            }}
            style={{
              borderWidth: 0,
              shadowColor: "#52006A",
              shadowOffset: { width: -2, height: 4 },
              shadowOpacity: 0.2,
              shadowRadius: 3,
              elevation: 8,
              borderRadius: 14,
              width: 44,
              minHeight: 44,
            }}
          />
        )}
      </View>
      <View style={commonStyles.headerCenterColumn}>
        {windowWidth > avgDeviceSize && (
          <StaticWebHeader {...screenProps} route={route} />
        )}
        {windowWidth <= avgDeviceSize &&
          progressPages.includes(getFocusedRouteNameFromRoute(route) || "") && (
            <AnyProgress />
          )}
      </View>
      <View style={commonStyles.headerRightColumn}>
        {windowWidth > avgDeviceSize ? (
          <Pressable
            testID="user-logout-web-button"
            onPress={() => {
              dispatch(setRouteName(undefined));
              dispatch(invalidateAuth());
            }}
            style={[styles.webNavItem, styles.logoutButton]}
          >
            <View style={styles.webNavItemIcon}>
              <CustomSvgIcon iconType="logout" iconSize={20} />
            </View>
            {windowWidth > avgDeviceSize && (
              <Text style={styles.webNavItemLabel}>{text("logout")}</Text>
            )}
          </Pressable>
        ) : (
          <CustomButton
            icon="menu"
            theme="secondary"
            iconColor={colors.primary}
            onPress={() => navigation.toggleDrawer()} // todo: Drawer needs to have opacity 0 whilst closed so set opacity to one on toggle open and hook into close event to set back to zero
            style={styles.menuButton}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

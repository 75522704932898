import React from "react";
import { LazyCategoryPublishedViewListScreen } from "src/components/domain/pages/categoryPublished/categoryPublished.viewListLazy";
import { LazyCategoryPublishedFullViewScreen } from "src/components/domain/pages/categoryPublishedFull/categoryPublishedFull.viewLazy";
import { LazyLessonPublishedViewListScreen } from "src/components/domain/pages/lessonPublished/lessonPublished.viewListLazy";
import { LazyLessonPublishedByCategoryFullViewScreen } from "src/components/domain/pages/lessonPublishedByCategoryFull/lessonPublishedByCategoryFull.viewLazy";
import { LazyLessonPublishedFullViewScreen } from "src/components/domain/pages/lessonPublishedFull/lessonPublishedFull.viewLazy";
import { LazyQuizPublishedViewScreen } from "src/components/domain/pages/quizPublished/quizPublished.viewLazy";
import { LazyTopicPublishedViewListScreen } from "src/components/domain/pages/topicPublished/topicPublished.viewListLazy";
import { LazyTopicPublishedFullViewScreen } from "src/components/domain/pages/topicPublishedFull/topicPublishedFull.viewLazy";
import DeleteAccountScreen from "src/pages/settings/deleteAccount/deleteAccount";
import UserDashboardScreen from "src/pages/user/dashboard/dashboard";
import QuizQuestionUserScreen from "src/pages/user/quiz/quizQuestion";
import QuizResultScreen from "src/pages/user/quiz/result";
import { AuthScreens } from "../auth/authScreens";
import { LazyPersonalDetails, LazySettings } from "../lazy/loader";
import { hiddenOptions } from "../utils/hiddenMenuItem";

export const UserScreens = ({
  Drawer,
  RootStack,
}: {
  Drawer: any;
  RootStack: any;
}) => {
  return (
    <React.Fragment>
      <Drawer.Screen name="Dashboard" component={UserDashboardScreen} />
      <Drawer.Screen name="Settings" component={LazySettings} />
      <Drawer.Screen
        name="DeleteAccountScreen"
        component={DeleteAccountScreen}
      />

      {/* list */}
      <Drawer.Screen
        name="Lessons"
        component={LazyLessonPublishedViewListScreen}
      />
      {/* full page */}
      <Drawer.Screen
        name="LessonPublishedView"
        component={LazyLessonPublishedFullViewScreen}
      />
      <Drawer.Screen
        name="LessonPublishedByCategoryView"
        component={LazyLessonPublishedByCategoryFullViewScreen}
      />

      {/* quiz within a lesson */}
      <RootStack.Screen
        name="QuizPublished"
        component={LazyQuizPublishedViewScreen}
        options={hiddenOptions}
      />

      {/* list */}
      <Drawer.Screen
        name="Categories"
        component={LazyCategoryPublishedViewListScreen}
      />
      {/* full page */}
      <Drawer.Screen
        name="CategoryPublishedView"
        component={LazyCategoryPublishedFullViewScreen}
      />

      {/* list */}
      <Drawer.Screen
        name="Glossary"
        component={LazyTopicPublishedViewListScreen}
      />
      {/* full page */}
      <Drawer.Screen
        name="TopicPublishedView"
        component={LazyTopicPublishedFullViewScreen}
      />

      <RootStack.Screen
        name="QuizUserScreen"
        component={QuizQuestionUserScreen}
        options={hiddenOptions}
      />

      <RootStack.Screen
        name="QuizResultScreen"
        component={QuizResultScreen}
        options={hiddenOptions}
      />

      <RootStack.Screen
        name="PersonalDetails"
        component={LazyPersonalDetails}
        options={hiddenOptions}
      />
      {AuthScreens({ Navigator: RootStack })}
    </React.Fragment>
  );
};

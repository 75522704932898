/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { LessonResourceCollectionState } from "./lesson-resource-collection-state";

import { Lessonresource } from "../../../../../../types/domain/flat-types";
const initialState: LessonResourceCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessonResources = createAsyncThunk(
  "get/lesson-resources",
  async (options?: { lesson?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-resource?lesson=${options?.lesson}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createLessonResource = createAsyncThunk(
    "post/lesson-resource",
    async (lessonResource: Partial<Lessonresource>) => {
      const client = await fetchClient();
      const { data } = await client.post(`lesson-resource/`, { lessonResource });
      return data;
    }
  );


export const updateLessonResource = createAsyncThunk(
  "put/lesson-resource",
  async (lessonResource: Partial<Lessonresource>) => {
    const client = await fetchClient();
    const { data } = await client.put(`lesson-resource/`, { lessonResource });
    return data;
  }
);

export const getLessonResource = createAsyncThunk(
  "get/lesson-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-resource/${id}`);
    return data;
  }
);

export const deleteLessonResource = createAsyncThunk(
  "delete/lesson-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`lesson-resource/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: LessonResourceCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Lesson : undefined;

//   if(currentStateConstraint && actionArgs.lesson && Number(actionArgs.lesson) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Lesson : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const lessonResourcesSlice = createSlice({
  name: "lessonResources",
  initialState,
  reducers: {
    clearLessonResourceItems(state: LessonResourceCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonResources.pending, (state: LessonResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessonResources.fulfilled, (state: LessonResourceCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessonResources.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLessonResource.pending, (state: LessonResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteLessonResource.pending, (state: LessonResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateLessonResource.pending, (state: LessonResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLessonResource.fulfilled, (state: LessonResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteLessonResource.fulfilled, (state: LessonResourceCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateLessonResource.fulfilled, (state: LessonResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createLessonResource.pending, (state: LessonResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createLessonResource.fulfilled, (state: LessonResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getLessonResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteLessonResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateLessonResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonResourceSelector = (id: number) => (state: AppState) => {
  return state.lessonResources?.items?.find((o) => o.Id === id);
}; 

export const lessonResourceLoading = createSelector(
  (state: AppState) => state.lessonResources.status,
  status => status === 'pending'
);

export const lessonResourceErrorSelector = createSelector(
  (state: AppState) => state.lessonResources,
  status => status.error
);


export const lessonResourcesSelector = (lesson?: number) => (state: AppState) => {
  if (!lesson) {
    return undefined;
  }
  return state.lessonResources?.items?.filter((q) => q.Lesson === lesson);
}; 

export const lessonResourcesLoading = createSelector(
  (state: AppState) => state.lessonResources.status,
  status => status === 'pending'
);

export const lessonResourcesErrorSelector = createSelector(
  (state: AppState) => state.lessonResources,
  status => status.error
);

export const { clearLessonResourceItems } = lessonResourcesSlice.actions;

export default lessonResourcesSlice.reducer;

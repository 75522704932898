/* eslint-disable prettier/prettier */
import { useTheme } from "@react-navigation/native";
import React from "react";
import { ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import useStyles from "src/pages/layout/cms.styles";
import { ListPageBody } from "src/pages/layout/listPageBody";
import usePageStyles from "src/pages/layout/page.styles";
import { PageHeader } from "src/pages/layout/pageheader";
import { categoryPublishedsLoading, getCategoryPublisheds, searchCategoryPublisheds } from "src/redux/domain/features/categoryPublished/collection-slice";
import { useCategoryPublishedsSearchCollection } from "src/redux/domain/features/categoryPublished/useCategoryPublishedSearchCollection";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { useTranslation } from "src/translations/useTranslation";
import { initialLoadSize, useOnScrollContainerCloseToBottom } from "src/utils";

const CategoryPublishedViewListScreen = ({
  navigation,
  route,
  showTitle = true,
  // showPreamble = true,
  // hideGoBack,
  style,
  // contentStyle,
  background,
  parentPageIsCloseToBottomOfScroll,
}: {
  navigation: any;
  route?: any;
  showTitle?: boolean;
  // showPreamble?: boolean;
  // hideGoBack?: boolean;
  style?: any;
  // contentStyle?: any;
  background?: boolean;
  parentPageIsCloseToBottomOfScroll?: boolean;
}) => {
  const styles = useStyles();
  const pageStyles = usePageStyles();
  const loading = useSelector(categoryPublishedsLoading);
  const { language } = useUserInfo();
  const { colors } = useTheme();

  const {
    loadMore,
    loadCategoryPublisheds,
    basicParams,
    searchResult: categoryPublisheds,
  } = useCategoryPublishedsSearchCollection(initialLoadSize, language);

  const dispatch = useDispatch();
  if(!categoryPublisheds){
    dispatch(getCategoryPublisheds({ language: language || 1,offset: 0, limit: 20 }));
  }

  const { scrollCallback } = useOnScrollContainerCloseToBottom({
    parentPageIsCloseToBottomOfScroll,
    onScrollContainerCloseToBottom: loadMore,
  });
  const { text } = useTranslation();

  const getIconPath = (
    categoryPublished: Categorypublished & WithMediaResources,
  ) => {
    const iconPath = categoryPublished?.resources?.find(
      (r) => r.Key === "Icon",
    )?.Url;
    if (iconPath) {
      return iconPath;
    }
  };

  return (
    <WebFadeIn style={style} background={background}>
      <View
        style={[
          pageStyles.page,
          styles?.categoryPublisheds,
          style,
          { backgroundColor: colors.secondary },
        ]}
      >
        <ScrollView
          scrollEnabled={true}
          nestedScrollEnabled={true}
          style={[pageStyles.container]}
          contentContainerStyle={[pageStyles.body, { paddingBottom: 20 }]}
          showsVerticalScrollIndicator={false}
          onScroll={scrollCallback}
        >
          <PageHeader
            title={
              showTitle
                ? text("categoryPublishedViewList.title")[0] ||
                  "All CategoryPublisheds"
                : ""
            }
            // preamble={showPreamble ? text("categoryPublishedViewList.preamble")[0] || "All categorypublisheds" : ""}
            navigation={navigation}
            hideGoBack={true}
            style={style}
          />
          <ListPageBody
            navigation={navigation}
            route={route}
            listItems={categoryPublisheds}
            languageSorting={false} // as the search hook above is already sorting by language we don't need any language sorting in the view
            loading={loading}
            style={style}
            basicParams={basicParams}
            loadMore={loadMore}
            doLoad={loadCategoryPublisheds}
            destination={"CategoryPublishedView"}
            showIcon
            orderBy={["Order"]}
            paramKey="categoryPublishedFullId"
            mode="view"
            getIconPath={getIconPath}
          />
        </ScrollView>
      </View>
    </WebFadeIn>
  );
};

export default CategoryPublishedViewListScreen;

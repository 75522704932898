import puzzle from "../../../assets/captcha/1/puzzle.jpg";
import puzzlePiece from "../../../assets/captcha/1/puzzlePiece.png";
import puzzle2 from "../../../assets/captcha/2/puzzle.jpg";
import puzzlePiece2 from "../../../assets/captcha/2/puzzlePiece.png";
import puzzle3 from "../../../assets/captcha/3/puzzle.png";
import puzzlePiece3 from "../../../assets/captcha/3/puzzlePiece.png";
import puzzle4 from "../../../assets/captcha/4/puzzle.png";
import puzzlePiece4 from "../../../assets/captcha/4/puzzlePiece.png";
import puzzle5 from "../../../assets/captcha/5/puzzle.png";
import puzzlePiece5 from "../../../assets/captcha/5/puzzlePiece.png";
import puzzle6 from "../../../assets/captcha/6/puzzle.png";
import puzzlePiece6 from "../../../assets/captcha/6/puzzlePiece.png";

export default [
  {
    puzzleUrl: puzzle,
    puzzlePieceUrl: puzzlePiece,
    matchingPair: [
      { matchMinValue: 96, matchMaxValue: 102, deviceWidth: 420 },
      { matchMinValue: 78, matchMaxValue: 96, deviceWidth: 360 },
      { matchMinValue: 68, matchMaxValue: 73, deviceWidth: 320 },
      { matchMinValue: 55, matchMaxValue: 61, deviceWidth: 200 },
    ],
  },
  {
    puzzleUrl: puzzle2,
    puzzlePieceUrl: puzzlePiece2,
    matchingPair: [
      { matchMinValue: 122, matchMaxValue: 128, deviceWidth: 420 },
      { matchMinValue: 104, matchMaxValue: 117, deviceWidth: 360 },
      { matchMinValue: 68, matchMaxValue: 73, deviceWidth: 320 },
      { matchMinValue: 82, matchMaxValue: 88, deviceWidth: 200 },
    ],
  },
  {
    puzzleUrl: puzzle3,
    puzzlePieceUrl: puzzlePiece3,
    matchingPair: [
      { matchMinValue: 249, matchMaxValue: 255, deviceWidth: 420 },
      { matchMinValue: 200, matchMaxValue: 208, deviceWidth: 360 },
      { matchMinValue: 209, matchMaxValue: 222, deviceWidth: 360 },
      { matchMinValue: 233, matchMaxValue: 239, deviceWidth: 320 },
      { matchMinValue: 165, matchMaxValue: 173, deviceWidth: 200 },
    ],
  },
  {
    puzzleUrl: puzzle4,
    puzzlePieceUrl: puzzlePiece4,
    matchingPair: [
      { matchMinValue: 249, matchMaxValue: 255, deviceWidth: 420 },
      { matchMinValue: 209, matchMaxValue: 222, deviceWidth: 360 },
      { matchMinValue: 233, matchMaxValue: 239, deviceWidth: 320 },
      { matchMinValue: 165, matchMaxValue: 173, deviceWidth: 200 },
    ],
  },
  {
    puzzleUrl: puzzle5,
    puzzlePieceUrl: puzzlePiece5,
    matchingPair: [
      { matchMinValue: 96, matchMaxValue: 102, deviceWidth: 420 },
      { matchMinValue: 90, matchMaxValue: 103, deviceWidth: 360 },
      { matchMinValue: 81, matchMaxValue: 87, deviceWidth: 320 },
      { matchMinValue: 61, matchMaxValue: 69, deviceWidth: 200 },
    ],
  },
  {
    puzzleUrl: puzzle6,
    puzzlePieceUrl: puzzlePiece6,
    matchingPair: [
      { matchMinValue: 256, matchMaxValue: 262, deviceWidth: 420 },
      { matchMinValue: 235, matchMaxValue: 248, deviceWidth: 360 },
      { matchMinValue: 214, matchMaxValue: 220, deviceWidth: 360 },
      { matchMinValue: 168, matchMaxValue: 174, deviceWidth: 320 },
      { matchMinValue: 150, matchMaxValue: 163, deviceWidth: 200 },
    ],
  },
];

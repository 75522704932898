import { StyleSheet } from "react-native";
import { maxPageWidth } from "src/components/forms/form.styles";

const styles = StyleSheet.create({
  progress: {
    position: "absolute",
    maxWidth: maxPageWidth,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10,
  },
  progressContainer: {
    position: "absolute",
    top: 0,
    width: 145,
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 1,
    height: "100%",
  },
  progressBar: {
    borderRadius: 6,
    height: 6,
    width: 145,
    opacity: 1,
  },
});

export default styles;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import { LanguageCollectionState } from "./language-collection-state";

import { Language } from "../../../../../../types/domain/flat-types";
const initialState: LanguageCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLanguages = createAsyncThunk(
  "get/languages",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`language?offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createLanguage = createAsyncThunk(
    "post/language",
    async (language: Partial<Language>) => {
      const client = await fetchClient();
      const { data } = await client.post(`language/`, { language });
      return data;
    }
  );


export const updateLanguage = createAsyncThunk(
  "put/language",
  async (language: Partial<Language>) => {
    const client = await fetchClient();
    const { data } = await client.put(`language/`, { language });
    return data;
  }
);

export const getLanguage = createAsyncThunk(
  "get/language",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`language/${id}`);
    return data;
  }
);

export const deleteLanguage = createAsyncThunk(
  "delete/language",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`language/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    clearLanguageItems(state: LanguageCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLanguages.pending, (state: LanguageCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLanguages.fulfilled, (state: LanguageCollectionState, action) => {

  
      state.items = action.payload; 
  

      state.status = "fulfilled";
    });
    builder.addCase(getLanguages.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLanguage.pending, (state: LanguageCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteLanguage.pending, (state: LanguageCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateLanguage.pending, (state: LanguageCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLanguage.fulfilled, (state: LanguageCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteLanguage.fulfilled, (state: LanguageCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateLanguage.fulfilled, (state: LanguageCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createLanguage.pending, (state: LanguageCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createLanguage.fulfilled, (state: LanguageCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getLanguage.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteLanguage.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateLanguage.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const languageSelector = (id: number) => (state: AppState) => {
  return state.languages?.items?.find((o) => o.Id === id);
}; 

export const languageLoading = createSelector(
  (state: AppState) => state.languages.status,
  status => status === 'pending'
);

export const languageErrorSelector = createSelector(
  (state: AppState) => state.languages,
  status => status.error
);


export const languagesSelector = createSelector(
  (state: AppState) => state.languages,
  state => state.items
);

export const languagesLoading = createSelector(
  (state: AppState) => state.languages.status,
  status => status === 'pending'
);

export const languagesErrorSelector = createSelector(
  (state: AppState) => state.languages,
  status => status.error
);

export const { clearLanguageItems } = languagesSlice.actions;

export default languagesSlice.reducer;

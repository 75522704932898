import React, { useState } from "react";
import { View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { authUser, error } from "src/redux/features/auth/slice";
import authStyles from "../layout/auth.styles";
import formStyles from "../../../components/forms/authForm.styles";
import * as Yup from "yup";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFormik } from "formik";
import { Credentials } from "uiTypes";
import { useFocusEffect } from "@react-navigation/native";
import { AuthHeader } from "../layout/auth-header";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { CodeInput } from "./codeInput";
import { useTranslation } from "src/translations/useTranslation";
import { fetchClient } from "src/api/axios/axiosApi";

export const TwoFactor = ({ navigation }: { navigation: any }) => {
  const err = useSelector(error);
  const [err2, setErr2] = useState("");
  const dispatch = useDispatch();
  const [ready, setReady] = useState(false);
  const { t } = useTranslation();

  const initialValues: Credentials = {
    show2fa: true,
    email: "",
    password: "",
    passwordCopy: "",
    code: "      ",
    token: "",
    reset: false,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      show2fa: Yup.boolean(),
      password: Yup.string().required("Required"),
      code: Yup.string().when("show2fa", {
        is: true,
        then: Yup.string().required("Required"),
      }),
    }),
    onSubmit: async (credential) => {
      if (!credential.reset) {
        dispatch(authUser(credential));
      } else {
        const client = await fetchClient();
        client
          .post(`auth/reset/data`, {
            email: credential.email,
            password: credential.password,
            passwordCopy: credential.passwordCopy,
            token: credential.token,
            code: credential.code,
          })
          .then(() => {
            navigation.navigate("ResetPassword");
            formik.resetForm();
            setErr2("");
          })
          .catch((e) => {
            setErr2(e);
          });
      }
    },
  });

  useFocusEffect(() => {
    if (!formik.values.email) {
      AsyncStorage.getItem("authFormik").then((af) => {
        if (!af) {
          navigation.navigate("Login");
          return;
        }
        const authFormik: any = JSON.parse(af as string);
        formik.setFieldValue("show2fa", true, false);
        formik.setFieldValue("email", authFormik.email, false);
        formik.setFieldValue("password", authFormik.password, false);
        formik.setFieldValue("passwordCopy", authFormik.passwordCopy, false);
        formik.setFieldValue("token", authFormik.token, false);
        formik.setFieldValue("reset", authFormik.reset, false);
        AsyncStorage.removeItem("authFormik");
        setReady(true);
      });
    }
  });

  if (ready === false) {
    return null;
  }

  return (
    <WebFadeIn>
      <View style={authStyles.authPage} testID="auth-page">
        <View style={[formStyles.form]}>
          <AuthHeader title={t("security.enterCode")} preamble={" "} />
          <CodeInput
            formik={formik}
            navigation={navigation}
            error={err || err2}
          />
        </View>
      </View>
    </WebFadeIn>
  );
};

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { CategoryResourceCollectionState } from "./category-resource-collection-state";

import { Categoryresource } from "../../../../../../types/domain/flat-types";
const initialState: CategoryResourceCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCategoryResources = createAsyncThunk(
  "get/category-resources",
  async (options?: { category?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-resource?category=${options?.category}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createCategoryResource = createAsyncThunk(
    "post/category-resource",
    async (categoryResource: Partial<Categoryresource>) => {
      const client = await fetchClient();
      const { data } = await client.post(`category-resource/`, { categoryResource });
      return data;
    }
  );


export const updateCategoryResource = createAsyncThunk(
  "put/category-resource",
  async (categoryResource: Partial<Categoryresource>) => {
    const client = await fetchClient();
    const { data } = await client.put(`category-resource/`, { categoryResource });
    return data;
  }
);

export const getCategoryResource = createAsyncThunk(
  "get/category-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-resource/${id}`);
    return data;
  }
);

export const deleteCategoryResource = createAsyncThunk(
  "delete/category-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`category-resource/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: CategoryResourceCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Category : undefined;

//   if(currentStateConstraint && actionArgs.category && Number(actionArgs.category) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Category : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const categoryResourcesSlice = createSlice({
  name: "categoryResources",
  initialState,
  reducers: {
    clearCategoryResourceItems(state: CategoryResourceCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryResources.pending, (state: CategoryResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCategoryResources.fulfilled, (state: CategoryResourceCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getCategoryResources.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCategoryResource.pending, (state: CategoryResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteCategoryResource.pending, (state: CategoryResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateCategoryResource.pending, (state: CategoryResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCategoryResource.fulfilled, (state: CategoryResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteCategoryResource.fulfilled, (state: CategoryResourceCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateCategoryResource.fulfilled, (state: CategoryResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createCategoryResource.pending, (state: CategoryResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createCategoryResource.fulfilled, (state: CategoryResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getCategoryResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteCategoryResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateCategoryResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const categoryResourceSelector = (id: number) => (state: AppState) => {
  return state.categoryResources?.items?.find((o) => o.Id === id);
}; 

export const categoryResourceLoading = createSelector(
  (state: AppState) => state.categoryResources.status,
  status => status === 'pending'
);

export const categoryResourceErrorSelector = createSelector(
  (state: AppState) => state.categoryResources,
  status => status.error
);


export const categoryResourcesSelector = (category?: number) => (state: AppState) => {
  if (!category) {
    return undefined;
  }
  return state.categoryResources?.items?.filter((q) => q.Category === category);
}; 

export const categoryResourcesLoading = createSelector(
  (state: AppState) => state.categoryResources.status,
  status => status === 'pending'
);

export const categoryResourcesErrorSelector = createSelector(
  (state: AppState) => state.categoryResources,
  status => status.error
);

export const { clearCategoryResourceItems } = categoryResourcesSlice.actions;

export default categoryResourcesSlice.reducer;

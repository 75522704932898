import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "../../reducer/root-reducer";

export interface Progress {
  total: number;
  progress: number;
  show: boolean;
}

export type MiscState = {
  error?: string;
  pendingNumber?: string;
  language?: number;
  forceNav?: boolean;
  videoPlayerFullScreen?: boolean;
  progress?: Progress;
  routeName?: string;
};

export const progress = createSelector(
  (state: AppState) => state.misc.progress,
  (num) => num,
);

export const videoPlayerFullScreen = createSelector(
  (state: AppState) => state.misc.videoPlayerFullScreen,
  (num) => num,
);

export const pendingNumber = createSelector(
  (state: AppState) => state.misc.pendingNumber,
  (num) => num,
);

export const languagePreference = createSelector(
  (state: AppState) => state.misc.language,
  (num) => num,
);

export const forceNav = createSelector(
  (state: AppState) => state.misc.forceNav,
  (num) => num,
);

export const routeName = createSelector(
  (state: AppState) => state.misc.routeName,
  (num) => num,
);

const initialState: MiscState = {
  pendingNumber: "",
};

const miscSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    setPendingNumber(state: MiscState, action: PayloadAction<string>) {
      state.pendingNumber = action.payload;
    },
    setForceNav(state: MiscState, action: PayloadAction<boolean>) {
      state.forceNav = action.payload;
    },
    setRouteName(state: MiscState, action: PayloadAction<string | undefined>) {
      state.routeName = action.payload;
    },
    clearPendingNumber(state: MiscState) {
      state.pendingNumber = "";
    },
    setLanguage(state: MiscState, action: PayloadAction<number>) {
      state.language = action.payload;
    },
    setVideoPlayerFullScreen(state: MiscState, action: PayloadAction<boolean>) {
      state.videoPlayerFullScreen = action.payload;
    },
    setProgress(state: MiscState, action: PayloadAction<Progress>) {
      state.progress = action.payload;
    },
  },
});

export const {
  setPendingNumber,
  clearPendingNumber,
  setLanguage,
  setForceNav,
  setVideoPlayerFullScreen,
  setProgress,
  setRouteName,
} = miscSlice.actions;

export default miscSlice.reducer;

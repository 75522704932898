import React from "react";
import { Path } from "react-native-svg";

const Close: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M3.06066 0.93934C2.47487 0.353553 1.52513 0.353553 0.93934 0.93934C0.353553 1.52513 0.353553 2.47487 0.93934 3.06066L3.06066 0.93934ZM20.9393 23.0607C21.5251 23.6464 22.4749 23.6464 23.0607 23.0607C23.6464 22.4749 23.6464 21.5251 23.0607 20.9393L20.9393 23.0607ZM0.93934 3.06066L20.9393 23.0607L23.0607 20.9393L3.06066 0.93934L0.93934 3.06066Z"
        fill={props?.iconColor}
      />
      <Path
        d="M20.9393 0.93934C21.5251 0.353553 22.4749 0.353553 23.0607 0.93934C23.6464 1.52513 23.6464 2.47487 23.0607 3.06066L20.9393 0.93934ZM3.06066 23.0607C2.47487 23.6464 1.52513 23.6464 0.939339 23.0607C0.353552 22.4749 0.353552 21.5251 0.939339 20.9393L3.06066 23.0607ZM23.0607 3.06066L3.06066 23.0607L0.939339 20.9393L20.9393 0.93934L23.0607 3.06066Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default Close;

import { Dimensions, StyleSheet } from "react-native";
import { maxPageWidth } from "src/components/forms/form.styles";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import {
  genLargePageViewStyles,
  largePageWidth,
} from "src/pages/layout/cms.styles";
import { vars } from "src/styles";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    quizPage: {
      ...genLargePageViewStyles(windowWidth),
      paddingHorizontal:
        windowWidth > largePageWidth ? 100 : windowWidth > mobileBreak ? 0 : 20,
    },
    quizPageHeader: {},
    quizPageBody: {
      maxWidth: maxPageWidth,
      width: "100%",
      marginBottom: 10,
    },
    quizPageBodyContentContainer: {
      flexGrow: 1,
      paddingBottom: 30,
    },
    resultImage: {
      height: 235,
      width: 235,
      borderWidth: 1,
      marginBottom: 30,
      marginTop: 30,
    },
    quizResultBody: {
      width: "100%",
      alignItems: "center",
      paddingHorizontal: 20,
    },
    resultText: {
      marginBottom: 0,
      color: "#A6B1C2",
    },
    textStyle: {
      color: "#A6B1C2",
      fontSize: 18,
    },
    tokenText: {
      marginTop: 0,
    },
    previousAward: {
      marginTop: 20,
    },
    nextStepText: {
      marginTop: 25,
      marginBottom: 35,
    },
    otherLessons: {
      marginTop: 30,
      width: "100%",
    },
    confirmButtonContainer: {
      width: "100%",
      maxWidth: windowWidth > mobileBreak ? 402 : "100%",
      alignItems: "center",
      alignSelf: "center",
    },
    confirmButton: {
      maxWidth: windowWidth > mobileBreak ? 402 : "100%",
      width: 402,
    },
    questionsContainer: {
      width: "100%",
      alignItems: "center",
      maxWidth: maxPageWidth,
      flexGrow: 1,
    },
    progress: {
      position: "absolute",
      maxWidth: maxPageWidth,
      width: "100%",
      alignItems: "center",
      zIndex: 10,
    },
    progressContainer: {
      position: "absolute",
      width: 145,
      alignItems: "center",
      top: -60,
    },
    progressBar: {
      borderRadius: 6,
      height: 6,
      width: 145,
    },
    title: {
      marginTop: 0,
      marginBottom: windowWidth > mobileBreak ? 0 : 12,
    },
    preamble: {
      color: vars.black,
      fontSize: 18,
      lineHeight: 27,
      marginBottom: windowWidth > mobileBreak ? 0 : 30,
    },
  });
};

export default styles;

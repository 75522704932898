import { Dimensions, StyleSheet } from "react-native";
import { vars } from "src/styles";

export const largePageWidth = 1024;

export const genLargePageViewStyles = (_windowWidth: number) => ({
  paddingHorizontal: _windowWidth > largePageWidth ? 100 : 0,
  backgroundColor: "#ffffff",
  maxWidth: _windowWidth > largePageWidth ? largePageWidth : undefined,
});

const styles = () => {
  const windowWidth = Dimensions.get("window").width;

  return StyleSheet.create({
    lessons: {},
    lesson: {},
    lessonPublisheds: {},
    lessonEditPage: genLargePageViewStyles(windowWidth),
    lessonEditList: {},
    lessonPublishedFull: genLargePageViewStyles(windowWidth),
    lessonPublishedByCategoryFull: genLargePageViewStyles(windowWidth),
    lessonPublishedFull_slot_last: { borderWidth: 1, padding: 0 }, //exists by convention
    categorys: {},
    categoryEditPage: genLargePageViewStyles(windowWidth),
    categoryEditList: {},
    categoryPublisheds: {},
    categoryPublishedFull: {}, //genLargePageViewStyles(),
    categoryList: {},
    topics: {},
    topicsList: {},
    topicEditPage: genLargePageViewStyles(windowWidth),
    topicEditList: {},
    topicPublishedFull: genLargePageViewStyles(windowWidth),
    customerEmailEditPage: genLargePageViewStyles(windowWidth),

    quiz: {},
    quizEditPage: genLargePageViewStyles(windowWidth),
    quizEditList: {},
    quizAnswerEditPage: {
      ...genLargePageViewStyles(windowWidth),
      height: "100%",
    },
    quizQuestionEditPage: genLargePageViewStyles(windowWidth),
    quizList: {},

    title: {
      marginTop: 0,
    },
    intro: {
      marginTop: 20,
      marginBottom: 20,
      lineHeight: 27,
      color: vars.light,
    },
    article: {
      marginTop: 20,
      lineHeight: 27,
      color: vars.light,
    },
  });
};

export default styles;

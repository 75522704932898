import React from "react";
import { View } from "react-native";
import { StyledText } from "src/styles/text";
import styles from "./auth.styles";

export const AuthHeader = ({
  title,
  preamble,
  style,
}: {
  title: string;
  preamble: string;
  style?: any;
}) => {
  return (
    <View>
      <StyledText variant="h1" style={[styles.title, style?.title]}>
        {title}
      </StyledText>
      <StyledText variant="body1" style={[styles.preamble, style?.preamble]}>
        {preamble}
      </StyledText>
    </View>
  );
};

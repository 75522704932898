import { Platform, StyleSheet } from "react-native";
import { vars } from "src/styles";

//https://transform.tools/css-to-js

const styles = StyleSheet.create({
  container: {
    backgroundColor: vars.appBackground,
  },
  home: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    alignSelf: "center",
    width: "100%",
    maxWidth: 400,
    padding: 20,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: Platform.OS === "web" ? "center" : "space-between",
    alignItems: "center",
    padding: 20,
    width: "100%",
    marginTop: Platform.OS === "android" ? 20 : 0,
  },
  logo: {
    marginTop: 80, //100 - 20 (padding)
    marginBottom: 40,
    height: 202,
    width: 164,
  },
  button: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  version: {
    position: "absolute",
    bottom: 0,
    left: 0,
    margin: 10,
  },
  appName: {
    marginTop: 30,
  },
  homeText: {
    marginTop: 10,
    marginBottom: 40,
  },
  imageContainer: {
    marginTop: 20,
    marginBottom: 15,
  },
});

export default styles;

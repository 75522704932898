import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  imgContainer: {
    // flexBasis: "fit-content",
    // position: "relative",
  },
  widthConstraint: {
    maxWidth: "100%",
  },
  image: {
    // flexBasis: "fit-content",
  },
});

export default styles;

import React from "react";
import { View, Image } from "react-native";
import authStyles from "../layout/auth.styles";
import formStyles from "../../../components/forms/authForm.styles";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { StyledText } from "src/styles/text";
import { useDispatch, useSelector } from "react-redux";
import { resendSignUpMail, signUpData } from "src/redux/features/signUp/slice";
import useStyles from "../common.styles";
import { PageBody } from "src/pages/layout/pageBody";
import { CustomButton } from "src/uikit";
import { useTheme } from "@react-navigation/native";

export default function SignUpConfirmationScreen({
  navigation,
}: {
  navigation: any;
}) {
  const commonStyles = useStyles();
  const dispatch = useDispatch();
  const credentials = useSelector(signUpData);
  const { colors } = useTheme();

  return (
    <WebFadeIn>
      <PageBody style={{ margin: "auto", backgroundColor: colors.secondary }}>
        <View style={[authStyles.authPage]} testID="auth-page">
          <View style={commonStyles.imageContainer}>
            <Image
              source={require("../../../../assets/be-images/Message.png")}
              style={{
                height: 214,
                width: 240,
              }}
            />
          </View>
          <View style={[formStyles.form]} testID="auth-conf-form">
            <StyledText style={commonStyles.pageTitle} variant="h1">
              Confirmation sent
            </StyledText>
            <View style={[formStyles.fieldContainer]}>
              <CustomButton
                onPress={() => navigation.navigate("Login")}
                testID={"Login"}
                title={"Log in"}
              />
            </View>
            <View style={[formStyles.fieldContainer]}>
              <CustomButton
                onPress={() => {
                  dispatch(resendSignUpMail(credentials?.email!));
                }}
                testID={"ResendConfirmation"}
                title={"Send again"}
                theme="link"
              />
            </View>
          </View>
        </View>
      </PageBody>
    </WebFadeIn>
  );
}

import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect, useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import {
  authenticateAsync,
  hasHardwareAsync,
  isEnrolledAsync,
} from "expo-local-authentication";
import * as SecureStore from "expo-secure-store";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Image, Platform, Pressable, Text, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import Recaptcha from "src/components/recaptcha/recaptcha";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { PageBody } from "src/pages/layout/pageBody";
import { StyledText } from "src/styles/text";
import { CustomButton, CustomTextInput } from "src/uikit";
import CustomIcon from "src/uikit/CustomIcon/CustomIcon";
import CustomLoader from "src/uikit/customLoader/customLoader";
import { Credentials } from "uiTypes";
import * as Yup from "yup";
import pinkLogo from "../../../../assets/vertical.svg";
import formStyles from "../../../components/forms/authForm.styles";
import {
  authSelector,
  authUser,
  error,
  isTwoFactorAuthEnabled,
} from "../../../redux/features/auth/slice";
import useStyles from "../common.styles";
import authStyles from "../layout/auth.styles";

export default function LoginScreen({ navigation }: { navigation: any }) {
  const { colors } = useTheme();
  const [state, setState] = useState({
    logo: pinkLogo,
    isBioMetricEnrolled: false,
    isCaptchaVerified: false,
    credentials: "",
    invalidLoginAttempt: 0,
  });
  const {
    logo,
    isBioMetricEnrolled,
    isCaptchaVerified,
    credentials,
    invalidLoginAttempt,
  } = state;
  const commonStyles = useStyles();
  const dispatch = useDispatch();
  let isTwoFactorEnabled = useSelector(isTwoFactorAuthEnabled);
  let authRed = useSelector(authSelector);
  const err = useSelector(error);

  const initialValues: Credentials = {
    show2fa: false,
    email: "",
    password: "",
    code: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: submitLogin,
  });

  useEffect(() => {
    if (Platform.OS !== "web") {
      isBioMetricSupported();
    }
  }, []);

  useEffect(() => {
    if (authRed.invalidLoginAttempt) {
      setState({ ...state, isCaptchaVerified: false });
    }
  }, [authRed.invalidLoginAttempt]);

  useFocusEffect(() => {
    if (isTwoFactorEnabled && formik.values.show2fa === false) {
      AsyncStorage.setItem("authFormik", JSON.stringify(formik.values)).then(
        () => {
          navigation.navigate("TwoFactor");
        },
      );
    }
  });

  // check the device supported biometric
  async function isBioMetricSupported() {
    const isHardwareSupport = await hasHardwareAsync();
    let isEnrolled = false;

    if (isHardwareSupport) {
      isEnrolled = await isEnrolledAsync();
    }
    if (isEnrolled) {
      const cred = await storedCredentials();
      setState({
        ...state,
        isBioMetricEnrolled: isEnrolled,
        credentials: cred ?? "",
      });
    }
  }

  // login with biometric
  async function biometricsAuth() {
    let result = await authenticateAsync();
    if (result.success) {
      if (credentials) {
        const loginCred = JSON.parse(credentials);
        submitLogin({ ...loginCred, code: "", show2fa: false });
      }
    }
  }

  // login
  function submitLogin(credential: any) {
    credential.email = credential.email?.trim();
    dispatch(authUser(credential));
    setState({ ...state, invalidLoginAttempt: invalidLoginAttempt + 1 });
  }

  // get stored credential from device memory
  async function storedCredentials() {
    const credential = await SecureStore.getItemAsync("credential");
    return credential;
  }

  // on captcha verified
  function onVarified(isVerified: boolean) {
    setState({ ...state, isCaptchaVerified: isVerified });
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  return (
    <LinearGradient
      colors={[colors.primaryBackground, colors.secondaryBackground]}
      start={{
        x: 0,
        y: 0,
      }}
      end={{
        x: 0,
        y: 1,
      }}
      style={{ height: "100%" }}
    >
      <WebFadeIn background={false}>
        <PageBody style={{ margin: "auto" }}>
          <View style={[authStyles.authPage]} testID="auth-page">
            <View style={commonStyles.imageContainer}>
              <Image
                source={logo}
                style={{
                  height: 135,
                  width: 91,
                }}
              />
            </View>
            {authRed.isLoading ? (
              <CustomLoader />
            ) : (
              <View style={[formStyles.form]}>
                <StyledText style={commonStyles.pageTitle} variant="h1">
                  Log in
                </StyledText>
                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    testID="email"
                    placeholder="Email"
                    onChangeText={formik.handleChange("email")}
                    onBlur={formik.handleBlur("email")}
                    value={formik.values.email}
                    onKeyPress={handleKeyDown}
                    errorMessage={formik.touched.email && formik.errors.email}
                    textContentType="emailAddress"
                    icon={<CustomIcon iconName={"email-outline"} />}
                  />
                </View>
                <View style={formStyles.fieldContainer}>
                  <CustomTextInput
                    testID="password"
                    placeholder="Password"
                    onChangeText={formik.handleChange("password")}
                    onBlur={formik.handleBlur("password")}
                    value={formik.values.password}
                    onKeyPress={handleKeyDown}
                    secureTextEntry
                    errorMessage={
                      formik.touched.password && formik.errors.password
                    }
                    textContentType="password"
                    icon={<CustomIcon iconName={"lock-outline"} />}
                  />
                </View>

                <View style={authStyles.authAction}>
                  <Pressable
                    testID="ForgottenPassword"
                    onPress={() => navigation.navigate("ForgottenPassword")}
                  >
                    <StyledText
                      variant={"linkText"}
                      textStyle={{
                        ...commonStyles.forgottenPassword,
                        ...{ color: colors.primary },
                      }}
                    >
                      Forgotten password?
                    </StyledText>
                  </Pressable>
                </View>
                {invalidLoginAttempt > 3 && (
                  <Recaptcha onVarified={onVarified} />
                )}
                <View style={formStyles.fieldContainer}>
                  <CustomButton
                    disabled={invalidLoginAttempt > 3 && !isCaptchaVerified}
                    onPress={() => formik.handleSubmit()}
                    title={"Log in"}
                    testID="login"
                  />
                  <View>
                    <Text>{err ? (err.toString() as string) : ""}</Text>
                  </View>
                </View>
                {isBioMetricEnrolled && (
                  <>
                    <View style={{ alignItems: "center" }}>
                      <StyledText
                        variant={"h3"}
                        textStyle={{ color: colors.primary }}
                      >
                        -- OR --
                      </StyledText>
                    </View>
                    <View style={formStyles.fieldContainer}>
                      {!credentials && (
                        <View>
                          <Text>
                            Enter your credentials to enable biometric
                          </Text>
                        </View>
                      )}
                      <CustomButton
                        disabled={
                          !credentials ||
                          (invalidLoginAttempt > 3 && !isCaptchaVerified)
                        }
                        onPress={biometricsAuth}
                        title={"Log in with Biometric"}
                        testID="biometric"
                        style={
                          !credentials
                            ? {
                                backgroundColor: "#B7BDC0",
                                borderColor: "#B7BDC0",
                              }
                            : {}
                        }
                      />
                    </View>
                  </>
                )}
              </View>
            )}
          </View>
        </PageBody>
      </WebFadeIn>
    </LinearGradient>
  );
}

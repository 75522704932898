import React from "react";
import { fetchClient } from "../../../api/axios/axiosApi";
import url from "url";
import { passwordRegex, passwordStrengthMessage } from "../utils";
import { View, Text } from "react-native";
import authStyles from "../layout/auth.styles";
import formStyles from "src/components/forms/authForm.styles";
import { AppOrWebLoginButtons } from "src/components/buttons/appOrWebLoginButtons";
import { CustomButton, CustomTextInput } from "src/uikit";
import { PageHeader } from "src/pages/layout/pageheader";
import { PageBody } from "src/pages/layout/pageBody";
import { useTranslation } from "src/translations/useTranslation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useTheme } from "@react-navigation/native";
import CustomIcon from "src/uikit/CustomIcon/CustomIcon";

const ResetPWScreen = ({
  navigation,
  route,
  styleOverrides,
  SubmitButtonOverride,
  handleSubmitOverride,
}: {
  navigation: any;
  route?: any;
  styleOverrides?: any;
  SubmitButtonOverride?: any; //todo: why can't we use ReactNode here ?
  handleSubmitOverride?: any;
}) => {
  const token = route?.params?.token || "";
  // const { success } = route?.params;
  const [pw, setPw] = React.useState<string>("");
  const [pw2, setPw2] = React.useState<string>("");
  const [err, setErr] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const { text } = useTranslation();
  const { colors } = useTheme();

  const resetPWValidation = async () => {
    if (!pw || !pw2) {
      setErr(text("changePassword.validation.bothRequired"));
      return false;
    }
    if (pw !== pw2) {
      setErr(text("changePassword.validation.samePassword"));
      return false;
    }
    if (passwordRegex.test(pw) === false) {
      setErr(passwordStrengthMessage);
      return false;
    }
    return true;
  };

  const navTo2FA = async () => {
    const tokenValues = url.parse("?" + token, true).query;
    AsyncStorage.setItem(
      "authFormik",
      JSON.stringify({
        password: pw,
        passwordCopy: pw2,
        email: tokenValues.u,
        reset: true,
        token,
      }),
    ).then(() => {
      setLoading(false);
      setComplete(true);
      reset();
      navigation.navigate("TwoFactor");
    });
  };

  let fStyles: any, aStyles: any;

  if (styleOverrides) {
    fStyles = styleOverrides.formStyles;
    aStyles = styleOverrides.authStyles;
  } else {
    fStyles = formStyles;
    aStyles = authStyles;
  }

  const reset = () => {
    setPw("");
    setPw2("");
    setErr("");
  };

  const resetPWSubmit = async (_token?: string) => {
    const tokenValues = url.parse("?" + (token || _token), true).query;

    setLoading(true);
    setErr("");
    const client = await fetchClient();
    client
      .post(`auth/reset/data`, {
        email: tokenValues.u,
        password: pw,
        passwordCopy: pw2,
        token: token || _token,
      })
      .then((resp: any) => {
        if (resp.data.two_factor_auth === 1) {
          navTo2FA();
          return;
        }
        setLoading(false);
        setComplete(true);
        reset();
      })
      .catch(() => {
        setErr(text("common.error"));
        setLoading(false);
      });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (handleSubmitOverride) {
        handleSubmitOverride(resetPWSubmit, resetPWValidation);
      } else {
        resetPWValidation().then((result) => {
          if (result === true) {
            resetPWSubmit();
          }
        });
      }
    }
  };

  const headerMessage = (): string => {
    if (loading) {
      //todo: this looks poor for on a fast connection
      return text("changePassword.loading");
    }
    if (complete) {
      return text("changePassword.resetSuccess");
    }
    return text("changePassword.resetError");
  };

  return (
    <PageBody style={{ margin: "auto", backgroundColor: colors.secondary }}>
      <View style={[authStyles.authPage]} testID="auth-page">
        <PageHeader navigation={navigation} />
        <View style={[fStyles.form]}>
          <PageHeader
            style={[formStyles.form]}
            title={text("changePassword.title")}
            preamble={headerMessage()}
            navigation={navigation}
          />
          {!complete && (
            <>
              <View>
                <CustomTextInput
                  testID="pw"
                  style={[formStyles.input, { marginTop: 30 }]}
                  placeholder={
                    typeof text("changePassword.fields.password") === "string"
                      ? text("changePassword.fields.password")
                      : text("changePassword.fields.password")[0]
                  }
                  onChangeText={(e) => setPw(e)}
                  value={pw}
                  onKeyPress={handleKeyDown}
                  autoCompleteType={"off"}
                  secureTextEntry
                  icon={<CustomIcon iconName={"lock-outline"} />}
                />
              </View>
              <View>
                <CustomTextInput
                  testID="pw2"
                  style={[formStyles.input, { marginTop: 30 }]}
                  placeholder={
                    typeof text("changePassword.fields.password2") === "string"
                      ? text("changePassword.fields.password2")
                      : text("changePassword.fields.password2")[0]
                  }
                  onChangeText={(e) => setPw2(e)}
                  value={pw2}
                  onKeyPress={handleKeyDown}
                  autoCompleteType={"off"}
                  secureTextEntry
                  icon={<CustomIcon iconName={"lock-outline"} />}
                />
              </View>
            </>
          )}
          {!complete && !SubmitButtonOverride && (
            <View style={[fStyles.fieldContainer, aStyles.spacedButton]}>
              <CustomButton
                onPress={() => {
                  resetPWValidation().then((result) => {
                    if (result === true) {
                      resetPWSubmit();
                    }
                  });
                }}
                testID={"Reset"}
                title={text("changePassword.saveBtn")}
              />
            </View>
          )}
          {complete && !SubmitButtonOverride && (
            <AppOrWebLoginButtons navigation={navigation} />
          )}
          {!complete && SubmitButtonOverride && (
            <SubmitButtonOverride
              resetPWValidation={resetPWValidation}
              resetPWSubmit={resetPWSubmit}
            />
          )}
          <View>
            <Text>{err ? (err as string) : ""}</Text>
          </View>
        </View>
      </View>
    </PageBody>
  );
};

export default ResetPWScreen;

import React from "react";
import { Card } from "react-native-paper";
import Logo from "src/assets/images/logo";
import { useTheme } from "@react-navigation/native";

export const LogoCard = ({ isOnlyLogo = false }) => {
  const { colors } = useTheme();

  if (isOnlyLogo) {
    return <Logo iconColor={colors.primary} />;
  }
  return (
    <Card
      style={{
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 10,
      }}
    >
      <Logo iconColor={colors.primary} />
    </Card>
  );
};

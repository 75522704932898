export const vars = {
  appBackground: "#F6F8FC",
  primaryColor: "#5841D8",
  secondaryColor: "#FFFFFF",
  grey: "#A6B1C2",
  light: "#F7F8FB",
  black: "#0D1625",
  white: "#FFFFFF",
  textRed: "#FD5851",
  menuBackgound: "#F7F5FE",
  lightBlue: "#c5cae4",
  lightGrey: "#F2F4F9",
  progressBlue: "#3ECBFF",
  progressRed: "#FF716B",
  progressYellow: "#FFC44C",
};

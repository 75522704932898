/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { QuizCollectionState } from "./quiz-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Quiz } from "../../../../../../types/domain/flat-types";
const initialState: QuizCollectionState = {
  items: undefined,
  status: "idle",
};

export const getQuizs = createAsyncThunk(
  "get/quizs",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchQuizs = createAsyncThunk(
  "get/quizs",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`quiz-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);



  export const createQuiz = createAsyncThunk(
    "post/quiz",
    async (quiz: Partial<Quiz>) => {
      const client = await fetchClient();
      const { data } = await client.post(`quiz/`, { quiz });
      return data;
    }
  );


export const updateQuiz = createAsyncThunk(
  "put/quiz",
  async (quiz: Partial<Quiz>) => {
    const client = await fetchClient();
    const { data } = await client.put(`quiz/`, { quiz });
    return data;
  }
);

export const getQuiz = createAsyncThunk(
  "get/quiz",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz/${id}`);
    return data;
  }
);

export const deleteQuiz = createAsyncThunk(
  "delete/quiz",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`quiz/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const quizsSlice = createSlice({
  name: "quizs",
  initialState,
  reducers: {
    clearQuizItems(state: QuizCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizs.pending || searchQuizs.pending, (state: QuizCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getQuizs.fulfilled || searchQuizs.fulfilled, (state: QuizCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getQuizs.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getQuiz.pending, (state: QuizCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteQuiz.pending, (state: QuizCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateQuiz.pending, (state: QuizCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getQuiz.fulfilled, (state: QuizCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteQuiz.fulfilled, (state: QuizCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateQuiz.fulfilled, (state: QuizCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createQuiz.pending, (state: QuizCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createQuiz.fulfilled, (state: QuizCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getQuiz.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteQuiz.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateQuiz.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const quizSelector = (id: number) => (state: AppState) => {
  return state.quizs?.items?.find((o) => o.Id === id);
}; 

export const quizLoading = createSelector(
  (state: AppState) => state.quizs.status,
  status => status === 'pending'
);

export const quizErrorSelector = createSelector(
  (state: AppState) => state.quizs,
  status => status.error
);


export const quizsSelector = createSelector(
  (state: AppState) => state.quizs,
  state => state.items
);

export const quizsLoading = createSelector(
  (state: AppState) => state.quizs.status,
  status => status === 'pending'
);

export const quizsErrorSelector = createSelector(
  (state: AppState) => state.quizs,
  status => status.error
);

export const { clearQuizItems } = quizsSlice.actions;

export default quizsSlice.reducer;

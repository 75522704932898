import { useTheme } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect } from "react";
import { Image, View } from "react-native";
import { useDispatch } from "react-redux";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { PageBody } from "src/pages/layout/pageBody";
import { setLanguage } from "src/redux/features/misc/slice";
import { Language } from "src/translations/types";
import { useTranslation } from "src/translations/useTranslation";
import { CustomButton } from "src/uikit";
import logo from "../../../assets/vertical.svg";
import styles from "./home-styles";

export const HomeScreen = ({ navigation }: { navigation: any }) => {
  const { text } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const appName = text("appName");

  useEffect(() => {
    dispatch(setLanguage(Language.English)); //todo: add UI selection option
  }, [dispatch]);

  return (
    <LinearGradient
      colors={[colors.primaryBackground, colors.secondaryBackground]}
      start={{
        x: 0,
        y: 0,
      }}
      end={{
        x: 0,
        y: 1,
      }}
      style={{ height: "100%" }}
    >
      <WebFadeIn background={false}>
        <PageBody style={{ margin: "auto" }}>
          <View testID="logo-view" style={styles.logoContainer}>
            <Image
              source={logo}
              style={{
                height: 190,
                width: 128,
                borderRadius: 8,
              }}
            />
          </View>
          <View style={styles.home}>
            <View style={styles.button}>
              <CustomButton
                testID="app-login-button"
                onPress={() => navigation.navigate("Login")}
                title={text("auth.home.loginButton")}
                theme="primary"
              />
            </View>
            <View style={styles.button}>
              <CustomButton
                testID="app-signup-button"
                onPress={() => navigation.navigate("SignUp1")}
                title={text("auth.home.signUpButton")}
                theme="secondary"
              />
            </View>
          </View>
        </PageBody>
      </WebFadeIn>
    </LinearGradient>
  );
};

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { LessonPublishedByCategoryCollectionState } from "./lesson-published-by-category-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Lessonpublishedbycategory } from "../../../../../../types/domain/flat-types";
const initialState: LessonPublishedByCategoryCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessonPublishedByCategorys = createAsyncThunk(
  "get/lesson-published-by-categorys",
  async (options?: { categorypublishedfull?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-by-category?categorypublishedfull=${options?.categorypublishedfull}&language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchLessonPublishedByCategorys = createAsyncThunk(
  "get/lesson-published-by-categorys",
  async ({ categorypublishedfull, search, language, offset, limit, filter }: { categorypublishedfull?: number, search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`lesson-published-by-category-search?categorypublishedfull=${categorypublishedfull}&language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getLessonPublishedByCategory = createAsyncThunk(
  "get/lesson-published-by-category",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-by-category/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: LessonPublishedByCategoryCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].CategoryPublishedFull : undefined;

//   if(currentStateConstraint && actionArgs.categorypublishedfull && Number(actionArgs.categorypublishedfull) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].CategoryPublishedFull : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const lessonPublishedByCategorysSlice = createSlice({
  name: "lessonPublishedByCategorys",
  initialState,
  reducers: {
    clearLessonPublishedByCategoryItems(state: LessonPublishedByCategoryCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonPublishedByCategorys.pending || searchLessonPublishedByCategorys.pending, (state: LessonPublishedByCategoryCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessonPublishedByCategorys.fulfilled || searchLessonPublishedByCategorys.fulfilled, (state: LessonPublishedByCategoryCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessonPublishedByCategorys.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLessonPublishedByCategory.pending, (state: LessonPublishedByCategoryCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLessonPublishedByCategory.fulfilled, (state: LessonPublishedByCategoryCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getLessonPublishedByCategory.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonPublishedByCategorySelector = (id: number) => (state: AppState) => {
  return state.lessonPublishedByCategorys?.items?.find((o) => o.Id === id);
}; 

export const lessonPublishedByCategoryLoading = createSelector(
  (state: AppState) => state.lessonPublishedByCategorys.status,
  status => status === 'pending'
);

export const lessonPublishedByCategoryErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedByCategorys,
  status => status.error
);


export const lessonPublishedByCategorysSelector = (categoryPublishedFull?: number) => (state: AppState) => {
  if (!categoryPublishedFull) {
    return undefined;
  }
  return state.lessonPublishedByCategorys?.items?.filter((q) => q.CategoryPublishedFull === categoryPublishedFull);
}; 

export const lessonPublishedByCategorysLoading = createSelector(
  (state: AppState) => state.lessonPublishedByCategorys.status,
  status => status === 'pending'
);

export const lessonPublishedByCategorysErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedByCategorys,
  status => status.error
);

export const { clearLessonPublishedByCategoryItems } = lessonPublishedByCategorysSlice.actions;

export default lessonPublishedByCategorysSlice.reducer;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { CustomerEmailMediaKeyCollectionState } from "./customer-email-media-key-collection-state";

import { Customeremailmediakey } from "../../../../../../types/domain/flat-types";
const initialState: CustomerEmailMediaKeyCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCustomerEmailMediaKeys = createAsyncThunk(
  "get/customer-email-media-keys",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email-media-key?offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createCustomerEmailMediaKey = createAsyncThunk(
    "post/customer-email-media-key",
    async (customerEmailMediaKey: Partial<Customeremailmediakey>) => {
      const client = await fetchClient();
      const { data } = await client.post(`customer-email-media-key/`, { customerEmailMediaKey });
      return data;
    }
  );


export const updateCustomerEmailMediaKey = createAsyncThunk(
  "put/customer-email-media-key",
  async (customerEmailMediaKey: Partial<Customeremailmediakey>) => {
    const client = await fetchClient();
    const { data } = await client.put(`customer-email-media-key/`, { customerEmailMediaKey });
    return data;
  }
);

export const getCustomerEmailMediaKey = createAsyncThunk(
  "get/customer-email-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email-media-key/${id}`);
    return data;
  }
);

export const deleteCustomerEmailMediaKey = createAsyncThunk(
  "delete/customer-email-media-key",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`customer-email-media-key/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const customerEmailMediaKeysSlice = createSlice({
  name: "customerEmailMediaKeys",
  initialState,
  reducers: {
    clearCustomerEmailMediaKeyItems(state: CustomerEmailMediaKeyCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerEmailMediaKeys.pending, (state: CustomerEmailMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCustomerEmailMediaKeys.fulfilled, (state: CustomerEmailMediaKeyCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getCustomerEmailMediaKeys.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCustomerEmailMediaKey.pending, (state: CustomerEmailMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteCustomerEmailMediaKey.pending, (state: CustomerEmailMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateCustomerEmailMediaKey.pending, (state: CustomerEmailMediaKeyCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCustomerEmailMediaKey.fulfilled, (state: CustomerEmailMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteCustomerEmailMediaKey.fulfilled, (state: CustomerEmailMediaKeyCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateCustomerEmailMediaKey.fulfilled, (state: CustomerEmailMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createCustomerEmailMediaKey.pending, (state: CustomerEmailMediaKeyCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createCustomerEmailMediaKey.fulfilled, (state: CustomerEmailMediaKeyCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getCustomerEmailMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteCustomerEmailMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateCustomerEmailMediaKey.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const customerEmailMediaKeySelector = (id: number) => (state: AppState) => {
  return state.customerEmailMediaKeys?.items?.find((o) => o.Id === id);
}; 

export const customerEmailMediaKeyLoading = createSelector(
  (state: AppState) => state.customerEmailMediaKeys.status,
  status => status === 'pending'
);

export const customerEmailMediaKeyErrorSelector = createSelector(
  (state: AppState) => state.customerEmailMediaKeys,
  status => status.error
);


export const customerEmailMediaKeysSelector = createSelector(
  (state: AppState) => state.customerEmailMediaKeys,
  state => state.items
);

export const customerEmailMediaKeysLoading = createSelector(
  (state: AppState) => state.customerEmailMediaKeys.status,
  status => status === 'pending'
);

export const customerEmailMediaKeysErrorSelector = createSelector(
  (state: AppState) => state.customerEmailMediaKeys,
  status => status.error
);

export const { clearCustomerEmailMediaKeyItems } = customerEmailMediaKeysSlice.actions;

export default customerEmailMediaKeysSlice.reducer;

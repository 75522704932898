/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearCategoryPublishedItems, categoryPublishedsSelector, searchCategoryPublisheds, categoryPublishedsLoading } from "./collection-slice"
import { Search } from "../../../../../../types/search/search";
// import TagButtons from "../../../../containers/pages/layout/components/tagButton";
import _ from "lodash";
import CustomButton from "src/uikit/customButton";




export interface InitialFilter {
    fieldName: string;
    filter: number[];
    maxFilterCount: number; //todo: we don't need this here!
}

export interface BasicParams {
    offset?: number;
    limit: number;
    filter?: string;
}

export const useCategoryPublishedsSearchCollection = ( initialLoadSize?: number, language?: number, initialFilters?: InitialFilter[]) => {
    const dispatch = useDispatch();
    const [initialised, setInitialised] = useState(false);
    const [initialisedLanguage, setInitialisedLanguage] = useState(language);
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(false);
    const [textFilter, setTextFilter] = useState<string | undefined>();
    const categorypublisheds = useSelector(categoryPublishedsSelector); 
    const loading = useSelector(categoryPublishedsLoading);
    const [basicParams, setBasicParams] = useState<BasicParams>();
    
    
    
    
    const [search, setSearch] = useState<Search>({
      selects: [],
      filters: []
    });


    const toggleFilter = (fieldName: string, filterTypeId?: number) => {

        if(!filterTypeId) return;


        let filter = _.find(search.filters, { fieldName: fieldName })?.filter;

        if (filter && filter.indexOf(filterTypeId) !== -1) {
            _.remove(filter, val => val === filterTypeId);
        } else if (filter) {
            filter.push(filterTypeId);
        }

        setSearch(search);
        setInitialised(false);
    };

    const toggleBoolFilter = (fieldName: string) => {
        let filter = _.find(search.filters, { fieldName: fieldName })?.filter;

        if (filter && filter[0] === true) {
            _.remove(filter, val => val === true);
        } else if (filter) {
            filter.push(true);
        }

        setSearch(search);
        setInitialised(false);
    };

    const filterOn = (fieldName: string, filterTypeId?: number): boolean => {

        if(!filterTypeId) return false;

        let filter = _.find(search.filters, { fieldName: fieldName })?.filter;

        if (filter && filter.indexOf(filterTypeId) !== -1) {
            return true;
        }

        return false;
    };

    const filterOnBool = (fieldName: string): boolean => {
        let filter = _.find(search.filters, { fieldName: fieldName })?.filter;

        if (filter && filter[0] === true) {
            return true;
        }

        return false;
    };

    const setSelect = (selectName: string, value: any) => {
        let selectItem = _.find(search.selects, { fieldName: selectName });

        if (selectItem && value === -1) {
            selectItem.select = [];
        } else if (selectItem) {
            selectItem.select[0] = value;
        }

        setSearch(search);
        setInitialised(false);
    };

    const setMultipleSelect = (selectName: string, value: any) => {
        let selectItem = _.find(search.selects, { fieldName: selectName });

        if (value.indexOf(-1) !== -1) value.splice(value.indexOf(-1), 1);

        if (selectItem?.select) selectItem.select = value

        setSearch(search);
    };

    const loadCategoryPublisheds = useCallback(({ offset, limit, filter }: { offset?: number, limit: number, filter?: string }) => {
        setTextFilter(filter);
        setBasicParams({offset, limit, filter});
        dispatch(searchCategoryPublisheds({ search: search, language: language || 1,offset: offset || 0, limit: limit, filter: filter }));
    }, [dispatch, language]);

    useEffect(() => {
      if (initialised && initialisedLanguage !== language) {
          setInitialised(false);
          setInitialisedLanguage(language);
      }
    },[initialised, initialisedLanguage, language]);

    useEffect(() => {
        if (!loading && !categorypublisheds && !initialised) {
            setInitialised(true);
            dispatch(clearCategoryPublishedItems());
            loadCategoryPublisheds({ offset: 0, limit: initialLoadSize || 3, filter: textFilter });
        }
    }, [loading, categorypublisheds, loadCategoryPublisheds, initialised]);

    useEffect(() => {
        if(!searchParamsLoaded){
          setSearchParamsLoaded(true);
        
        
        }
    }, [dispatch, searchParamsLoaded]);

    const Filters = ({ disabled }: { disabled?: boolean }) => {
        const [filtered, setFiltered] = useState(1);

        return (
            <>
                
            </>
        );
    };

    const Selects = ({ disabled }: { disabled?: boolean }) => {
        const [renderTarget, setRenderTarget] = React.useState<any[-1]>();
        return (
            <>
            
            </>
        );
    };

    const doSearch = () => {
        setInitialised(false);
    };

    return {
        LoadMoreCategoryPublishedsSearchButton: () => (
          <CustomButton
            title="Load more"
            style={{ maxWidth: 400  }}
            theme={"secondary"}
            onPress={() =>
              loadCategoryPublisheds({
                offset: categorypublisheds?.length,
                limit: initialLoadSize || 3,
                filter: textFilter,
              })
            }
          />
        ),
        loadMore: () => {
            loadCategoryPublisheds({
                offset: categorypublisheds?.length,
                limit: initialLoadSize || 3,
                filter: textFilter,
            });
        },
        Filters,
        Selects,
        loadCategoryPublisheds,
        searchDataLoaded: true,
        searchResult: categorypublisheds,
        search,
        setSearch,
        doSearch,
        basicParams,
    };
};


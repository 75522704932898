import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { invalidateAuth } from "src/redux/features/auth/slice";

let _store: any;

export const setAxiosModuleStore = (store: any) => {
  // pending curcular ref fix

  _store = store;
};

export const fetchClient = async (baseUrl?: string) => {
  const token =
    (await AsyncStorage.getItem("token")) ||
    (await AsyncStorage.getItem("signup")) ||
    "";
  // console.log("baseurl", baseUrl || process.env.REACT_APP_API_BASE_URL);
  const client = axios.create({
    baseURL: baseUrl || process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      authorization: token,
      credentials: "include",
    },
  });

  client.interceptors.request.use(
    function (config) {
      //request interceptions here

      //if we've cleared our token then don't even try to hit the API
      // @TODO refactor this condition
      if (!config.url?.includes("auth") && !token) {
        throw new Error("no token");
      }

      if (
        process.env.ENVIRONMENT !== "Prod" &&
        process.env.ENVIRONMENT !== "CI" &&
        process.env.ENVIRONMENT !== "local"
      ) {
        (config as any).headers["app-dev-api-key"] = process.env.APP_AUTH_TOKEN;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error?.response && error?.response?.status === 401) {
        _store.dispatch(invalidateAuth()); //
      }

      return Promise.reject(
        error?.response?.data?.knownError ||
          error?.response?.data?.message ||
          error?.message,
      );
    },
  );

  return client;
};

import { Platform, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  addNew: {
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {},
  disabled: {
    opacity: 0.2,
  },
  disabledPressable: {},
  icon: {
    display: "flex",
    fontSize: 30,
    lineHeight: Platform.OS === "web" ? 20 : Platform.OS === "ios" ? 31 : 33, //urgghhh ...todo: use icon!
    letterSpacing: -1,
    borderWidth: 1,
    borderColor: "#6B7079",
    height: 30,
    width: 30,
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  pressable: {
    height: 30,
    width: 30,
    borderRadius: 25,
    marginLeft: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default styles;

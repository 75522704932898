//ImageKit supported MIME types

export const supportedVideo = [
  "video/mp4",
  "video/webm",
  "video/mov",
  "video/ts",
];

export const supportedImage = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif",
  "image/svg+xml",
];

/* eslint-disable react-native/no-inline-styles */
import React, { useCallback, useEffect, useState } from "react";
import {
  BackHandler,
  Platform,
  ScrollView,
  useWindowDimensions,
  View,
} from "react-native";
import { CustomButton, CustomCheckbox } from "src/uikit";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import usePageStyles from "src/pages/layout/page.styles";
import { PageHeader } from "src/pages/layout/pageheader";
import useStyles from "./quiz.styles";
import { useFocusEffect } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import {
  quizPublishedSelector,
  getQuizPublished,
} from "src/redux/domain/features/quizPublished/collection-slice";
import { Quizpublishedfull } from "./types";
import {
  Quizanswerpublic,
  Quizattempt,
} from "../../../../../types/domain/flat-types";
import { CheckBoxItem } from "src/uikit/customCheckbox/customCheckbox";
import { createQuizAttemptAnswer } from "src/redux/domain/features/quizAttemptAnswer/collection-slice";
import {
  quizAttemptsSelector,
  updateQuizAttempt,
} from "src/redux/domain/features/quizAttempt/collection-slice";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import _ from "lodash";
import q from "q";
import { useSnackBar } from "src/components/snackBar/snackBar";
import { setProgress } from "src/redux/features/misc/slice";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { useTranslation } from "src/translations/useTranslation";

const QuizQuestionUserScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) => {
  const pageStyles = usePageStyles();
  const styles = useStyles();
  const dispatch = useDispatch();
  const { showSnackError, Snack } = useSnackBar();
  const [values, setValues] = useState<CheckBoxItem[]>([]);
  const { userId } = useUserInfo();
  const { text, t } = useTranslation();
  const { quizPublishedId, questionId, lessonPublishedFullId } = route.params;
  const [lastQuestion, setLastQuestion] = useState(null);
  const { width } = useWindowDimensions();

  const quizPublishedFull: Quizpublishedfull = useSelector(
    quizPublishedSelector(quizPublishedId),
  );
  const quizAttempts = useSelector(quizAttemptsSelector(userId));

  //we pre load the quiz on the lesson page
  useFocusEffect(
    React.useCallback(() => {
      const getQuizResources = async () => {
        if (!quizPublishedFull) {
          await dispatch(getQuizPublished(quizPublishedId));
        }
      };
      getQuizResources();
    }, [dispatch, quizPublishedFull, quizPublishedId]),
  );

  useFocusEffect(() => {
    if (!questionId && quizPublishedFull) {
      navigation.setParams({
        questionId: quizPublishedFull.questions[0].Id,
      });
    }
  });

  const handleError = (response: any) => {
    if (response?.meta?.requestStatus !== "fulfilled") {
      showSnackError({ message: "Error" });
      return true;
    }
    return false;
  };

  //todo: although this works it feels like it could be tighter
  const getLatestAttemptAtThisQuiz = useCallback(() => {
    const attempts = _.orderBy(
      quizAttempts?.filter((qa) => qa.Quiz === quizPublishedId),
      "StartDate",
      "desc",
    ) as Quizattempt[];
    return attempts[0];
  }, [quizAttempts, quizPublishedId]);

  const nextQuestionId = () => {
    let nextIndex;
    const currentIndex = quizPublishedFull?.questions.findIndex(
      (qu) => qu.Id === questionId,
    );

    if (currentIndex + 1 < quizPublishedFull?.questions.length) {
      nextIndex = currentIndex + 1;
    } else {
      return undefined;
    }

    const question = quizPublishedFull?.questions[nextIndex];
    return question.Id;
  };

  const currentQuestion = () => {
    const question = quizPublishedFull?.questions.find(
      (qu) => qu.Id === questionId,
    );
    return question?.Question;
  };

  const answers = () => {
    const question = quizPublishedFull?.questions.find(
      (qu) => qu.Id === questionId,
    );
    return question?.answers;
  };

  const pressAction = async () => {
    // await submit answers
    if (values.length === 0) {
      showSnackError({ message: t("quizQuestion.pleaseSelect") });
      return;
    }

    const attempt = getLatestAttemptAtThisQuiz();

    const promises: Promise<any>[] = [];
    values.forEach((v) => {
      promises.push(
        dispatch(
          createQuizAttemptAnswer({
            QuizAttempt: attempt.Id,
            QuizQuestion: questionId,
            QuizAnswer: v.Id,
          }),
        ) as unknown as Promise<any>,
      );
    });

    await q.all(promises).then(async (results: any[]) => {
      let error = false;

      results.forEach((r) => {
        if (r?.meta?.requestStatus !== "fulfilled") {
          error = true;
        }
      });

      if (error) {
        console.log(error);
        showSnackError({ message: "Error" });
        return;
      }

      if (nextQuestionId()) {
        const _nextQuestionId = nextQuestionId();
        setLastQuestion(questionId);
        navigation.replace(
          "QuizUserScreen",
          _.merge(route.params, { questionId: _nextQuestionId }),
        );
      } else {
        const finalizedQuizAttempt = await dispatch(
          updateQuizAttempt({ Id: attempt.Id, Complete: true }),
        );

        if (!handleError(finalizedQuizAttempt)) {
          navigation.replace("QuizResultScreen", {
            quizPublishedId,
            lessonPublishedFullId,
          });
        }
      }
    });
  };

  const onSelectCheckbox = (item: CheckBoxItem) => {
    setValues(
      values.find((i) => i.Id === item.Id)
        ? values.filter((v) => v.Id !== item.Id)
        : [...values, item],
    );
  };

  useEffect(() => {
    const handleBack = () => {
      navigation.replace("LessonPublishedView", { lessonPublishedFullId });
      return true;
    };

    //android only
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBack,
    );

    if (Platform.OS === "web") {
      window.addEventListener("popstate", handleBack, false);
    }

    return () => {
      if (Platform.OS === "web") {
        window.removeEventListener("popstate", handleBack, false);
      }
      backHandler.remove();
    };
  }, [lessonPublishedFullId, navigation, lastQuestion]);

  //todo: move to app bar header and control via redux to prevent re-render animation...

  const totalQuestions = quizPublishedFull?.questions.length;
  const currentQuestionIndex = quizPublishedFull?.questions.findIndex(
    (qu) => qu.Id === questionId,
  );

  useEffect(() => {
    if (totalQuestions) {
      dispatch(
        setProgress({
          show: true,
          total: totalQuestions,
          progress: currentQuestionIndex + 1,
        }),
      );
    }
  }, [currentQuestionIndex, dispatch, totalQuestions]);

  return (
    <WebFadeIn>
      <View
        style={[
          pageStyles.page,
          styles.quizPage,
          width > mobileBreak ? { paddingTop: 0 } : {},
        ]}
      >
        <PageHeader
          title={quizPublishedFull?.Name}
          preamble={currentQuestion()}
          style={styles.quizPageHeader}
          titleStyle={styles.title}
          navigation={navigation}
          hideGoBack={true}
        />

        <ScrollView
          style={[styles.quizPageBody]}
          contentContainerStyle={styles.quizPageBodyContentContainer}
        >
          <View style={styles.questionsContainer}>
            {answers() && (
              <CustomCheckbox
                selectedItems={values}
                items={
                  answers()?.map((a: Quizanswerpublic) => {
                    return {
                      Id: a.Id,
                      Value: a.Text,
                    };
                  }) as CheckBoxItem[]
                }
                onSelect={onSelectCheckbox}
                theme="secondary"
              />
            )}
          </View>
          <View style={[styles.confirmButtonContainer]}>
            <CustomButton
              title={text("lessonViewList.confirmBtn")}
              onPress={() => pressAction()}
              // style={styles.confirmButton}
            />
          </View>
        </ScrollView>
      </View>
      <Snack />
    </WebFadeIn>
  );
};

export default QuizQuestionUserScreen;

import React from "react";
import { View, Text } from "react-native";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import authStyles from "../layout/auth.styles";
import { AuthHeader } from "../layout/auth-header";
import formStyles from "src/components/forms/authForm.styles";
import {
  authReset,
  AuthResetCredentials,
  error,
  isFulfilled,
  isLoading,
} from "src/redux/features/authReset/slice";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { CustomButton, CustomTextInput } from "src/uikit";
import { PageBody } from "src/pages/layout/pageBody";
import { useTheme } from "@react-navigation/native";
import CustomIcon from "src/uikit/CustomIcon/CustomIcon";

const ForgottenPasswordScreen = () => {
  const dispatch = useDispatch();
  const { colors } = useTheme();

  const loading = useSelector(isLoading);
  const err = useSelector(error);
  const fulfilled = useSelector(isFulfilled);

  const initialValues: AuthResetCredentials = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (credential) => {
      dispatch(authReset(credential));
    },
  });

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  const headerMessage = (): string => {
    if (loading) {
      return "Processing...";
    }
    if (fulfilled) {
      return "If registered an email will be sent.";
    }
    return "Please enter your email address.";
  };

  return (
    <WebFadeIn>
      <PageBody style={{ margin: "auto", backgroundColor: colors.secondary }}>
        <View style={[authStyles.authPage]} testID="auth-page">
          <View style={[formStyles.form]}>
            <AuthHeader
              style={authStyles.fixedForDynamicPreambleMessaging}
              title={"Password reset"}
              preamble={headerMessage()}
            />
            <View style={formStyles.fieldContainer}>
              <CustomTextInput
                testID="emailf"
                placeholder="Email"
                onChangeText={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
                onKeyPress={handleKeyDown}
                icon={<CustomIcon iconName={"email-outline"} />}
              />
            </View>
            <View style={[formStyles.fieldContainer, authStyles.spacedButton]}>
              <CustomButton
                onPress={() => formik.handleSubmit()}
                testID={"submit"}
                title={"Submit"}
              />
              <View>
                <Text>{err ? (err as string) : ""}</Text>
              </View>
            </View>
          </View>
        </View>
      </PageBody>
    </WebFadeIn>
  );
};

export default ForgottenPasswordScreen;

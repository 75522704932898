import { Asset } from "expo-asset";
import React from "react";
import { Image, LogBox, Platform } from "react-native";
import "react-native-url-polyfill/auto";
import { Provider } from "react-redux";
import { setAxiosModuleStore } from "./src/api/axios/axiosApi";
import { Navigation } from "./src/navigation/navigation";
import store from "./src/redux/stores/store";

LogBox.ignoreLogs(["Require cycle:"]);

//fix broken error overlay bug for web dev
if (process.env.NODE_ENV === "development" && Platform.OS === "web") {
  require("./src/styles/webStyle");
}

import {
  Poppins_100Thin,
  Poppins_100Thin_Italic,
  Poppins_200ExtraLight,
  Poppins_200ExtraLight_Italic,
  Poppins_300Light,
  Poppins_300Light_Italic,
  Poppins_400Regular,
  Poppins_400Regular_Italic,
  Poppins_500Medium,
  Poppins_500Medium_Italic,
  Poppins_600SemiBold,
  Poppins_600SemiBold_Italic,
  Poppins_700Bold,
  Poppins_700Bold_Italic,
  Poppins_800ExtraBold,
  Poppins_800ExtraBold_Italic,
  Poppins_900Black,
  Poppins_900Black_Italic,
  useFonts,
} from "@expo-google-fonts/poppins";

import { SafeAreaProvider } from "react-native-safe-area-context";

setAxiosModuleStore(store);

function cacheImages(images) {
  return images.map((image) => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(image).downloadAsync();
    }
  });
}

const loadImagesAsync = async () => {
  const imageAssets = cacheImages([
    require("./assets/backArrow.png"),
    require("./assets/headerLogo.png"),
    require("./assets/nav.png"),
    require("./assets/logo.png"),
    require("./assets/rightChevron.png"),
    require("./assets/editing.png"),
    require("./assets/folder.png"),
    require("./assets/resourceImagePlaceholder.png"),
    require("./assets/play.png"),
    require("./assets/pause.png"),
    require("./assets/profile.png"),
    require("./assets/savings.png"),
    require("./assets/be-images/Graphics.png"),
    require("./assets/be-images/Login.png"),
    require("./assets/be-images/SignUp.png"),
    require("./assets/be-images/Background.png"),
    require("./assets/be-images/user.png"),
    require("./assets/be-images/glossary.png"),
    require("./assets/be-images/categories.png"),
    require("./assets/be-images/lessons.png"),
    require("./assets/be-images/quizzes.png"),
    require("./assets/be-images/QuizLost.png"),
    require("./assets/be-images/QuizWon.png"),
    require("./assets/be-images/check.png"),
    require("./assets/be-images/chat.png"),
    require("./assets/splash2.png"),
  ]);
  await Promise.all([...imageAssets]);
};

loadImagesAsync();

export default () => {
  useFonts({
    Poppins_100Thin,
    Poppins_100Thin_Italic,
    Poppins_200ExtraLight,
    Poppins_200ExtraLight_Italic,
    Poppins_300Light,
    Poppins_300Light_Italic,
    Poppins_400Regular,
    Poppins_400Regular_Italic,
    Poppins_500Medium,
    Poppins_500Medium_Italic,
    Poppins_600SemiBold,
    Poppins_600SemiBold_Italic,
    Poppins_700Bold,
    Poppins_700Bold_Italic,
    Poppins_800ExtraBold,
    Poppins_800ExtraBold_Italic,
    Poppins_900Black,
    Poppins_900Black_Italic,
  });

  return (
    <Provider store={store}>
      <SafeAreaProvider>
        <Navigation />
      </SafeAreaProvider>
    </Provider>
  );
};

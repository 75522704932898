import React from "react";
import { Path } from "react-native-svg";

const HomeOutline: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M11.424 2.04558L11.4234 2.04599L2.43832 8.52775C2.43824 8.52781 2.43815 8.52787 2.43807 8.52793C1.77315 9.003 1.3473 9.80823 1.3473 10.6615V22.1059C1.3473 22.6805 1.8168 23.15 2.39139 23.15H9.1418C9.71639 23.15 10.1859 22.6805 10.1859 22.1059V17.859C10.1859 16.8689 10.9681 16.0867 11.9582 16.0867C12.9483 16.0867 13.7305 16.8689 13.7305 17.859V22.1059C13.7305 22.6805 14.2 23.15 14.7746 23.15H21.6144C22.1873 23.15 22.6505 22.6851 22.7025 22.1655L22.7032 22.1581V22.1506V10.6615C22.7032 9.80829 22.2774 9.00311 21.6126 8.52803C21.6124 8.52794 21.6123 8.52785 21.6122 8.52775L12.6271 2.04599L12.6271 2.04599L12.6265 2.04558C12.2615 1.78481 11.789 1.78481 11.424 2.04558ZM20.615 10.6615V21.1065H15.8187V17.859C15.8187 15.7644 14.0975 14.0432 12.0029 14.0432C9.90835 14.0432 8.18712 15.7644 8.18712 17.859V21.0618H3.39078V10.6168C3.39078 10.4502 3.49403 10.2684 3.64258 10.1565L12.0026 4.14086L20.3621 10.2004C20.3624 10.2006 20.3627 10.2008 20.363 10.201C20.5117 10.313 20.615 10.4948 20.615 10.6615Z"
        fill={props?.iconColor}
        stroke={props?.iconColor}
        strokeWidth="0.3"
      />
    </>
  );
};

export default HomeOutline;

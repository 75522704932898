import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export interface CoinListItem {
  id: string;
  symbol: string;
  name: string;
  image: {
    small: string;
  };
  market_data: {
    current_price: {
      usd: number;
    };
  };
}

export const useCoinGecko = () => {
  const [coinList, setCoinList] = useState<CoinListItem[]>([]);

  const fetchCoins = useCallback(async () => {
    const { data } = await axios.get("https://api.coingecko.com/api/v3/coins?");
    setCoinList(data);
  }, []);

  useEffect(() => {
    fetchCoins();
  }, [fetchCoins]);

  return {
    coinList,
  };
};

import { useTheme } from "@react-navigation/native";
import React from "react";
import { View, useWindowDimensions } from "react-native";
import useCommonStyles from "src/pages/auth/common.styles";
import { CustomButton } from "src/uikit";
import { NavProps } from "../types";
import { mobileBreak } from "./drawerNavOptions";

export const HeaderLeft = (props: NavProps) => {
  const commonStyles = useCommonStyles();
  const { width } = useWindowDimensions();
  const { colors } = useTheme();

  if (width > mobileBreak) {
    return (
      <View style={commonStyles.webHeader}>
        <CustomButton
          icon="chevron-left"
          theme="secondary"
          iconColor={colors.text}
          title={<span style={{ color: colors.text }}>Back</span>}
          onPress={() => {
            if (props.navigation.canGoBack()) {
              props.navigation.goBack();
            } else {
              props.navigation.navigate("Home");
            }
          }}
          style={[commonStyles.backButton]}
        />
      </View>
    );
  }

  return (
    <CustomButton
      icon="chevron-left"
      theme="secondary"
      iconColor={colors.text}
      onPress={() => {
        props.navigation.goBack();
      }}
      style={[commonStyles.backButton]}
    />
  );
};

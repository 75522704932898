import React from "react";
import { Path } from "react-native-svg";

const UserEdit: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M12 12.75C8.83 12.75 6.25 10.17 6.25 7C6.25 3.83 8.83 1.25 12 1.25C15.17 1.25 17.75 3.83 17.75 7C17.75 10.17 15.17 12.75 12 12.75ZM12 2.75C9.66 2.75 7.75 4.66 7.75 7C7.75 9.34 9.66 11.25 12 11.25C14.34 11.25 16.25 9.34 16.25 7C16.25 4.66 14.34 2.75 12 2.75Z"
        fill={props?.iconColor}
      />
      <Path
        d="M15.8201 22.7494C15.4401 22.7494 15.0801 22.6094 14.8201 22.3494C14.5101 22.0394 14.3701 21.5894 14.4401 21.1194L14.6301 19.7694C14.6801 19.4194 14.8901 19.0094 15.1401 18.7494L18.68 15.2094C20.1 13.7894 21.3501 14.5994 21.9601 15.2094C22.4801 15.7294 22.7501 16.2894 22.7501 16.8494C22.7501 17.4194 22.4901 17.9494 21.9601 18.4794L18.42 22.0194C18.17 22.2694 17.7501 22.4794 17.4001 22.5294L16.05 22.7194C15.97 22.7394 15.9001 22.7494 15.8201 22.7494ZM20.31 15.9194C20.13 15.9194 19.97 16.0394 19.74 16.2694L16.2001 19.8094C16.1701 19.8394 16.12 19.9394 16.12 19.9794L15.9401 21.2294L17.1901 21.0494C17.2301 21.0394 17.33 20.9894 17.36 20.9594L20.9001 17.4194C21.0601 17.2594 21.2501 17.0294 21.2501 16.8494C21.2501 16.6994 21.1301 16.4894 20.9001 16.2694C20.6601 16.0294 20.48 15.9194 20.31 15.9194Z"
        fill={props?.iconColor}
      />
      <Path
        d="M20.9201 19.2194C20.8501 19.2194 20.7801 19.2094 20.7201 19.1894C19.4001 18.8194 18.3501 17.7694 17.9801 16.4494C17.8701 16.0494 18.1001 15.6394 18.5001 15.5294C18.9001 15.4194 19.3101 15.6494 19.4201 16.0494C19.6501 16.8694 20.3001 17.5194 21.1201 17.7494C21.5201 17.8594 21.7501 18.2794 21.6401 18.6694C21.5501 18.9994 21.2501 19.2194 20.9201 19.2194Z"
        fill={props?.iconColor}
      />
      <Path
        d="M3.41016 22.75C3.00016 22.75 2.66016 22.41 2.66016 22C2.66016 17.73 6.85018 14.25 12.0002 14.25C13.0902 14.25 14.1702 14.41 15.1802 14.71C15.5802 14.83 15.8002 15.25 15.6802 15.64C15.5602 16.04 15.1402 16.26 14.7502 16.14C13.8702 15.88 12.9502 15.74 12.0002 15.74C7.68018 15.74 4.16016 18.54 4.16016 21.99C4.16016 22.41 3.82016 22.75 3.41016 22.75Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default UserEdit;

import { Dimensions, StyleSheet } from "react-native";

export const mobileBreak = 768;
const windowWidth = Dimensions.get("window").width;

const styles = StyleSheet.create({
  header: {
    backgroundColor: windowWidth > mobileBreak ? "#ffffff" : undefined,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 20,
    paddingTop: 20,
    flex: 1,
  },
  logo: {
    height: 39,
    width: 32,
    marginLeft: 30,
  },
  menuButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderWidth: 0,
    shadowColor: "rgba(13, 12, 45, 0.08)",
  },
  menuButtonContainer: {
    width: 50,
  },
  backButton: {
    borderWidth: 0,
  },
});

export default styles;

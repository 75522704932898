import { StyleSheet } from "react-native";

//https://transform.tools/css-to-js

const styles = StyleSheet.create({
  authPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    padding: 20,
  },
  form: {
    width: "100%",
    maxWidth: 400, //form width
    // height: '100%',
  },
  fieldContainer: {
    marginVertical: 10,
  },
  label: {
    maxWidth: 400,
    width: "100%",
  },
  input: {
    // width: "100%",
    // paddingTop: 12,
    // paddingBottom: 12,
    // paddingLeft: 20,
    // paddingRight: 20,
    // marginTop: 12,
    // borderStyle: "solid",
    // borderWidth: 2,
    // borderColor: "#6B7079",
    // borderRadius: 0,
    // backgroundColor: "rgba(0, 0, 0, 0)",
    // maxWidth: 400,
    // minHeight: 60,
  },
  disabled: {
    opacity: 0.3,
  },
  fieldErrorMessage: {
    // width: "100%",
    // marginBottom: 6,
    // justifyContent: "flex-end", //not working android
    // textAlign: "right", //android ok
    // display: "flex",
    // color: "#6B7079",
    // top: 32,
    // right: 12,
    // zIndex: -1,
  },
  underFieldMessage: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  error: {},
});

export default styles;

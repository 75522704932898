import React from "react";
import { LazyCategoryEditScreen } from "src/components/domain/pages/category/category.editLazy";
import { LazyCategoryEditListScreen } from "src/components/domain/pages/category/category.editListLazy";
import { LazyLessonEditScreen } from "src/components/domain/pages/lesson/lesson.editLazy";
import { LazyLessonEditListScreen } from "src/components/domain/pages/lesson/lesson.editListLazy";
import { LazyQuizEditScreen } from "src/components/domain/pages/quiz/quiz.editLazy";
import { LazyQuizEditListScreen } from "src/components/domain/pages/quiz/quiz.editListLazy";
import { LazyQuizQuestionEditScreen } from "src/components/domain/pages/quizQuestion/quizQuestion.editLazy";
import { LazyQuizAnswerEditScreen } from "src/components/domain/pages/quizAnswer/quizAnswer.editLazy";
import { LazyTopicEditScreen } from "src/components/domain/pages/topic/topic.editLazy";
import { LazyTopicEditListScreen } from "src/components/domain/pages/topic/topic.editListLazy";
import { AuthScreens } from "../auth/authScreens";
import { LazyAdminDashboardScreen } from "../lazy/lazyScreens/admin/dashboard";
import { LazyPersonalDetails, LazySettings } from "../lazy/loader";
import { hiddenOptions } from "../utils/hiddenMenuItem";
import { LazyUsersScreen } from "src/pages/admin/users/users.lazy";
import { LazyUserViewScreen } from "src/pages/admin/users/userView.lazy";
import { LazyCustomerEmailEditListScreen } from "src/components/domain/pages/customerEmail/customerEmail.editListLazy";
import { LazyCustomerEmailEditScreen } from "src/components/domain/pages/customerEmail/customerEmail.editLazy";

export const AdminScreens = ({
  Drawer,
  RootStack,
}: {
  Drawer: any;
  RootStack: any;
}) => {
  return (
    <React.Fragment>
      <Drawer.Screen name="Dashboard" component={LazyAdminDashboardScreen} />
      <Drawer.Screen name="Settings" component={LazySettings} />

      <Drawer.Screen name="Users" component={LazyUsersScreen} />
      <RootStack.Screen
        name="UserView"
        component={LazyUserViewScreen}
        options={hiddenOptions}
      />

      <Drawer.Screen name="Lessons" component={LazyLessonEditListScreen} />
      <RootStack.Screen
        name="LessonEdit"
        component={LazyLessonEditScreen}
        options={hiddenOptions}
      />
      <RootStack.Screen
        name="LessonEditTranslation"
        component={LazyLessonEditScreen}
        options={hiddenOptions}
      />

      <Drawer.Screen name="Quizzes" component={LazyQuizEditListScreen} />
      <RootStack.Screen
        name="QuizEdit"
        component={LazyQuizEditScreen}
        options={hiddenOptions}
      />

      <RootStack.Screen
        name="QuizQuestionEdit"
        component={LazyQuizQuestionEditScreen}
        options={hiddenOptions}
      />

      <RootStack.Screen
        name="QuizAnswerEdit"
        component={LazyQuizAnswerEditScreen}
        options={hiddenOptions}
      />

      <Drawer.Screen name="Categories" component={LazyCategoryEditListScreen} />
      <RootStack.Screen
        name="CategoryEdit"
        component={LazyCategoryEditScreen}
        options={hiddenOptions}
      />

      <Drawer.Screen name="Glossary" component={LazyTopicEditListScreen} />
      <RootStack.Screen
        name="TopicEdit"
        component={LazyTopicEditScreen}
        options={hiddenOptions}
      />

      <Drawer.Screen name="Email" component={LazyCustomerEmailEditListScreen} />
      <RootStack.Screen
        name="CustomerEmailEdit"
        component={LazyCustomerEmailEditScreen}
        options={hiddenOptions}
      />

      <RootStack.Screen
        name="PersonalDetails"
        component={LazyPersonalDetails}
        options={hiddenOptions}
      />

      {AuthScreens({ Navigator: RootStack })}
    </React.Fragment>
  );
};

import { useTheme } from "@react-navigation/native";
import { Dimensions, StyleSheet } from "react-native";
import { vars } from "src/styles";
import { themeDefault } from "src/theme";
import { avgDeviceSize } from "./header/drawerNavOptions";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  const { colors } = useTheme();
  return StyleSheet.create({
    authedView: {
      flex: 1,
      overflow: "hidden",
    },
    logout: {
      fontSize: 16,
      marginBottom: 20,
    },
    logoutLabel: {
      color: vars.black,
    },
    draw: {
      backgroundColor: vars.menuBackgound,
    },
    drawContainer: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
    background: {
      backgroundColor: "#ffffff",
    },
    menuItem: {
      fontSize: 16,
      backgroundColor: "#fff",
      borderRadius: 8,
    },
    menuItemLabel: {
      color: vars.black,
    },
    menuItemSelected: {
      backgroundColor: colors.primary,
    },
    menuItemLabelSelected: {
      color: "#fff",
    },
    webNavList: {},
    webNavItem: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: windowWidth > avgDeviceSize ? "space-between" : "center",
      marginHorizontal: 0,
      height: 30,
    },
    webNavItemIcon: {
      paddingRight: 6,
    },
    webNavItemLabel: {},
    webNavItemLabelSelected: {
      color: themeDefault.colors.primary,
    },
    menuButton: {
      borderWidth: 0,
      borderTopLeftRadius: 14,
      borderBottomLeftRadius: 14,
      borderTopRightRadius: 14,
      borderBottomRightRadius: 14,
      shadowColor: "#52006A",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 8,
      width: 50,
      // height: 50,
      // minHeight: 44,
      marginRight: 12,
      marginTop: 10,
    },
    logoutButton: {
      marginRight: windowWidth > avgDeviceSize ? 20 : 0,
    },
  });
};

export default styles;

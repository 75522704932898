/* eslint-disable react-native/no-inline-styles */
import { useTheme } from "@react-navigation/native";
import React from "react";
import { Text, useWindowDimensions, View } from "react-native";
import * as RNProgress from "react-native-progress";
import { useSelector } from "react-redux";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { progress } from "src/redux/features/misc/slice";
import { vars } from "src/styles";
import { StyledText } from "src/styles/text";
import styles from "./progress.styles";

export const Progress = React.memo(
  // eslint-disable-next-line no-shadow
  ({ total, progress }: { total: number; progress: number }) => {
    const { width } = useWindowDimensions();
    const { colors } = useTheme();

    return (
      <View
        key={"quiz-progress"}
        style={styles.progress}
        testID={"quiz-progress"}
      >
        <View
          style={[
            styles.progressContainer,
            width > mobileBreak ? { bottom: -70 } : {},
          ]}
        >
          <StyledText variant="buttonText">
            {progress}/{total}
          </StyledText>

          <RNProgress.Bar
            progress={progress / total}
            style={styles.progressBar}
            color={colors.primary}
            borderWidth={0}
            unfilledColor="rgba(88, 65, 216, 0.38)"
          />
        </View>
      </View>
    );
  },
);

export const AnyProgress = () => {
  const _progress = useSelector(progress);

  // if (!_progress?.show) {
  //   return null;
  // }

  return (
    <>
      {/* android render hack */}
      <Text> </Text>
      {_progress?.show && (
        <>
          {/* { console.log(123) } */}
          <Progress progress={_progress?.progress} total={_progress?.total} />
        </>
      )}
    </>
  );
};

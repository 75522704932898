import React from "react";
import { ProgressChart } from "react-native-chart-kit";
import { AbstractChartConfig } from "react-native-chart-kit/dist/AbstractChart";

export const Doughnut = ({ data }: { data: number[] }) => {
  const chartConfig: AbstractChartConfig = {
    backgroundGradientFrom: "#FFFFFF",
    backgroundGradientFromOpacity: 1,
    backgroundGradientTo: "#FFFFFF",
    backgroundGradientToOpacity: 1,
    color: (opacity = 1) => `rgb(91, 209, 215, ${opacity})`,
    strokeWidth: 3, // optional, default 3
    barPercentage: 0.5,
    barRadius: 2,
    useShadowColorFromDataset: false, // optional
  };

  return (
    <ProgressChart
      data={data}
      width={79}
      height={79}
      strokeWidth={16}
      radius={32}
      chartConfig={chartConfig}
      hideLegend={true}
    />
  );
};

import React, { useEffect, useState, useRef } from "react";
import { Text, View, ViewStyle, StyleProp, Platform } from "react-native";
import DropDownPicker, { ValueType } from "react-native-dropdown-picker";
import CustomIcon, { IconTypes } from "../customSvgIcon/customSvgIcon";
import { vars } from "src/styles";
import useStyles from "./customSelect.styles";
import { textStyles } from "src/styles/text";

export type CustomValueType = ValueType;

interface Props {
  icon?: IconTypes;
  title?: string;
  theme?: "primary" | "secondary" | "red";
  value: CustomValueType;
  items: any[];
  setValue: (value: CustomValueType) => void;
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;
  testID?: string;
  style?: StyleProp<ViewStyle>;
  dropdownStyle?: StyleProp<ViewStyle>;
  onOpen?: () => void;
  onClose?: () => void;
  iconColor?: string;
  iconSize?: number;
}

const CustomSelect: React.FunctionComponent<Props> = ({
  title,
  value,
  items,
  style,
  dropdownStyle,
  testID,
  setValue,
  disabled,
  placeholder,
  loading = false,
  onOpen,
  onClose,
  iconColor,
  iconSize,
}) => {
  const styles = useStyles();
  const dropdownRef: any = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [zIndex, setZIndex] = useState<number>(1);

  const onValueChange = (_setValue: any) => {
    // eslint-disable-next-line no-shadow
    const _value: ValueType = _setValue();
    if (_value !== value) {
      setValue(_value);
    } else if (_value === value) {
      setValue(-1);
    }
  };

  useEffect(() => {
    if (Platform.OS === "web") {
      const listener = (event: any) => {
        if (!dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
          onClose ? onClose() : setZIndex(1);
        }
      };
      document.addEventListener("mousedown", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
      };
    }
  }, [dropdownRef, onClose]);

  return (
    <View ref={dropdownRef} style={[style, styles.dropdown, { zIndex }]}>
      {title && <Text style={styles.label}>{title}</Text>}
      <DropDownPicker
        loading={loading}
        open={isOpen}
        value={value}
        items={items}
        setOpen={setIsOpen}
        setValue={onValueChange}
        ArrowUpIconComponent={() => (
          <CustomIcon
            iconType="chevron-down"
            iconColor={iconColor || vars.primaryColor}
            iconSize={iconSize}
          />
        )}
        ArrowDownIconComponent={() => (
          <CustomIcon
            iconType="chevron-down"
            iconColor={iconColor || vars.primaryColor}
            iconSize={iconSize}
          />
        )}
        modalProps={{
          presentationStyle: "pageSheet",
          animationType: "slide",
        }}
        CloseIconComponent={() => <></>}
        stickyHeader={false}
        style={[styles.dwopdown, dropdownStyle]}
        containerStyle={styles.containerStyle}
        labelStyle={textStyles.selectText}
        textStyle={textStyles.selectText}
        placeholderStyle={textStyles.selectPlaceholderText}
        dropDownContainerStyle={styles.dropdownContainerStyle}
        listItemContainerStyle={styles.listItemContainerStyle}
        listItemLabelStyle={styles.listItemLabelStyle}
        listMode={Platform.OS === "web" ? "FLATLIST" : "MODAL"}
        placeholder={placeholder}
        testID={testID}
        disabled={disabled}
        multiple={false}
        onOpen={onOpen || (() => setZIndex(10))}
        onClose={onClose || (() => setZIndex(1))}
      />
    </View>
  );
};

export default CustomSelect;

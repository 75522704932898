import { DefaultTheme } from "@react-navigation/native";

export const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "white",
    primaryBackground: "white",
  },
};

export const themeDefault = {
  dark: false,
  colors: {
    primary: "#5BD1D7",
    secondary: "#f5ecec8a",
    background: "#f5ecec8a",
    primaryBackground: "#fafafa",
    secondaryBackground: "#e1eaee",
    card: "rgb(255, 255, 255)",
    text: "#616161",
    buttonText: "#fff",
    borderColor: "#5BD1D7",
    notification: "rgb(255, 69, 58)",
    disableColor: "#f764ae3b",
  },
};
export const PinkTheme = {
  dark: false,
  colors: {
    primary: "#5BD1D7",
    secondary: "#FDE0EF",
    background: "#FDE0EF",
    primaryBackground: "#fafafa",
    secondaryBackground: "#e1eaee",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    buttonText: "#fff",
    borderColor: "#5BD1D7",
    notification: "rgb(255, 69, 58)",
    disableColor: "#f764ae3b",
  },
};
export const PurpleTheme = {
  dark: false,
  colors: {
    primary: "#5841D8",
    secondary: "#DFD8F7",
    background: "#DFD8F7",
    primaryBackground: "#fafafa",
    secondaryBackground: "#e1eaee",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    buttonText: "#fff",
    borderColor: "#5841D8",
    notification: "rgb(255, 69, 58)",
    disableColor: "#5841d838",
  },
};
export const OrangeTheme = {
  dark: false,
  colors: {
    primary: "#FF8000",
    secondary: "#FEE4CF",
    background: "#FEE4CF",
    primaryBackground: "#FF8000",
    secondaryBackground: "#ffffff",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    buttonText: "#fff",
    borderColor: "#FF8000",
    notification: "rgb(255, 69, 58)",
    disableColor: "#ff80003d",
  },
};
export const BlueTheme = {
  dark: false,
  colors: {
    primary: "#002FA7",
    secondary: "#D0D6EE",
    background: "#D0D6EE",
    primaryBackground: "#002FA7",
    secondaryBackground: "#ffffff",
    card: "rgb(255, 255, 255)",
    text: "rgb(28, 28, 30)",
    buttonText: "#fff",
    borderColor: "#002FA7",
    notification: "rgb(255, 69, 58)",
    disableColor: "#8fa2d4",
  },
};

/* eslint-disable no-shadow */
import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import { fetchClient } from "../../../api/axios/axiosApi";
import { AppState } from "../../reducer/root-reducer";
import { Credentials, AuthState } from "uiTypes";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as SecureStore from "expo-secure-store";
import { Platform } from "react-native";

const initialState: AuthState = {
  status: "idle",
  roles: undefined,
  user: undefined,
  userId: undefined,
  username: undefined,
  two_factor_auth: 0,
  language: undefined,
  userTheme: undefined,
  isLoading: false,
  invalidLoginAttempt: 0,
};

//login auth
const authUser = createAsyncThunk(
  "auth",
  async (credentials: Credentials, { dispatch }) => {
    try {
      dispatch(loginStarted());
      const { data } = await (
        await fetchClient()
      ).post("auth", {
        ...credentials,
      });
      if (Platform.OS !== "web") {
        const result = await SecureStore.setItemAsync(
          "credential",
          JSON.stringify(credentials),
        );
      }

      dispatch(loginSuccess());
      return data;
    } catch (e) {
      console.info(e);
      dispatch(loginFail());
      if ((e as any).response) {
        throw new Error((e as any)?.response?.data?.message);
      } else {
        //@ts-ignore
        throw new Error(e);
      }
    }
  },
);

//re-auth to refresh tokens
const reAuth = createAsyncThunk("post/re-auth", async () => {
  try {
    const { data } = await (await fetchClient()).post("re-auth", {});
    return data;
  } catch ({ response }) {
    //@ts-ignore
    throw new Error((response as any).data.message);
  }
});

//no authed user? - then auth with the token e.g. on screen refresh
const tokenAuth = createAsyncThunk("auth", async () => {
  try {
    const { data } = await (await fetchClient()).post("re-auth", {});
    return data;
  } catch ({ response }) {
    //@ts-ignore
    throw new Error((response as any).data.message);
  }
});

const invalidateAuth = createAsyncThunk("auth", async () => {
  await AsyncStorage.removeItem("token");
  throw new Error();
});

export const authenticating = createSelector(
  (state: AppState) => state.auth.status,
  (status) => status === "pending",
);

export const isRejected = createSelector(
  (state: AppState) => state.auth.status,
  (status) => status === "rejected",
);

export const isFulfilled = createSelector(
  (state: AppState) => state.auth.status,
  (status) => status === "fulfilled",
);

export const authSelector = createSelector(
  (state: AppState) => state.auth,
  (num) => num,
);

export const isTwoFactorAuthEnabled = createSelector(
  (state: AppState) => state.auth.two_factor_auth,
  (two_factor_auth) => (two_factor_auth === 1 ? true : false),
);

export const userRoles = createSelector(
  (state: AppState) => state.auth.roles,
  (roles) => roles,
);

export const authenticatedUser = createSelector(
  (state: AppState) => state.auth.user,
  (user) => user,
);

export const error = createSelector(
  isRejected,
  (state: AppState) => state.auth.error,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  (rejected, error) => rejected && error,
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth(state: AuthState) {
      Object.assign(state, initialState);
    },
    //todo: either implement or remove
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logOutAction(state, action) {
      // rootReducer(undefined, action);
    },
    loginStarted(state: AuthState) {
      console.log("started>>>", state.invalidLoginAttempt);
      state.isLoading = true;
    },
    loginSuccess(state: AuthState) {
      console.log("success>>>", state.invalidLoginAttempt);
      state.invalidLoginAttempt = 0;
      state.isLoading = false;
    },
    loginFail(state: AuthState) {
      console.log("fail>>>", state.invalidLoginAttempt);
      state.invalidLoginAttempt = 1;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authUser.pending || tokenAuth.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(reAuth.fulfilled, (state, action) => {
      state.roles = action.payload.roles || [];
      state.user = action.payload.user;
      state.status = "fulfilled";
      state.two_factor_auth = action.payload.two_factor_auth;
    });
    builder.addCase(authUser.fulfilled, (state, action) => {
      state.roles = action.payload.roles || [];
      state.user = action.payload.user;
      state.status = "fulfilled";
      state.two_factor_auth = action.payload.two_factor_auth;
    });
    builder.addCase(
      authUser.rejected || invalidateAuth.rejected || reAuth.rejected,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      (state, { error }) => {
        state.user = undefined;
        state.status = "rejected";
        state.error = error.message;
      },
    );
  },
});

export { authUser, invalidateAuth, reAuth, tokenAuth };
export const {
  logOutAction,
  clearAuth,
  loginStarted,
  loginSuccess,
  loginFail,
} = authSlice.actions;
export default authSlice.reducer;

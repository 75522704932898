import React from "react";
import { Path } from "react-native-svg";

const HomeFilled: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M21.6144 23H14.7746C14.2828 23 13.8805 22.5977 13.8805 22.1059V17.859C13.8805 16.7861 13.0311 15.9367 11.9582 15.9367C10.8853 15.9367 10.0359 16.7861 10.0359 17.859V22.1059C10.0359 22.5977 9.63355 23 9.1418 23H2.39139C1.89964 23 1.4973 22.5977 1.4973 22.1059V10.6615C1.4973 9.85684 1.89964 9.09686 2.52551 8.64981L11.5111 2.16764C11.8241 1.94412 12.2264 1.94412 12.5394 2.16764L21.525 8.64981C22.1509 9.09686 22.5532 9.85684 22.5532 10.6615V22.1506C22.5085 22.5977 22.1062 23 21.6144 23Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default HomeFilled;

import { useTheme } from "@react-navigation/native";
import { Dimensions, Platform, StyleSheet } from "react-native";

const styles = (props: any) => {
  const windowWidth = Dimensions.get("window").width;
  const { colors } = useTheme();
  return StyleSheet.create({
    container: {
      width: "100%",
      maxWidth: windowWidth,
    },
    buttonView: {
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: 2,
      minHeight: 36,
    },
    leftPos: {
      justifyContent: "flex-start",
      textAlign: "left",
    },
    centerPos: { justifyContent: "center", textAlign: "center" },
    rightPos: { justifyContent: "flex-end", textAlign: "right" },

    // Button styles
    button: {
      borderRadius: 14,
      paddingHorizontal: 12,
      borderWidth: 2,
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: Platform.OS === "ios" ? 4 : 0,
    },
    primary: {
      backgroundColor: `${
        props.disabled ? colors.disableColor : colors.primary
      }`,
      borderColor: `${
        props.disabled ? colors.disableColor : colors.borderColor
      }`,
      shadowColor: "rgba(88, 65, 216, 0.28)",
      shadowOffset: { width: -6, height: 8 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 8,
    },
    secondary: {
      backgroundColor: colors.secondaryBackground,
      borderColor: `${
        props.disabled ? colors.disableColor : colors.borderColor
      }`,
    },
    red: {
      borderColor: colors.white,
    },
    redBordered: {
      borderColor: colors.textRed,
    },
    white: {
      borderColor: colors.white,
    },
    link: {
      borderColor: "transparent",
    },

    // Text styles
    text: {
      flex: 1,
    },
    primaryText: {
      color: colors.buttonText,
    },
    secondaryText: {
      color: colors.primary,
    },
    redText: {
      color: colors.textRed,
    },
    redBorderedText: {
      color: colors.textRed,
    },
    whiteText: {
      color: colors.primaryColor,
    },
    linkText: {
      color: colors.primary,
    },

    // Icon styles
    icon: {
      display: "flex",
      paddingRight: 5,
    },
    primaryIcon: {
      color: colors.secondaryColor,
    },
    secondaryIcon: {
      color: colors.primaryColor,
    },
    redIcon: {
      color: colors.textRed,
    },
    redBorderedIcon: {
      color: colors.textRed,
    },
    whiteIcon: {
      color: colors.primaryColor,
    },
    linkIcon: {
      color: colors.primaryColor,
    },
  });
};

export default styles;

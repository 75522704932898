import React, { memo } from "react";
import { useWindowDimensions, View } from "react-native";
import * as Progress from "react-native-progress";
import { isNaN } from "lodash";
import { mobileBreak } from "src/navigation/header/drawerNavOptions.styles";
import { vars } from "src/styles";
import { StyledText } from "src/styles/text";
import styles from "./quizProgress.styles";
import { useTranslation } from "src/translations/useTranslation";

interface Props {
  total: number;
  progress: number;
}

const QuizProgress: React.FC<Props> = memo(({ total, progress }) => {
  const { width: windowWidth } = useWindowDimensions();
  const { text } = useTranslation();
  const completedPercent = isNaN(Math.round((progress * 100) / total))
    ? 0
    : Math.round((progress * 100) / total);
  const fillColor =
    completedPercent < 35
      ? vars.progressRed
      : completedPercent < 50
      ? vars.progressYellow
      : completedPercent < 75
      ? vars.progressBlue
      : vars.primaryColor;
  return (
    <View>
      <StyledText variant="body1" style={styles.qrText}>
        {text("quizEditList.progress")} {completedPercent}%
      </StyledText>
      <Progress.Bar
        progress={isNaN(progress / total) ? 0 : progress / total}
        color={fillColor}
        borderWidth={0}
        unfilledColor={vars.lightGrey}
        width={windowWidth > mobileBreak ? 200 : 150}
      />
    </View>
  );
});

export default QuizProgress;

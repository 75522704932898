import React from "react";
import { Pressable, View } from "react-native";
import { useDispatch } from "react-redux";
import IkImageViewer from "src/imagekit/screens/Fetch/ikImageViewer";
import { setRouteName } from "src/redux/features/misc/slice";
import customStyles from "src/styles/commonStyles";
import { StyledText } from "src/styles/text";
import useStyles from "./resourceViewNavOption.styles";

const ResourceViewNavOption = ({
  navigation,
  params,
  destination,
  text,
  iconPath,
  showIcon,
  subText,
}: {
  navigation: any;
  params?: any;
  destination: string;
  text: string;
  iconPath?: string;
  showIcon?: boolean;
  // eslint-disable-next-line no-undef
  subText?: string | JSX.Element;
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const cusStyles = customStyles();

  return (
    <Pressable
      testID={`navOption_${destination}`}
      style={[styles.navOption, cusStyles.cardShadow, { marginRight: 20 }]}
      onPress={() => {
        dispatch(setRouteName(destination));
        navigation.navigate(destination, params);
      }}
    >
      <View style={[styles.row]}>
        {showIcon && (
          <>
            {iconPath ? (
              <IkImageViewer
                style={{
                  marginRight: 10,
                  borderRadius: 12,
                  overflow: "hidden",
                }}
                imagePath={iconPath}
                height={100}
                width={100}
                transform
              />
            ) : (
              <View style={[styles.resourceImage]} />
            )}
          </>
        )}
        <View
          style={[
            styles.textView,
            showIcon && iconPath ? {} : styles.textViewPadding,
          ]}
        >
          <StyledText
            variant="linkText"
            textStyle={styles.title}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {text}
          </StyledText>
          {subText && <View style={styles.categoryView}>{subText}</View>}
        </View>
      </View>
    </Pressable>
  );
};

export default ResourceViewNavOption;

import React from "react";
import { View } from "react-native";
import styles from "./customRadio.styles";
import CustomButton, { CustomButtonTheme } from "../customButton/customButton";
import { vars } from "src/styles";

interface Props {
  items: (string | number)[];
  selectedItem: string | number;
  theme?: CustomButtonTheme;
  onSelect: (item: string | number) => void;
}

const CustomRadio: React.FunctionComponent<Props> = ({
  items,
  selectedItem,
  onSelect,
  theme = "primary",
}) => {
  return (
    <View style={[styles.checkboxes]}>
      {items.map((item: string | number, key: number) => (
        <View key={key} style={styles.checkbox}>
          <CustomButton
            title={item as string}
            onPress={() => onSelect(item)}
            icon={
              selectedItem === item
                ? "checked-radio-filled"
                : "checked-radio-outline"
            }
            theme={theme}
            position="left"
            withOpacity={false}
            textStyle={
              selectedItem === item ? styles.textChecked : styles.textStyle
            }
            iconColor={selectedItem === item ? vars.primaryColor : "#A6B1C2"}
            style={
              selectedItem === item
                ? styles.checkboxBorder
                : styles.checkboxCheckedBorder
            }
          />
        </View>
      ))}
    </View>
  );
};

export default CustomRadio;

import React from "react";
import { View, Pressable } from "react-native";
import { vars } from "src/styles";
import { StyledText } from "src/styles/text";
import { CustomSvgIcon } from "src/uikit";
import styles from "./addNew.styles";

export const AddNew = ({
  title,
  destinationScreen,
  navigation,
  params,
  newEnabled = true,
}: {
  title?: string;
  destinationScreen: string;
  navigation: any;
  params: any;
  newEnabled?: boolean;
}) => {
  return (
    <View style={[styles.addNew, !newEnabled ? styles.disabled : {}]}>
      <StyledText variant="body1" textStyle={styles.title}>
        {title}
      </StyledText>
      <Pressable
        disabled={!newEnabled}
        style={({ pressed }) => [
          {
            backgroundColor:
              pressed && newEnabled ? "rgb(210, 230, 255)" : "white",
          },
          styles.pressable,
          !newEnabled ? styles.disabledPressable : {},
        ]}
        onPress={() => {
          if (newEnabled) {
            navigation.navigate("Main", {
              ...{ screen: destinationScreen },
              params,
            });
          }
        }}
      >
        <CustomSvgIcon
          iconType="plus"
          iconSize="24"
          iconColor={vars.primaryColor}
        />
      </Pressable>
    </View>
  );
};

import { Dimensions, StyleSheet } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";
import { vars } from "src/styles";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    centeredInnerView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
    },
    modalView: {
      width: windowWidth > mobileBreak ? 502 : "90%",
      margin: 30,
      backgroundColor: vars.white,
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
    },
    modalTitle: {
      marginBottom: 10,
    },
    modalText: {
      textAlign: "center",
      marginBottom: 40,
    },
    modalButton: {
      width: "100%",
      marginVertical: 5,
    },
  });
};

export default styles;

import React from "react";
import { StyleSheet, Text, TextProps, View } from "react-native";
import { themeDefault } from "src/theme";
import { vars } from "./";
import useStyles from "./commonStyles";

type TextVariant =
  | "h1"
  | "h2"
  | "h3"
  | "buttonText"
  | "body1"
  | "body1black"
  | "body1lite"
  | "body1error"
  | "body1Link"
  | "body1ok"
  | "body1truncated"
  | "inputText"
  | "authedInputText"
  | "linkText"
  | "linkTextUnderline"
  | "snackMessage"
  | "navLinkText"
  | "modalText"
  | "modalTitle";

interface StyledTextProps extends TextProps {
  variant: TextVariant;
  textStyle?: any;
}

//fonts loaded in App.tsx
export const textStyles = StyleSheet.create({
  h1: {
    fontFamily: "Poppins_600SemiBold",
    fontSize: 36,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 42,
    letterSpacing: 0,
    color: themeDefault.colors.text,
  },
  h2: {
    fontFamily: "Poppins_500Medium",
    fontSize: 26,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 42,
    letterSpacing: 0,
    color: "#6B7079",
  },
  h3: {
    fontFamily: "Poppins_500Medium",
    fontSize: 22,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 33,
    letterSpacing: 0,
    color: "#0D1625",
  },
  buttonText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 21,
    color: "#6B7079",
  },
  genFormInputText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 30,
    color: vars.black,
  },
  genFormTextareaText: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 18,
    lineHeight: 27,
    color: vars.black,
    minHeight: 300,
    height: 300,
  },
  inputTitle: {
    fontFamily: "Poppins_700Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 32,
    lineHeight: 37.5,
    color: vars.black,
  },
  inputText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 27,
    color: "#6B7079",
    minHeight: 40,
  },
  selectText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 30,
    color: vars.black,
  },
  selectPlaceholderText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 20,
    lineHeight: 30,
  },
  authedInputText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 19,
    color: "#6B7079",
  },
  linkText: {
    fontFamily: "Poppins_600SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 27,
    color: vars.black,
  },
  linkTextUnderline: {
    fontFamily: "Poppins_600SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 27,
    color: vars.black,
    textDecorationLine: "underline",
  },
  snackMessage: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 19,
    color: "#ffffff",
  },
  navLinkText: {
    fontFamily: "Poppins_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 19,
    color: "#C5CBD5",
  },
  body1: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 27,
    color: "#87849B",
  },
  body1truncated: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 21,
    color: "#87849B",
  },
  body1black: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 27,
    color: vars.black,
  },
  body1Link: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 16,
    color: "#6B7079",
    textDecorationLine: "underline",
  },
  body1lite: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 16,
    color: "red",
  },
  body1error: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 16,
    color: "#e13b3b",
  },
  body1ok: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 16,
    color: "#39b939",
  },
  modalTitle: {
    fontFamily: "Poppins_600SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 32,
    lineHeight: 48,
    color: vars.black,
  },
  modalText: {
    fontFamily: "Poppins_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 17,
    lineHeight: 27,
    color: vars.grey,
  },
});

export const StyledText: React.FunctionComponent<StyledTextProps> = ({
  children,
  variant,
  style,
  textStyle,
  ...props
}) => {
  const styles = useStyles();
  const getStyle = () => {
    return (textStyles as any)[variant];
  };

  return (
    <View style={[styles.styledText, style]}>
      <Text style={[getStyle(), textStyle]} {...props}>
        {children}
      </Text>
    </View>
  );
};

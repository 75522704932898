/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { AdminViewUserCollectionState } from "./admin-view-user-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Adminviewuser } from "../../../../../../types/domain/flat-types";
const initialState: AdminViewUserCollectionState = {
  items: undefined,
  status: "idle",
};

export const getAdminViewUsers = createAsyncThunk(
  "get/admin-view-users",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`admin-view-user?offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchAdminViewUsers = createAsyncThunk(
  "get/admin-view-users",
  async ({ search, offset, limit, filter }: { search: Search, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`admin-view-user-search?offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getAdminViewUser = createAsyncThunk(
  "get/admin-view-user",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`admin-view-user/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const adminViewUsersSlice = createSlice({
  name: "adminViewUsers",
  initialState,
  reducers: {
    clearAdminViewUserItems(state: AdminViewUserCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminViewUsers.pending || searchAdminViewUsers.pending, (state: AdminViewUserCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getAdminViewUsers.fulfilled || searchAdminViewUsers.fulfilled, (state: AdminViewUserCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getAdminViewUsers.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getAdminViewUser.pending, (state: AdminViewUserCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getAdminViewUser.fulfilled, (state: AdminViewUserCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getAdminViewUser.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const adminViewUserSelector = (id: number) => (state: AppState) => {
  return state.adminViewUsers?.items?.find((o) => o.Id === id);
}; 

export const adminViewUserLoading = createSelector(
  (state: AppState) => state.adminViewUsers.status,
  status => status === 'pending'
);

export const adminViewUserErrorSelector = createSelector(
  (state: AppState) => state.adminViewUsers,
  status => status.error
);


export const adminViewUsersSelector = createSelector(
  (state: AppState) => state.adminViewUsers,
  state => state.items
);

export const adminViewUsersLoading = createSelector(
  (state: AppState) => state.adminViewUsers.status,
  status => status === 'pending'
);

export const adminViewUsersErrorSelector = createSelector(
  (state: AppState) => state.adminViewUsers,
  status => status.error
);

export const { clearAdminViewUserItems } = adminViewUsersSlice.actions;

export default adminViewUsersSlice.reducer;

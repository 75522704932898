import { Platform, StyleSheet } from "react-native";

const styles = ({ bgBanner }) =>
  StyleSheet.create({
    confirmationPage: {
      alignItems: "center",
    },
    confImage: {
      marginTop: 54,
      height: 180,
      width: 180,
    },
    confButtons: {
      width: "100%",
      marginTop: 40,
    },
    title: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 10,
    },
    preamble: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    signUpBanner: {
      backgroundColor: bgBanner,
      width: Platform.OS === "web" ? "15%" : "70%",
      borderRadius: 60,
      marginBottom: 30,
    },
  });

export default styles;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { ProgressStatsCollectionState } from "./progress-stats-collection-state";

import { Progressstats } from "../../../../../../types/domain/flat-types";
const initialState: ProgressStatsCollectionState = {
  items: undefined,
  status: "idle",
};

export const getProgressStatss = createAsyncThunk(
  "get/progress-statss",
  async (options?: { user?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`progress-stats?user=${options?.user}&language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);




export const getProgressStats = createAsyncThunk(
  "get/progress-stats",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`progress-stats/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: ProgressStatsCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].User : undefined;

//   if(currentStateConstraint && actionArgs.user && Number(actionArgs.user) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].User : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const progressStatssSlice = createSlice({
  name: "progressStatss",
  initialState,
  reducers: {
    clearProgressStatsItems(state: ProgressStatsCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProgressStatss.pending, (state: ProgressStatsCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getProgressStatss.fulfilled, (state: ProgressStatsCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getProgressStatss.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getProgressStats.pending, (state: ProgressStatsCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getProgressStats.fulfilled, (state: ProgressStatsCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getProgressStats.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const progressStatsSelector = (id: number) => (state: AppState) => {
  return state.progressStatss?.items?.find((o) => o.Id === id);
}; 

export const progressStatsLoading = createSelector(
  (state: AppState) => state.progressStatss.status,
  status => status === 'pending'
);

export const progressStatsErrorSelector = createSelector(
  (state: AppState) => state.progressStatss,
  status => status.error
);


export const progressStatssSelector = (user?: number) => (state: AppState) => {
  if (!user) {
    return undefined;
  }
  return state.progressStatss?.items?.filter((q) => q.User === user);
}; 

export const progressStatssLoading = createSelector(
  (state: AppState) => state.progressStatss.status,
  status => status === 'pending'
);

export const progressStatssErrorSelector = createSelector(
  (state: AppState) => state.progressStatss,
  status => status.error
);

export const { clearProgressStatsItems } = progressStatssSlice.actions;

export default progressStatssSlice.reducer;

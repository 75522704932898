import React, { ReactElement } from "react";
import { useWindowDimensions, View } from "react-native";
import { StyledText } from "src/styles/text";
import { themeDefault } from "src/theme";
import { CustomButton } from "src/uikit";
import { AddNew } from "../admin/cms/addNew.tsx/addNew";
import useStyles from "./page.styles";

export const PageHeader = ({
  title,
  preamble,
  style,
  navigation,
  addNewDestination,
  onGoBack,
  addNewParams,
  hideGoBack,
  newEnabled = true,
  headerComponents,
  backButtonStyle,
  titleStyle,
  preambleStyle,
}: {
  title?: string;
  preamble?: string;
  style?: any;
  navigation: any;
  addNewDestination?: any;
  addNewParams?: any;
  onGoBack?: () => void;
  hideGoBack?: boolean;
  newEnabled?: boolean;
  headerComponents?: ReactElement;
  backButtonStyle?: any;
  titleStyle?: any;
  preambleStyle?: any;
}) => {
  const styles = useStyles();
  const { width: windowWidth } = useWindowDimensions();
  //fix for https://github.com/react-navigation/react-navigation/issues/5392
  // const latestKey = () => {
  //   const historyLength = navigation?.getState()?.history?.length;
  //   const key = navigation?.getState().history[historyLength - 1]?.key;
  //   return key;
  // };//gsdgsd

  // const previousKey = () => {
  //   const key = history![history?.length! - 2];
  //   return key;
  // };

  // useFocusEffect(() => {
  //   const key = latestKey();
  //   if (key && history?.indexOf(key) === -1) {
  //     dispatch(historyPush(key));
  //   }
  // });

  return (
    <View style={[styles.header, style]}>
      <View style={styles.headerTopBar}>
        {navigation.canGoBack() &&
          hideGoBack !== true &&
          windowWidth > 1150 && ( //not using largePageWidth as design doesn;t work at that width
            <CustomButton
              title={
                <span style={{ color: themeDefault.colors.text }}>Back</span>
              }
              icon="chevron-left"
              iconColor={themeDefault.colors.text}
              onPress={() => {
                if (onGoBack) {
                  onGoBack();
                }
                navigation.goBack();
              }}
              theme="secondary"
              iconStyle={styles.backButtonIcon}
              textStyle={styles.backButtonText}
              style={[styles.backButton, backButtonStyle]}
            />
          )}
        {headerComponents}
      </View>

      {!!title && (
        <StyledText variant="h1" style={[styles.title]} textStyle={titleStyle}>
          {title}
        </StyledText>
      )}
      {!!preamble && (
        <View style={styles.preambleContainer}>
          <StyledText
            variant="buttonText"
            style={[styles.preamble, preambleStyle]}
          >
            {preamble}
          </StyledText>
        </View>
      )}

      {addNewDestination && (
        <AddNew
          destinationScreen={addNewDestination}
          params={addNewParams}
          navigation={navigation}
          title={`New`}
          newEnabled={newEnabled}
        />
      )}
    </View>
  );
};

import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useSelector } from "react-redux";
import { maxPageWidth } from "src/components/forms/form.styles";
import {
  avgDeviceSize,
  mobileBreak,
} from "src/navigation/header/drawerNavOptions";
import { largePageWidth } from "src/pages/layout/cms.styles";
import { videoPlayerFullScreen } from "src/redux/features/misc/slice";
import { themeDefault } from "src/theme";

const useStyles = () => {
  const { width: windowWidth } = useWindowDimensions();
  const videoFullScreen = useSelector(videoPlayerFullScreen);

  return StyleSheet.create({
    page: {
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "flex-start",
      marginLeft: windowWidth > mobileBreak ? "auto" : 0,
      marginRight: windowWidth > mobileBreak ? "auto" : 0,
      paddingHorizontal: windowWidth > mobileBreak ? 40 : 20,
      //maxWidth: windowWidth >= maxPageWidth ? maxPageWidth : undefined,
      width: windowWidth > mobileBreak ? "100%" : undefined,
      // backgroundColor: windowWidth > mobileBreak ? "red" : "white",
    },
    fullscreenpage: {
      //TODO: this is poorly named considering the style below!
      flexDirection: "column",
      flex: 1,
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginLeft: windowWidth > mobileBreak ? "auto" : 0,
      marginRight: windowWidth > mobileBreak ? "auto" : 0,
      paddingTop: 0,
      maxWidth: largePageWidth,
      width: windowWidth > mobileBreak ? "100%" : undefined,
    },
    fullScreenPage: {
      position: "absolute",
      height: "100%",
      width: "100%",
      alignItems: windowWidth > mobileBreak ? "flex-start" : "center",
      justifyContent: "flex-start",
      padding: 0,
      paddingRight: 0,
      paddingTop: 0,
      backgroundColor: "green",
    },
    header: {
      width: "100%",
      maxWidth: windowWidth > mobileBreak ? maxPageWidth : "100%",
      zIndex: 1,
    },
    headerTopBar: {
      width: "100%",
      flexDirection: "row",
      borderWidth: 0,
      position: windowWidth > mobileBreak ? "relative" : undefined,
      zIndex: 1,
    },
    backButton: {
      maxWidth: windowWidth > mobileBreak ? 96 : 40,
      borderWidth: 0,
      backgroundColor: "#ffffff",
      position: windowWidth > mobileBreak ? "absolute" : undefined,
      left: windowWidth > mobileBreak ? -150 : undefined,
      top: windowWidth > mobileBreak ? 47 : undefined,
      zIndex: 1,
      shadowColor: "#c5cae4",
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    backButtonIcon: {},
    backButtonText: {
      fontSize: 16,
      color: themeDefault.colors.text,
    },
    title: {
      marginTop:
        windowWidth > mobileBreak ? 50 : Platform.OS === "ios" ? 0 : 20,
      // paddingLeft: windowWidth > mobileBreak ? 20 : 20,
      // paddingRight: windowWidth > mobileBreak ? 20 : 20,
      marginBottom: windowWidth > mobileBreak ? 30 : 10,
      paddingTop: Platform.OS === "ios" ? 20 : 0,
      width: "100%",
    },
    intro: {
      // paddingLeft: windowWidth > mobileBreak ? 20 : 20,
      // paddingRight: windowWidth > mobileBreak ? 20 : 20,
    },
    article: {
      // paddingLeft: windowWidth > mobileBreak ? 20 : 20,
      // paddingRight: windowWidth > mobileBreak ? 20 : 20,
    },
    preambleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      // paddingHorizontal: 20,
      marginBottom: 14,
    },
    preamble: {
      maxWidth: "100%",
    },
    backArrow: {
      width: 18,
      height: 10,
      marginRight: 10,
    },
    goBack: {
      flexDirection: "row",
      height: 40,
      width: 100,
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: windowWidth > mobileBreak ? 20 : 0,
    },
    container: {
      width: "100%",
      // scrollbarWidth: "none",
      // paddingRight: windowWidth > mobileBreak ? 20 : 14,
      // paddingRight: windowWidth > mobileBreak ? 20 : 0,
    },
    fullScreenContainer: {
      position: "absolute",
      height: "100%",
      maxHeight: "100%",
      width: "100%",
    },
    parentSubContainer: {
      flexGrow: 1,
      width: "100%",
      alignItems: "center",
      flexDirection: "column",
    },
    centerAligned: {
      flex: 1,
      width: "100%",
      //maxWidth: videoFullScreen ? "100%" : maxPageWidth,
    },
    body: {
      flexGrow: 1,
      width: "100%",
      // alignItems: "center",
      // marginHorizontal: "auto",
    },
    fullScreenBody: {
      position: "absolute",
      backgroundColor: "orange",
      width: "100%",
      alignItems: windowWidth > mobileBreak ? "flex-start" : "center",
      display: "flex",
      height: "100%",
      maxHeight: "100%", //works for android only
      overflow: "hidden", //even though scroll is disabled on full screen...scrollbar still shows?
      padding: 0,
    },
    //ONLY remove styles with this and re-style in cms.styles
    subList: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingBottom: 0,
      paddingRight: 0,
      backgroundColor: "rgba(0,0,0,0)",
    },
    paddingMob20: {
      paddingHorizontal: windowWidth > mobileBreak ? 20 : 20,
    },
    largePageBody: {
      maxWidth:
        windowWidth > largePageWidth
          ? largePageWidth
          : windowWidth > avgDeviceSize
          ? maxPageWidth
          : undefined,
    },
    noMobPadding: {
      paddingHorizontal: 0,
    },
  });
};

export default useStyles;

import React from "react";
import { Path } from "react-native-svg";

const ChevronRight: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M7.93934 5.06066C7.35355 4.47487 7.35355 3.52513 7.93934 2.93934C8.52513 2.35355 9.47487 2.35355 10.0607 2.93934L7.93934 5.06066ZM17 12L18.0607 10.9393C18.6464 11.5251 18.6464 12.4749 18.0607 13.0607L17 12ZM10.0607 21.0607C9.47487 21.6464 8.52513 21.6464 7.93934 21.0607C7.35355 20.4749 7.35355 19.5251 7.93934 18.9393L10.0607 21.0607ZM10.0607 2.93934L18.0607 10.9393L15.9393 13.0607L7.93934 5.06066L10.0607 2.93934ZM18.0607 13.0607L10.0607 21.0607L7.93934 18.9393L15.9393 10.9393L18.0607 13.0607Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default ChevronRight;

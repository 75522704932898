import React from "react";
import { Platform, View } from "react-native";
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from "react-native-keyboard-aware-scroll-view";
import { useSelector } from "react-redux";
import { videoPlayerFullScreen } from "src/redux/features/misc/slice";

import useStyles from "./page.styles";

export const PageBody = ({
  style,
  children,
  scrollEnabled,
  onScrollCallback,
  keyboardAwareScrollViewProps,
  scrollableRef,
}: {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  style?: any;
  scrollEnabled?: boolean;
  onScrollCallback?: (evt: any) => void;
  keyboardAwareScrollViewProps?: KeyboardAwareScrollViewProps;
  scrollableRef?: React.LegacyRef<KeyboardAwareScrollView>;
}) => {
  const styles = useStyles();
  const videoFullScreen = useSelector(videoPlayerFullScreen);

  const getScrollEnabled = () => {
    if (videoFullScreen) {
      return false;
    } else if (scrollEnabled !== undefined) {
      return scrollEnabled;
    }
    return true;
  };

  return (
    <KeyboardAwareScrollView
      testID="k-scroll-v"
      scrollEnabled={getScrollEnabled()}
      onScroll={onScrollCallback}
      {...keyboardAwareScrollViewProps}
      style={videoFullScreen ? styles.fullScreenContainer : styles.container}
      contentContainerStyle={[
        videoFullScreen ? styles.fullScreenBody : [styles.body, style],
      ]}
      ref={scrollableRef}
      extraScrollHeight={Platform.OS === "ios" ? 100 : undefined}
      scrollIndicatorInsets={{ top: 1, bottom: 1 }}
      bounces={true}
      // disableScrollViewPanResponder={true}
    >
      <View style={styles.parentSubContainer} testID="p-sub">
        <View style={styles.centerAligned}>{children}</View>
      </View>
    </KeyboardAwareScrollView>
  );
};

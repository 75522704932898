import { Dimensions, StyleSheet } from "react-native";
import { mobileBreak } from "src/navigation/header/drawerNavOptions";

const styles = () => {
  const windowWidth = Dimensions.get("window").width;
  return StyleSheet.create({
    navOptions: {
      flexWrap: "wrap",
      flexDirection: "row",
      //justifyContent: "space-between",
      alignItems: "flex-start",
      padding: 0,
      paddingHorizontal: 4,
    },
    navOptionsListOnly: {
      flexDirection: "row",
      justifyContent:
        windowWidth > mobileBreak ? "flex-start" : "space-between",
      alignItems: "flex-start",
    },
    navOption: {
      backgroundColor: "#ffffff",
      paddingHorizontal: 6,
      marginHorizontal: windowWidth > mobileBreak ? 0 : 0,
      marginVertical: windowWidth > mobileBreak ? 10 : 6,
      width: windowWidth > mobileBreak ? "49%" : "100%",
      borderRadius: 20,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingVertical: 14,
      paddingLeft: 16,
      paddingRight: 16,
      elevation: 8,
      shadowColor: "rgba(14, 19, 46, 0.06)",
      shadowOffset: { width: -2, height: 4 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
    },
    navChevron: {
      width: 24,
      height: 24,
    },
    navLeft: {
      flexDirection: "row",
      alignItems: "center",
    },
    navIcon: {
      marginRight: 10,
    },
    mr: {
      marginRight: 6,
    },
  });
};

export default styles;

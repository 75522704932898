/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { LessonPublishedByCategoryFullCollectionState } from "./lesson-published-by-category-full-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Lessonpublishedbycategoryfull } from "../../../../../../types/domain/flat-types";
const initialState: LessonPublishedByCategoryFullCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessonPublishedByCategoryFulls = createAsyncThunk(
  "get/lesson-published-by-category-fulls",
  async (options?: { categorypublishedfull?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-by-category-full?categorypublishedfull=${options?.categorypublishedfull}&language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchLessonPublishedByCategoryFulls = createAsyncThunk(
  "get/lesson-published-by-category-fulls",
  async ({ categorypublishedfull, search, language, offset, limit, filter }: { categorypublishedfull?: number, search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`lesson-published-by-category-full-search?categorypublishedfull=${categorypublishedfull}&language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getLessonPublishedByCategoryFull = createAsyncThunk(
  "get/lesson-published-by-category-full",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-by-category-full/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: LessonPublishedByCategoryFullCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].CategoryPublishedFull : undefined;

//   if(currentStateConstraint && actionArgs.categorypublishedfull && Number(actionArgs.categorypublishedfull) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].CategoryPublishedFull : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const lessonPublishedByCategoryFullsSlice = createSlice({
  name: "lessonPublishedByCategoryFulls",
  initialState,
  reducers: {
    clearLessonPublishedByCategoryFullItems(state: LessonPublishedByCategoryFullCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonPublishedByCategoryFulls.pending || searchLessonPublishedByCategoryFulls.pending, (state: LessonPublishedByCategoryFullCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessonPublishedByCategoryFulls.fulfilled || searchLessonPublishedByCategoryFulls.fulfilled, (state: LessonPublishedByCategoryFullCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessonPublishedByCategoryFulls.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLessonPublishedByCategoryFull.pending, (state: LessonPublishedByCategoryFullCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLessonPublishedByCategoryFull.fulfilled, (state: LessonPublishedByCategoryFullCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getLessonPublishedByCategoryFull.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonPublishedByCategoryFullSelector = (id: number) => (state: AppState) => {
  return state.lessonPublishedByCategoryFulls?.items?.find((o) => o.Id === id);
}; 

export const lessonPublishedByCategoryFullLoading = createSelector(
  (state: AppState) => state.lessonPublishedByCategoryFulls.status,
  status => status === 'pending'
);

export const lessonPublishedByCategoryFullErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedByCategoryFulls,
  status => status.error
);


export const lessonPublishedByCategoryFullsSelector = (categoryPublishedFull?: number) => (state: AppState) => {
  if (!categoryPublishedFull) {
    return undefined;
  }
  return state.lessonPublishedByCategoryFulls?.items?.filter((q) => q.CategoryPublishedFull === categoryPublishedFull);
}; 

export const lessonPublishedByCategoryFullsLoading = createSelector(
  (state: AppState) => state.lessonPublishedByCategoryFulls.status,
  status => status === 'pending'
);

export const lessonPublishedByCategoryFullsErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedByCategoryFulls,
  status => status.error
);

export const { clearLessonPublishedByCategoryFullItems } = lessonPublishedByCategoryFullsSlice.actions;

export default lessonPublishedByCategoryFullsSlice.reducer;

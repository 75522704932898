import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { Progressstats } from "../../../../../types/domain/flat-types";
import {
  getLessonPublishedByCategorys,
  lessonPublishedByCategorysSelector,
} from "src/redux/domain/features/lessonPublishedByCategory/collection-slice";
import {
  getProgressStatss,
  progressStatssSelector,
} from "src/redux/domain/features/progressStats/collection-slice";
import { CustomButton } from "src/uikit";
import { useFocusEffect } from "@react-navigation/native";
import { useTranslation } from "src/translations/useTranslation";

export const useNextLesson = ({ navigation }: { navigation: any }) => {
  const dispatch = useDispatch();
  const { userId, language } = useUserInfo();
  const { text } = useTranslation();
  const _progressStats = useSelector(progressStatssSelector(userId));

  const progressStats: Progressstats & { TotalLessons: number } = _progressStats
    ? _progressStats[0]
    : undefined;

  const _lessonsInCategory = useSelector(
    lessonPublishedByCategorysSelector(progressStats?.Category),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (userId) {
        dispatch(getProgressStatss({ user: userId, language }));
      }
    }, [dispatch, language, userId]),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (progressStats?.Category && !_lessonsInCategory) {
        dispatch(
          getLessonPublishedByCategorys({
            categorypublishedfull: progressStats.Category,
            language,
          }),
        );
      }
    }, [progressStats?.Category, _lessonsInCategory, dispatch, language]),
  );

  const getNextLesson = () => {
    const nextLesson = _lessonsInCategory?.find((l) => {
      return (
        l.LessonPosition === progressStats.LastQuizPassedLessonPosition! + 1
      ); //i.e. there's another lesson in the Category
    });

    return nextLesson;
  };

  const CanNavToNextLesson = () => {
    if (!progressStats?.LastQuizPassedLessonPosition) {
      return false;
    }

    if (!getNextLesson()) {
      return false;
    }

    return true;
  };

  const navToNextLessonInCategory = () => {
    const nextLesson = getNextLesson();

    //todo: this doesn't chanage the main menu selection
    navigation.navigate("LessonPublishedByCategoryView", {
      categoryPublishedFullId: progressStats?.Category,
      lessonPublishedByCategoryFullId: nextLesson.Id,
    });
  };

  const navToCategories = () => {
    navigation.navigate("Categories");
  };

  return {
    nextLessonButton: () => (
      <>
        {CanNavToNextLesson() && (
          <CustomButton
            title={text("quizViewPage.nextLessonBtn")}
            onPress={navToNextLessonInCategory}
          />
        )}
      </>
    ),
    CanNavToNextLesson,
    navToCategoriesButton: () => (
      <>
        {CanNavToNextLesson() && (
          <CustomButton
            title={text("quizViewPage.nextLessonBtn")}
            onPress={navToCategories}
          />
        )}
      </>
    ),
  };
};

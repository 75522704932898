import { useFocusEffect, useTheme } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import CategoryPublishedViewListScreen from "src/components/domain/pages/categoryPublished/categoryPublished.viewList";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import usePageStyles from "src/pages/layout/page.styles";
import { PageHeader } from "src/pages/layout/pageheader";
import {
  clearProgressStatsItems,
  getProgressStatss,
  progressStatssSelector,
} from "src/redux/domain/features/progressStats/collection-slice";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { StyledText } from "src/styles/text";
import { useTranslation } from "src/translations/useTranslation";
import { formatMoney } from "src/utils";
import { Progressstats } from "../../../../../types/domain/flat-types";
import { clearCategoryItems } from "../../../redux/domain/features/category/collection-slice";
import { clearCategoryPublishedItems } from "../../../redux/domain/features/categoryPublished/collection-slice";
import { clearCategoryPublishedFullItems } from "../../../redux/domain/features/categoryPublishedFull/collection-slice";
import { clearLessonPublishedByCategoryItems } from "../../../redux/domain/features/lessonPublishedByCategory/collection-slice";
import { CoinListItem, useCoinGecko } from "../useCoinGecko/useCoinGecko";
import { useNextLesson } from "../useNextLesson/useNextLesson";
import { Doughnut } from "./chart/doughnut";
import { CoinTile } from "./coinTile/coinTile";
import styles from "./dashboard.styles";
import { StatTile } from "./statTile/statTile";

const coinsToShow = ["btc", "eth", "usdc", "usdt", "sol", "dot"];

const UserDashboardScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) => {
  const { colors } = useTheme();
  const pageStyles = usePageStyles();
  const dispatch = useDispatch();
  const { userId, language, name } = useUserInfo();

  const { coinList } = useCoinGecko();
  const _progressStats = useSelector(progressStatssSelector(userId));

  const { text } = useTranslation();
  const [coins, setCoins] = useState<CoinListItem[]>([]);

  const { CanNavToNextLesson, navToCategoriesButton } = useNextLesson({
    navigation,
  });

  const progressStats: Progressstats & { TotalLessons: number } = _progressStats
    ? _progressStats[0]
    : undefined;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const loadPage = async (userId: number, language: number) => {
    await dispatch(clearProgressStatsItems());
    await dispatch(clearCategoryItems());
    await dispatch(clearCategoryPublishedItems());
    await dispatch(clearCategoryPublishedFullItems());
    await dispatch(clearLessonPublishedByCategoryItems());
    await dispatch(
      getProgressStatss({ user: userId, language, offset: 0, limit: 1000 }),
    );
  };

  useFocusEffect(
    React.useCallback(() => {
      if (userId) {
        loadPage(userId, language);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, language, userId]),
  );

  const getLessonsRemaining = () => {
    if (progressStats) {
      return progressStats.TotalLessons! - progressStats.LessonsCompleted!;
    }
    return 0;
  };

  useEffect(() => {
    if (coinList.length) {
      const _coins = coinList.filter((coin: CoinListItem) =>
        coinsToShow.includes(coin.symbol),
      );
      setCoins(_coins);
    }
  }, [coinList]);

  const chartData = [
    progressStats?.LessonsCompleted ?? 0 / progressStats?.TotalLessons,
  ];
  return (
    <WebFadeIn>
      <View style={[pageStyles.page, { backgroundColor: colors.secondary }]}>
        <ScrollView
          scrollEnabled={true}
          nestedScrollEnabled={true}
          style={[pageStyles.container]}
          contentContainerStyle={[pageStyles.body, { paddingBottom: 20 }]}
          showsVerticalScrollIndicator={false}
        >
          <>
            <PageHeader
              style={{ maxWidth: "100%", textAlign: "center" }}
              headerComponents={
                <View
                  style={[
                    pageStyles.title,
                    {
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 20,
                    },
                  ]}
                >
                  <StyledText variant="h1">
                    {text("dashboard.welcome")},{" "}
                  </StyledText>
                  <StyledText
                    variant="h1"
                    style={{ display: "flex" }}
                    textStyle={{
                      color: colors.primary,
                    }}
                  >
                    {name && name.split(" ")?.[0]}!
                  </StyledText>
                </View>
              }
              navigation={navigation}
            />
            <View style={{ alignItems: "center" }}>
              <View style={{ maxWidth: 400 }}>
                {CanNavToNextLesson() ? (
                  navToCategoriesButton()
                ) : (
                  <View style={{ minHeight: 0, justifyContent: "center" }} />
                )}
              </View>

              {coins.length > 0 && (
                <>
                  <StyledText
                    variant="h3"
                    style={[pageStyles.article, { marginVertical: 20 }]}
                  >
                    {text("dashboard.livePrices")}
                  </StyledText>
                  <View style={styles.livePricesWrap}>
                    <ScrollView
                      style={styles.livePrices}
                      horizontal={true}
                      bounces={false}
                      contentContainerStyle={styles.livePricesContainer}
                      showsHorizontalScrollIndicator={true}
                    >
                      {coins.map((coin, key) => (
                        <CoinTile
                          key={key}
                          remoteIconPath={coin.image.small}
                          price={`$ ${formatMoney(
                            coin.market_data.current_price.usd,
                            3,
                          )}`}
                        />
                      ))}
                    </ScrollView>
                    <StyledText
                      variant="body1"
                      style={styles.noteText}
                      textStyle={styles.noteTextStyle}
                    >
                      {text("dashboard.livePricesSignle")}
                    </StyledText>
                  </View>
                </>
              )}

              {_progressStats && (
                <View style={{ alignItems: "center" }}>
                  <ScrollView
                    style={{ minHeight: 165 }}
                    horizontal={true}
                    bounces={false}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={{
                      marginTop: 20,
                    }}
                  >
                    <StatTile
                      IconComponent={
                        !progressStats
                          ? undefined
                          : // eslint-disable-next-line react/no-unstable-nested-components
                            () => <Doughnut data={chartData} />
                      }
                      number={getLessonsRemaining()}
                      title={
                        getLessonsRemaining() === 1
                          ? text("dashboard.lessonRemaining")
                          : text("dashboard.lessonsRemaining")
                      }
                      width={220}
                    />
                    <StatTile
                      number={progressStats.LessonsCompleted!}
                      title={
                        progressStats.LessonsCompleted! > 1 ||
                        progressStats.LessonsCompleted! === 0
                          ? text("dashboard.lessonsCompleted")
                          : text("dashboard.lessonCompleted")
                      }
                      width={119}
                    />
                  </ScrollView>
                  <StyledText
                    variant="h3"
                    style={[
                      pageStyles.article,
                      {
                        marginTop: 20,
                        marginBottom: 20,
                        textAlign: "center",
                        color: colors.text,
                      },
                    ]}
                  >
                    {text("dashboard.categories")}
                  </StyledText>
                </View>
              )}
            </View>
          </>
          <View style={{ height: "auto", minHeight: 130 }}>
            <CategoryPublishedViewListScreen
              navigation={navigation}
              showTitle={false}
              route={route}
              style={[pageStyles.subList]}
              background={false}
            />
          </View>
        </ScrollView>
      </View>
    </WebFadeIn>
  );
};

export default UserDashboardScreen;

import React, { useEffect } from "react";
import { View, Text, Image, Pressable, StyleSheet } from "react-native";
import authStyles from "../layout/auth.styles";
import formStyles from "../../../components/forms/authForm.styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { StyledText } from "src/styles/text";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import {
  error,
  isFulfilled,
  setData,
  SignUpCredentials,
  signUpData,
  signupUser,
} from "src/redux/features/signUp/slice";
import { passwordRegex, passwordStrengthMessage } from "../utils";
import { languagePreference } from "src/redux/features/misc/slice";
import { PageBody } from "src/pages/layout/pageBody";
import useStyles from "../common.styles";
import { CustomButton, CustomTextInput } from "src/uikit";
import { vars } from "src/styles";
import CustomIcon from "src/uikit/CustomIcon/CustomIcon";
import { useTheme } from "@react-navigation/native";
import confStyle from "./signUpConfirmation.styles";

export default function SignUpScreen2({ navigation }: { navigation: any }) {
  const { colors } = useTheme();
  const commonStyles = useStyles();
  const dispatch = useDispatch();
  const err = useSelector(error);
  const signUp1Data = useSelector(signUpData);
  const language = useSelector(languagePreference);
  const fulfilled = useSelector(isFulfilled);
  const passwordRequiredText = "Required";

  const initialValues = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Required"),
      password: Yup.string()
        .required("Required")
        .matches(passwordRegex, passwordStrengthMessage)
        .required(passwordRequiredText),
    }),
    onSubmit: () => {
      const signUpCreds: SignUpCredentials = {
        ...{ language },
        ...(signUp1Data as SignUpCredentials),
        ...formik.values,
      };
      dispatch(setData({ email: formik.values.email }));
      dispatch(signupUser(signUpCreds));
    },
  });

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      formik.handleSubmit();
    }
  };

  useEffect(() => {
    if (fulfilled) {
      navigation.navigate("SignUpConfirmation");
    }
  }, [fulfilled, navigation]);

  return (
    <WebFadeIn>
      <PageBody style={{ margin: "auto", backgroundColor: colors.secondary }}>
        <View style={[authStyles.authPage]} testID="auth-page">
          <View style={confStyle({ bgBanner: colors.primary }).signUpBanner}>
            <Image
              source={require("../../../../assets/be-images/SignUp.png")}
              style={{
                height: 150,
                width: 200,
                resizeMode: "contain",
              }}
            />
          </View>
          <View style={[formStyles.form]}>
            <StyledText
              textStyle={{ color: colors.primary }}
              style={commonStyles.pageTitle}
              variant="h1"
            >
              Sign up
            </StyledText>
            <View style={formStyles.fieldContainer}>
              <CustomTextInput
                testID="email"
                placeholder="Email"
                onChangeText={formik.handleChange("email")}
                onBlur={formik.handleBlur("email")}
                value={formik.values.email}
                onKeyPress={handleKeyDown}
                autoCompleteType={"off"}
                errorMessage={formik.touched.email && formik.errors.email}
                icon={<CustomIcon iconName={"email-outline"} />}
              />
            </View>
            <View style={formStyles.fieldContainer}>
              <CustomTextInput
                testID="password"
                placeholder="Password"
                onChangeText={formik.handleChange("password")}
                onBlur={formik.handleBlur("password")}
                value={formik.values.password}
                onKeyPress={handleKeyDown}
                autoCompleteType={"off"}
                secureTextEntry
                errorMessage={formik.touched.password && formik.errors.password}
                icon={<CustomIcon iconName={"lock-outline"} />}
              />
            </View>

            <View style={[formStyles.fieldContainer, authStyles.spacedButton]}>
              <CustomButton
                onPress={() => formik.handleSubmit()}
                testID={"SignUp"}
                title={"Sign up"}
              />
              <View>
                <Text>
                  {err ? (
                    <>
                      <Text>
                        {err as string} Please choose a different email or{" "}
                      </Text>
                      <Text
                        style={styles.loginNavigator}
                        onPress={() => navigation.navigate("Login")}
                      >
                        Click here
                      </Text>
                      <Text> to login</Text>
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </PageBody>
    </WebFadeIn>
  );
}
const styles = StyleSheet.create({
  loginNavigator: {
    color: vars.primaryColor,
    cursor: "pointer",
  },
});

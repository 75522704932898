/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { TopicResourceCollectionState } from "./topic-resource-collection-state";

import { Topicresource } from "../../../../../../types/domain/flat-types";
const initialState: TopicResourceCollectionState = {
  items: undefined,
  status: "idle",
};

export const getTopicResources = createAsyncThunk(
  "get/topic-resources",
  async (options?: { topic?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-resource?topic=${options?.topic}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createTopicResource = createAsyncThunk(
    "post/topic-resource",
    async (topicResource: Partial<Topicresource>) => {
      const client = await fetchClient();
      const { data } = await client.post(`topic-resource/`, { topicResource });
      return data;
    }
  );


export const updateTopicResource = createAsyncThunk(
  "put/topic-resource",
  async (topicResource: Partial<Topicresource>) => {
    const client = await fetchClient();
    const { data } = await client.put(`topic-resource/`, { topicResource });
    return data;
  }
);

export const getTopicResource = createAsyncThunk(
  "get/topic-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-resource/${id}`);
    return data;
  }
);

export const deleteTopicResource = createAsyncThunk(
  "delete/topic-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`topic-resource/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: TopicResourceCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Topic : undefined;

//   if(currentStateConstraint && actionArgs.topic && Number(actionArgs.topic) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Topic : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const topicResourcesSlice = createSlice({
  name: "topicResources",
  initialState,
  reducers: {
    clearTopicResourceItems(state: TopicResourceCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopicResources.pending, (state: TopicResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getTopicResources.fulfilled, (state: TopicResourceCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getTopicResources.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getTopicResource.pending, (state: TopicResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteTopicResource.pending, (state: TopicResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateTopicResource.pending, (state: TopicResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getTopicResource.fulfilled, (state: TopicResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteTopicResource.fulfilled, (state: TopicResourceCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateTopicResource.fulfilled, (state: TopicResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createTopicResource.pending, (state: TopicResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createTopicResource.fulfilled, (state: TopicResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getTopicResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteTopicResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateTopicResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const topicResourceSelector = (id: number) => (state: AppState) => {
  return state.topicResources?.items?.find((o) => o.Id === id);
}; 

export const topicResourceLoading = createSelector(
  (state: AppState) => state.topicResources.status,
  status => status === 'pending'
);

export const topicResourceErrorSelector = createSelector(
  (state: AppState) => state.topicResources,
  status => status.error
);


export const topicResourcesSelector = (topic?: number) => (state: AppState) => {
  if (!topic) {
    return undefined;
  }
  return state.topicResources?.items?.filter((q) => q.Topic === topic);
}; 

export const topicResourcesLoading = createSelector(
  (state: AppState) => state.topicResources.status,
  status => status === 'pending'
);

export const topicResourcesErrorSelector = createSelector(
  (state: AppState) => state.topicResources,
  status => status.error
);

export const { clearTopicResourceItems } = topicResourcesSlice.actions;

export default topicResourcesSlice.reducer;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { CategoryAssignmentCollectionState } from "./category-assignment-collection-state";

import { Categoryassignment } from "../../../../../../types/domain/flat-types";
const initialState: CategoryAssignmentCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCategoryAssignments = createAsyncThunk(
  "get/category-assignments",
  async (options?: { category?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-assignment?category=${options?.category}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createCategoryAssignment = createAsyncThunk(
    "post/category-assignment",
    async (categoryAssignment: Partial<Categoryassignment>) => {
      const client = await fetchClient();
      const { data } = await client.post(`category-assignment/`, { categoryAssignment });
      return data;
    }
  );


export const updateCategoryAssignment = createAsyncThunk(
  "put/category-assignment",
  async (categoryAssignment: Partial<Categoryassignment>) => {
    const client = await fetchClient();
    const { data } = await client.put(`category-assignment/`, { categoryAssignment });
    return data;
  }
);

export const getCategoryAssignment = createAsyncThunk(
  "get/category-assignment",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-assignment/${id}`);
    return data;
  }
);

export const deleteCategoryAssignment = createAsyncThunk(
  "delete/category-assignment",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`category-assignment/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: CategoryAssignmentCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Category : undefined;

//   if(currentStateConstraint && actionArgs.category && Number(actionArgs.category) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Category : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const categoryAssignmentsSlice = createSlice({
  name: "categoryAssignments",
  initialState,
  reducers: {
    clearCategoryAssignmentItems(state: CategoryAssignmentCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryAssignments.pending, (state: CategoryAssignmentCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCategoryAssignments.fulfilled, (state: CategoryAssignmentCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getCategoryAssignments.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCategoryAssignment.pending, (state: CategoryAssignmentCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteCategoryAssignment.pending, (state: CategoryAssignmentCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateCategoryAssignment.pending, (state: CategoryAssignmentCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCategoryAssignment.fulfilled, (state: CategoryAssignmentCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteCategoryAssignment.fulfilled, (state: CategoryAssignmentCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateCategoryAssignment.fulfilled, (state: CategoryAssignmentCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createCategoryAssignment.pending, (state: CategoryAssignmentCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createCategoryAssignment.fulfilled, (state: CategoryAssignmentCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getCategoryAssignment.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteCategoryAssignment.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateCategoryAssignment.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const categoryAssignmentSelector = (id: number) => (state: AppState) => {
  return state.categoryAssignments?.items?.find((o) => o.Id === id);
}; 

export const categoryAssignmentLoading = createSelector(
  (state: AppState) => state.categoryAssignments.status,
  status => status === 'pending'
);

export const categoryAssignmentErrorSelector = createSelector(
  (state: AppState) => state.categoryAssignments,
  status => status.error
);


export const categoryAssignmentsSelector = (category?: number) => (state: AppState) => {
  if (!category) {
    return undefined;
  }
  return state.categoryAssignments?.items?.filter((q) => q.Category === category);
}; 

export const categoryAssignmentsLoading = createSelector(
  (state: AppState) => state.categoryAssignments.status,
  status => status === 'pending'
);

export const categoryAssignmentsErrorSelector = createSelector(
  (state: AppState) => state.categoryAssignments,
  status => status.error
);

export const { clearCategoryAssignmentItems } = categoryAssignmentsSlice.actions;

export default categoryAssignmentsSlice.reducer;

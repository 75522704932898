import { StyleSheet } from "react-native";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingHorizontal: 10,
    paddingVertical: 10,
    justifyContent: "flex-end",
    borderTopColor: "#e4e4e4",
    borderTopWidth: 1,
  },
  textStyle: {
    fontSize: 16,
    paddingHorizontal: 10,
    paddingVertical: 6,
    color: "#0074cc",
  },
  input: {
    minHeight: 50,
    width: "90%",
    paddingHorizontal: 10,
  },
  inputContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 18,
    width: "100%",
    // maxWidth: 400,
    borderRadius: 25,
    borderWidth: 2,
  },
  primaryInput: {
    backgroundColor: vars.white,
    borderColor: vars.white,
  },
  errorView: {
    width: "20%",
  },
  errorText: {
    fontSize: 12,
    color: vars.textRed,
    paddingHorizontal: 4,
  },
  multiline: {
    paddingTop: 11,
  },
});

export default styles;

import React from "react";
import { Path } from "react-native-svg";

const VerifyFilled: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M22.4558 9.67994C22.1751 9.45283 21.708 9.07399 21.6495 8.89446C21.59 8.70799 21.7437 8.10799 21.836 7.75094C22.0571 6.8891 22.3328 5.81697 21.715 4.97299C21.1001 4.13496 19.9942 4.06155 19.1056 4.00202C18.7377 3.97624 18.1208 3.93555 17.9651 3.82249C17.8074 3.70844 17.5703 3.12429 17.4285 2.77418C17.0784 1.90638 16.6798 0.922522 15.7068 0.609116C14.7389 0.292757 13.8542 0.8531 13.0756 1.34599C12.7325 1.56218 12.2158 1.88951 11.9996 1.88951C11.7923 1.88951 11.2815 1.55726 10.9424 1.33708C10.1936 0.8501 9.25734 0.2471 8.27053 0.58235C7.29956 0.912632 6.90488 1.89049 6.55477 2.75135C6.41395 3.09846 6.17986 3.67765 6.01922 3.79568C5.86252 3.91169 5.23472 3.95337 4.85981 3.97816C3.92259 4.04163 2.86041 4.11307 2.26336 4.95804C1.67723 5.78815 1.94002 6.81166 2.17209 7.71518C2.26336 8.07419 2.41805 8.67522 2.3625 8.85471C2.30302 9.02627 1.84083 9.40615 1.56511 9.63326C0.867797 10.2086 0 10.9236 0 11.9948C0 13.0589 0.850969 13.7661 1.53427 14.3334C1.8308 14.5793 2.27808 14.9512 2.33859 15.1357C2.39808 15.3182 2.24339 15.9113 2.15016 16.2654C1.92403 17.1351 1.64138 18.2182 2.28305 19.0691C2.90887 19.8963 4.00973 19.9696 4.89539 20.0291C5.26336 20.0549 5.87925 20.0946 6.03595 20.2087C6.19266 20.3228 6.42872 20.9059 6.57052 21.256C6.92161 22.1238 7.3193 23.1077 8.29322 23.4221C8.48859 23.4865 8.682 23.5143 8.87044 23.5143C9.61627 23.5143 10.3045 23.0779 10.9254 22.6871C11.2675 22.471 11.7833 22.1436 11.9995 22.1436C12.2107 22.1436 12.7185 22.467 13.0538 22.6812C13.7976 23.1543 14.7259 23.7474 15.7107 23.4101C16.6807 23.0799 17.0754 22.103 17.4255 21.2402C17.5663 20.894 17.8004 20.3139 17.961 20.1958C18.1187 20.0798 18.7425 20.0381 19.1155 20.0133C20.0458 19.9499 21.101 19.8795 21.707 19.0473C22.317 18.2113 22.0551 17.1768 21.8241 16.2654C21.7338 15.9093 21.5831 15.3153 21.6376 15.1368C21.6971 14.9652 22.1593 14.5853 22.434 14.3582C23.1322 13.783 24 13.0669 24 11.9958C24 10.9336 23.1431 10.2383 22.4558 9.67994Z"
        fill={props?.iconColor}
      />
      <Path
        d="M16.4349 8.25906C16.0124 7.906 15.3885 7.96352 15.0385 8.38305L10.6161 13.6702L8.73471 11.7898C8.34691 11.402 7.7201 11.402 7.33235 11.7898C6.94455 12.1776 6.94455 12.8044 7.33235 13.1922L9.98041 15.8402C10.1659 16.0277 10.4188 16.1308 10.6816 16.1308C10.6955 16.1308 10.7104 16.1308 10.7243 16.1289C11.0039 16.117 11.2628 15.988 11.4413 15.7748L16.5588 9.65547C16.9099 9.235 16.8554 8.61016 16.4349 8.25906Z"
        fill="#F7F8FB"
      />
    </>
  );
};

export default VerifyFilled;

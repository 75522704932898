import React from "react";
import { Path } from "react-native-svg";

const Plus: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M5 10.8887C4.44772 10.8887 4 11.3364 4 11.8887C4 12.441 4.44772 12.8887 5 12.8887V10.8887ZM18.7767 12.8887C19.329 12.8887 19.7767 12.441 19.7767 11.8887C19.7767 11.3364 19.329 10.8887 18.7767 10.8887V12.8887ZM5 12.8887L18.7767 12.8887V10.8887L5 10.8887V12.8887Z"
        fill={props?.iconColor}
      />
      <Path
        d="M10.8884 5.00049C10.8884 4.4482 11.3361 4.00049 11.8884 4.00049C12.4407 4.00049 12.8884 4.4482 12.8884 5.00049H10.8884ZM12.8884 18.7772C12.8884 19.3295 12.4407 19.7772 11.8884 19.7772C11.3361 19.7772 10.8884 19.3295 10.8884 18.7772H12.8884ZM12.8884 5.00049L12.8884 18.7772H10.8884L10.8884 5.00049H12.8884Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default Plus;

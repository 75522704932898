/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { QuizAttemptResultCollectionState } from "./quiz-attempt-result-collection-state";

import { Quizattemptresult } from "../../../../../../types/domain/flat-types";
const initialState: QuizAttemptResultCollectionState = {
  items: undefined,
  status: "idle",
};

export const getQuizAttemptResults = createAsyncThunk(
  "get/quiz-attempt-results",
  async (options?: { quizattempt?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-attempt-result?quizattempt=${options?.quizattempt}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createQuizAttemptResult = createAsyncThunk(
    "post/quiz-attempt-result",
    async (quizAttemptResult: Partial<Quizattemptresult>) => {
      const client = await fetchClient();
      const { data } = await client.post(`quiz-attempt-result/`, { quizAttemptResult });
      return data;
    }
  );


export const updateQuizAttemptResult = createAsyncThunk(
  "put/quiz-attempt-result",
  async (quizAttemptResult: Partial<Quizattemptresult>) => {
    const client = await fetchClient();
    const { data } = await client.put(`quiz-attempt-result/`, { quizAttemptResult });
    return data;
  }
);

export const getQuizAttemptResult = createAsyncThunk(
  "get/quiz-attempt-result",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`quiz-attempt-result/${id}`);
    return data;
  }
);

export const deleteQuizAttemptResult = createAsyncThunk(
  "delete/quiz-attempt-result",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`quiz-attempt-result/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: QuizAttemptResultCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].QuizAttempt : undefined;

//   if(currentStateConstraint && actionArgs.quizattempt && Number(actionArgs.quizattempt) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].QuizAttempt : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const quizAttemptResultsSlice = createSlice({
  name: "quizAttemptResults",
  initialState,
  reducers: {
    clearQuizAttemptResultItems(state: QuizAttemptResultCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizAttemptResults.pending, (state: QuizAttemptResultCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getQuizAttemptResults.fulfilled, (state: QuizAttemptResultCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getQuizAttemptResults.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getQuizAttemptResult.pending, (state: QuizAttemptResultCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteQuizAttemptResult.pending, (state: QuizAttemptResultCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateQuizAttemptResult.pending, (state: QuizAttemptResultCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getQuizAttemptResult.fulfilled, (state: QuizAttemptResultCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteQuizAttemptResult.fulfilled, (state: QuizAttemptResultCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateQuizAttemptResult.fulfilled, (state: QuizAttemptResultCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createQuizAttemptResult.pending, (state: QuizAttemptResultCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createQuizAttemptResult.fulfilled, (state: QuizAttemptResultCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getQuizAttemptResult.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteQuizAttemptResult.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateQuizAttemptResult.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const quizAttemptResultSelector = (id: number) => (state: AppState) => {
  return state.quizAttemptResults?.items?.find((o) => o.Id === id);
}; 

export const quizAttemptResultLoading = createSelector(
  (state: AppState) => state.quizAttemptResults.status,
  status => status === 'pending'
);

export const quizAttemptResultErrorSelector = createSelector(
  (state: AppState) => state.quizAttemptResults,
  status => status.error
);


export const quizAttemptResultsSelector = (quizAttempt?: number) => (state: AppState) => {
  if (!quizAttempt) {
    return undefined;
  }
  return state.quizAttemptResults?.items?.filter((q) => q.QuizAttempt === quizAttempt);
}; 

export const quizAttemptResultsLoading = createSelector(
  (state: AppState) => state.quizAttemptResults.status,
  status => status === 'pending'
);

export const quizAttemptResultsErrorSelector = createSelector(
  (state: AppState) => state.quizAttemptResults,
  status => status.error
);

export const { clearQuizAttemptResultItems } = quizAttemptResultsSlice.actions;

export default quizAttemptResultsSlice.reducer;

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { CategoryPublishedCollectionState } from "./category-published-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Categorypublished } from "../../../../../../types/domain/flat-types";
const initialState: CategoryPublishedCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCategoryPublisheds = createAsyncThunk(
  "get/category-publisheds",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-published?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchCategoryPublisheds = createAsyncThunk(
  "get/category-publisheds",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`category-published-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getCategoryPublished = createAsyncThunk(
  "get/category-published",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`category-published/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const categoryPublishedsSlice = createSlice({
  name: "categoryPublisheds",
  initialState,
  reducers: {
    clearCategoryPublishedItems(state: CategoryPublishedCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryPublisheds.pending || searchCategoryPublisheds.pending, (state: CategoryPublishedCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCategoryPublisheds.fulfilled || searchCategoryPublisheds.fulfilled, (state: CategoryPublishedCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getCategoryPublisheds.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCategoryPublished.pending, (state: CategoryPublishedCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCategoryPublished.fulfilled, (state: CategoryPublishedCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getCategoryPublished.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const categoryPublishedSelector = (id: number) => (state: AppState) => {
  return state.categoryPublisheds?.items?.find((o) => o.Id === id);
}; 

export const categoryPublishedLoading = createSelector(
  (state: AppState) => state.categoryPublisheds.status,
  status => status === 'pending'
);

export const categoryPublishedErrorSelector = createSelector(
  (state: AppState) => state.categoryPublisheds,
  status => status.error
);


export const categoryPublishedsSelector = createSelector(
  (state: AppState) => state.categoryPublisheds,
  state => state.items
);

export const categoryPublishedsLoading = createSelector(
  (state: AppState) => state.categoryPublisheds.status,
  status => status === 'pending'
);

export const categoryPublishedsErrorSelector = createSelector(
  (state: AppState) => state.categoryPublisheds,
  status => status.error
);

export const { clearCategoryPublishedItems } = categoryPublishedsSlice.actions;

export default categoryPublishedsSlice.reducer;

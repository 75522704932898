/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { TopicCollectionState } from "./topic-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Topic } from "../../../../../../types/domain/flat-types";
const initialState: TopicCollectionState = {
  items: undefined,
  status: "idle",
};

export const getTopics = createAsyncThunk(
  "get/topics",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchTopics = createAsyncThunk(
  "get/topics",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`topic-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);



  export const createTopic = createAsyncThunk(
    "post/topic",
    async (topic: Partial<Topic>) => {
      const client = await fetchClient();
      const { data } = await client.post(`topic/`, { topic });
      return data;
    }
  );


export const updateTopic = createAsyncThunk(
  "put/topic",
  async (topic: Partial<Topic>) => {
    const client = await fetchClient();
    const { data } = await client.put(`topic/`, { topic });
    return data;
  }
);

export const getTopic = createAsyncThunk(
  "get/topic",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic/${id}`);
    return data;
  }
);

export const deleteTopic = createAsyncThunk(
  "delete/topic",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`topic/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const topicsSlice = createSlice({
  name: "topics",
  initialState,
  reducers: {
    clearTopicItems(state: TopicCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopics.pending || searchTopics.pending, (state: TopicCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getTopics.fulfilled || searchTopics.fulfilled, (state: TopicCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getTopics.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getTopic.pending, (state: TopicCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteTopic.pending, (state: TopicCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateTopic.pending, (state: TopicCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getTopic.fulfilled, (state: TopicCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteTopic.fulfilled, (state: TopicCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateTopic.fulfilled, (state: TopicCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createTopic.pending, (state: TopicCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createTopic.fulfilled, (state: TopicCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getTopic.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteTopic.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateTopic.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const topicSelector = (id: number) => (state: AppState) => {
  return state.topics?.items?.find((o) => o.Id === id);
}; 

export const topicLoading = createSelector(
  (state: AppState) => state.topics.status,
  status => status === 'pending'
);

export const topicErrorSelector = createSelector(
  (state: AppState) => state.topics,
  status => status.error
);


export const topicsSelector = createSelector(
  (state: AppState) => state.topics,
  state => state.items
);

export const topicsLoading = createSelector(
  (state: AppState) => state.topics.status,
  status => status === 'pending'
);

export const topicsErrorSelector = createSelector(
  (state: AppState) => state.topics,
  status => status.error
);

export const { clearTopicItems } = topicsSlice.actions;

export default topicsSlice.reducer;

import { StyleSheet } from "react-native";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  userDashboard: {},
  livePricesWrap: {
    position: "relative",
    backgroundColor: vars.white,
    borderRadius: 16,
  },
  livePrices: {
    minWidth: 200,
    width: "100%",
    borderRadius: 16,
  },
  livePricesContainer: {
    flexDirection: "row",
    minWidth: "100%",
    justifyContent: "space-between",
  },
  noteText: {
    position: "absolute",
    bottom: 0,
    right: 20,
  },
  noteTextStyle: {
    fontSize: 9,
  },
});

export default styles;

import React, { useCallback, useEffect, useState } from "react";
import { BackHandler, Image, Platform, View } from "react-native";

import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import usePageStyles from "src/pages/layout/page.styles";
import { PageBody } from "src/pages/layout/pageBody";
import { PageHeader } from "src/pages/layout/pageheader";
import useStyles from "./quiz.styles";
import { useDispatch, useSelector } from "react-redux";
import { quizAttemptsSelector } from "src/redux/domain/features/quizAttempt/collection-slice";
import { useFocusEffect } from "@react-navigation/native";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { Quizattempt } from "../../../../../types/domain/flat-types";
import _ from "lodash";
import { StyledText } from "src/styles/text";
import { CustomButton } from "src/uikit";
import { setProgress } from "src/redux/features/misc/slice";
import {
  clearProgressStatsItems,
  getProgressStatss,
} from "src/redux/domain/features/progressStats/collection-slice";
import { useNextLesson } from "../useNextLesson/useNextLesson";
import { useTranslation } from "src/translations/useTranslation";
import { clearCategoryItems } from "../../../redux/domain/features/category/collection-slice";
import { clearCategoryPublishedItems } from "../../../redux/domain/features/categoryPublished/collection-slice";
import { clearCategoryPublishedFullItems } from "../../../redux/domain/features/categoryPublishedFull/collection-slice";

const QuizResultScreen = ({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) => {
  const pageStyles = usePageStyles();
  const styles = useStyles();
  const { userId, language } = useUserInfo();
  const { quizPublishedId, lessonPublishedFullId } = route.params;
  const [quizAttempt, setQuizAttempt] = useState<Quizattempt>();
  const dispatch = useDispatch();
  const { text } = useTranslation();

  const { CanNavToNextLesson, nextLessonButton } = useNextLesson({
    navigation,
  });

  //   const loading = useSelector(quizPublishedLoading);

  const quizAttempts = useSelector(quizAttemptsSelector(userId));

  const getLatestAttemptAtThisQuiz = useCallback(() => {
    const attempts = _.orderBy(
      quizAttempts?.filter((qa) => qa.Quiz === quizPublishedId),
      "StartDate",
      "desc",
    ) as Quizattempt[];
    return attempts[0];
  }, [quizAttempts, quizPublishedId]);

  useFocusEffect(() => {
    setQuizAttempt(getLatestAttemptAtThisQuiz());
    dispatch(clearProgressStatsItems());
    dispatch(clearCategoryItems());
    dispatch(clearCategoryPublishedItems());
    dispatch(clearCategoryPublishedFullItems());
  });

  useFocusEffect(
    React.useCallback(() => {
      if (userId) {
        dispatch(getProgressStatss({ user: userId, language }));
      }
    }, [dispatch, language, userId]),
  );

  useEffect(() => {
    const handleBack = () => {
      navigation.replace("LessonPublishedView", { lessonPublishedFullId });
      return true;
    };

    //android only
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      handleBack,
    );

    if (Platform.OS === "web") {
      window.addEventListener("popstate", handleBack, false);
    }

    return () => {
      if (Platform.OS === "web") {
        window.removeEventListener("popstate", handleBack, false);
      }
      backHandler.remove();
    };
  }, [lessonPublishedFullId, navigation, route]);

  const pass = (quizAttempt as any)?.result.pass;
  const tokens = (quizAttempt as any)?.result.tokensAwarded;
  const previousPass = (quizAttempt as any)?.result.previousPass;

  const pressAction = (_pass: boolean) => {
    if (_pass) {
      //todo: depends on how the lesson started!
      //get the next lesson in the category or back to lesson list (in category)
      navigation.replace("Categories");
    } else {
      navigation.replace("LessonPublishedView", { lessonPublishedFullId });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      dispatch(
        setProgress({
          show: false,
          total: 0,
          progress: 0,
        }),
      );
    }, [dispatch]),
  );

  return (
    <WebFadeIn>
      <View style={[pageStyles.page]}>
        <PageHeader
          style={styles.quizPageHeader}
          navigation={navigation}
          hideGoBack={true}
        />
        <PageBody>
          <View style={styles.quizResultBody}>
            {!previousPass && (
              <>
                {pass ? (
                  <Image
                    style={{ height: 235, width: 235 }}
                    source={require("../../../../assets/be-images/QuizWon.png")}
                  />
                ) : (
                  <Image
                    style={{ height: 235, width: 235 }}
                    source={require("../../../../assets/be-images/QuizLost.png")}
                  />
                )}
                <StyledText variant="h1" style={styles.resultText}>
                  {pass
                    ? `${text("quizViewPage.won")}`
                    : `${text("quizViewPage.lost")}`}
                </StyledText>
                {pass && (
                  <StyledText
                    variant="h2"
                    style={styles.tokenText}
                    textStyle={styles.textStyle}
                  >
                    {tokens} QC
                  </StyledText>
                )}
              </>
            )}
            {previousPass && (
              <>
                {pass ? (
                  <Image
                    style={{ height: 235, width: 235 }}
                    source={require("../../../../assets/be-images/QuizWon.png")}
                  />
                ) : (
                  <Image
                    style={{ height: 235, width: 235 }}
                    source={require("../../../../assets/be-images/QuizLost.png")}
                  />
                )}
                <StyledText variant="h2" style={styles.resultText}>
                  {pass
                    ? text("quizViewPage.passed")
                    : text("quizViewPage.lost")}
                </StyledText>
                {pass && (
                  <StyledText
                    variant="body1"
                    style={styles.previousAward}
                    textStyle={styles.textStyle}
                  >
                    {text("quizViewPage.token")}
                  </StyledText>
                )}
              </>
            )}
            <StyledText
              variant="body1"
              style={styles.nextStepText}
              textStyle={styles.textStyle}
            >
              {pass
                ? text("quizViewPage.passedSuccess")
                : text("quizViewPage.passedFailure")}
            </StyledText>
            {pass ? (
              <View style={styles.confirmButtonContainer}>
                {CanNavToNextLesson() ? (
                  nextLessonButton()
                ) : (
                  <CustomButton
                    title={text("quizViewPage.nextLessonBtn")}
                    onPress={() => pressAction(pass)}
                  />
                )}
              </View>
            ) : (
              <View style={styles.confirmButtonContainer}>
                <CustomButton
                  title={text("quizViewPage.articleBtn")}
                  onPress={() => pressAction(pass)}
                />
              </View>
            )}
          </View>
          {/* <StyledText variant="body1" style={styles.otherLessons}>
            Other lessons ... lessons in the same category ?
          </StyledText> */}
        </PageBody>
      </View>
    </WebFadeIn>
  );
};

export default QuizResultScreen;

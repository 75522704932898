import React, { useState } from "react";
import Slider from "@react-native-community/slider";
import { useTheme } from "@react-navigation/native";
import { Image, View, Dimensions } from "react-native";
import { CustomCheckbox } from "src/uikit";
import imgData from "./imgData";

export default function Recaptcha(props: any) {
  const { onVarified } = props;
  const { colors } = useTheme();
  const [xaxis, setXaxis] = useState(0);
  const [isShow, showCaptcha] = useState(false);
  const [isCaptchaVerified, setCaptcha] = useState(false);
  const [captchaNum, setNewCaptcha] = useState(0);
  const currentCaptcha = imgData[captchaNum];

  function onChangeSlider(value: any) {
    setXaxis(value);
  }

  function onShowCaptch() {
    onVarified(false);
    setCaptcha(false);
    showCaptcha(!isShow);
  }

  function onCompleteSlider(value: any) {
    const dimensions = Dimensions.get("window");
    let isCaptchaSolved = false;
    currentCaptcha.matchingPair.map(
      ({ deviceWidth, matchMinValue, matchMaxValue }) => {
        if (
          dimensions.width >= deviceWidth &&
          value >= matchMinValue &&
          value <= matchMaxValue
        ) {
          isCaptchaSolved = true;
          return;
        }
      },
    );
    if (isCaptchaSolved) {
      setValues();
    } else {
      setNewCaptcha(captchaNum + 1 > 5 ? 0 : captchaNum + 1);
      onVarified(false);
      setCaptcha(false);
      setXaxis(0);
    }
  }

  function setValues() {
    onVarified(true);
    setCaptcha(true);
    setXaxis(0);
  }
  const dimensions = Dimensions.get("window");
  let left = xaxis;
  if (left < 0) {
    left = 0;
  }
  if (dimensions.width - 75 < left) {
    left = dimensions.width - 75;
  }

  return (
    <>
      {isShow && !isCaptchaVerified && (
        <>
          <View
            style={{
              backgroundColor: "white",
              alignItems: "center",
              paddingBottom: 9,
              borderRadius: 4,
              maxHeight: 200,
              position: "relative",
              marginBottom: 3,
              width: "100%",
            }}
          >
            <View style={{ width: "100%", height: 200 }}>
              <Image
                source={currentCaptcha?.puzzleUrl}
                style={{
                  height: "100%",
                  width: "100%",
                  resizeMode: "contain",
                }}
              />
            </View>
            <View
              style={{
                position: "absolute",
                top: 0,
                left: left,
                height: 200,
                width: captchaNum === 0 ? "19%" : "24%",
              }}
            >
              <Image
                source={currentCaptcha?.puzzlePieceUrl}
                style={{
                  height: "100%",
                  width: "100%",
                  resizeMode: "contain",
                }}
              />
            </View>
          </View>
          <View style={{ width: "100%", backgroundColor: "#fff" }}>
            <Slider
              style={{ width: "100%", height: 40 }}
              minimumValue={0}
              maximumValue={325}
              thumbTintColor={colors.primary}
              minimumTrackTintColor={colors.primary}
              maximumTrackTintColor={colors.secondary}
              onValueChange={onChangeSlider}
              onSlidingComplete={onCompleteSlider}
              value={left}
            />
          </View>
        </>
      )}
      <View>
        <CustomCheckbox
          selectedItems={
            isCaptchaVerified ? [{ Id: 1, Value: "I am human" }] : []
          }
          onSelect={onShowCaptch}
          items={[{ Id: 1, Value: "I am human" }]}
          style={{ backgroundColor: "#f8f8f8" }}
        />
      </View>
    </>
  );
}

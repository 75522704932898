import React, { useState } from "react";
import { Snackbar } from "react-native-paper";
import { StyledText } from "src/styles/text";
import styles from "./snackBar.styles";

export const useSnackBar = () => {
  const [visible, setVisible] = useState(false);
  const [ok, setOK] = useState(true);
  const [_message, setMessage] = useState("");

  const showSnackOk = ({ message }: { message: string }) => {
    setMessage(message);
    setOK(true);
    setVisible(true);
  };

  const showSnackError = ({ message }: { message: string }) => {
    setMessage(message);
    setOK(false);
    setVisible(true);
  };

  const Snack = () => {
    return (
      <Snackbar
        testID="snackbar"
        visible={visible}
        onDismiss={() => setVisible(false)}
        duration={2000}
        // action={{
        //   label: "Undo",
        //   onPress: () => {
        //     // Do something
        //   },
        // }}
        style={[styles.snack, ok ? styles.ok : styles.error]}
      >
        {/* could put animated view in here... */}
        <StyledText
          variant="snackMessage"
          style={styles.text}
          testID="snackMessage"
        >
          {_message}
        </StyledText>
      </Snackbar>
    );
  };

  return {
    Snack,
    showSnackOk,
    showSnackError,
  };
};

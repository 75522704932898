import {
  Dimensions,
  Platform,
  StyleSheet,
  useWindowDimensions,
} from "react-native";
import { maxPageWidth } from "src/components/forms/form.styles";
import {
  avgDeviceSize,
  mobileBreak,
} from "src/navigation/header/drawerNavOptions";
import { vars } from "src/styles";

export const staticCommonStyles = StyleSheet.create({
  container: {
    backgroundColor: vars.appBackground,
  },
});

const isLandscape = () => {
  const dim = Dimensions.get("window");
  return dim.width >= dim.height;
};

const getHeaderHeight = (_windowWidth: number) => {
  if (_windowWidth > avgDeviceSize) {
    return 75;
  }

  if (isLandscape()) {
    if (Platform.OS === "android") {
      return 100;
    }
    if (Platform.OS === "ios") {
      return 80;
    }
    return 80;
  }

  if (Platform.OS === "android") {
    return 120;
  }
  if (Platform.OS === "ios") {
    return 120;
  }
  return 80;
};

const useStyles = () => {
  const { width: _windowWidth } = useWindowDimensions();

  const headerLeftRightColWidth = _windowWidth > avgDeviceSize ? 120 : 60;
  const menuBarMaxWidth = (windowWidth: number) => {
    if (windowWidth - headerLeftRightColWidth * 2 > maxPageWidth) {
      return maxPageWidth;
    } else if (
      windowWidth > maxPageWidth &&
      windowWidth < maxPageWidth + headerLeftRightColWidth * 2 &&
      windowWidth < avgDeviceSize
    ) {
      return windowWidth - headerLeftRightColWidth * 3;
    } else if (
      windowWidth > maxPageWidth &&
      windowWidth < maxPageWidth + headerLeftRightColWidth * 2 &&
      windowWidth > avgDeviceSize
    ) {
      return windowWidth - headerLeftRightColWidth * 2;
    } else if (windowWidth < maxPageWidth) {
      return windowWidth - headerLeftRightColWidth * 2;
    }

    return undefined;
  };
  return StyleSheet.create({
    container: {
      backgroundColor: "red",
    },
    headerPadding: {
      paddingHorizontal: 10,
    },
    backButton: {
      maxWidth: _windowWidth > mobileBreak ? 100 : 40,
      maxHeight: 40,
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      alignContent: "center",
      borderWidth: 0,
      paddingHorizontal: 6,
    },
    webHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      paddingTop: 26,
      marginLeft: 50,
    },
    imageContainer: {
      marginBottom: 20,
      justifyContent: "center",
      alignItems: "center",
    },
    pageTitle: {
      marginBottom: 30,
      alignSelf: "center",
    },
    //do NOT use any height: 100% in headers - causes screen jump
    header: {
      height: getHeaderHeight(_windowWidth),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
      paddingTop: Platform.OS === "android" ? 30 : 0,
      minHeight: 55,
      // shadowColor: "#000",
      // shadowOffset: { width: 1, height: 1 },
      // shadowOpacity: 0.1,
      // shadowRadius: 3,
      // elevation: 5,
      // borderWidth: 1,
    },
    headerLeftColumn: {
      width: _windowWidth > avgDeviceSize ? headerLeftRightColWidth : "auto",
      paddingLeft: _windowWidth > avgDeviceSize ? 20 : 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 15,
    },
    headerCenterColumn: {
      maxWidth: menuBarMaxWidth(_windowWidth),
      width: _windowWidth > maxPageWidth ? "100%" : "",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: _windowWidth > avgDeviceSize ? "100%" : "100%",
      flexGrow: 1,
    },
    headerRightColumn: {
      width: _windowWidth > avgDeviceSize ? headerLeftRightColWidth : "auto",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: _windowWidth > avgDeviceSize ? 0 : 12, //padding in the header doesn't work on ios - instead use margin
      height: _windowWidth > avgDeviceSize ? "100%" : undefined,
    },
    forgottenPassword: {
      fontFamily: "Poppins_400Regular",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 24,
      color: vars.primaryColor,
    },
  });
};

export default useStyles;

import { StyleSheet } from "react-native";
import { vars } from "src/styles";

const styles = StyleSheet.create({
  checkboxes: {
    paddingVertical: 10,
    maxWidth: 400,
    minWidth: 100,
  },
  checkbox: {
    paddingVertical: 6,
  },
  checkboxBorder: {
    borderColor: vars.primaryColor,
  },
  checkboxCheckedBorder: {
    borderColor: "#EEF1F5",
  },
  textStyle: {
    color: vars.black,
  },
  textChecked: {
    color: vars.primaryColor,
  },
});

export default styles;

import React from "react";
import { Path } from "react-native-svg";

const BookOutline: React.FunctionComponent<{
  iconColor?: string;
}> = (props) => {
  return (
    <>
      <Path
        d="M18 1H6C5.20435 1 4.44129 1.31607 3.87868 1.87868C3.31607 2.44129 3 3.20435 3 4V20C3 20.7957 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H18C18.5304 23 19.0391 22.7893 19.4142 22.4142C19.7893 22.0392 20 21.5304 20 21V19C20.2652 19 20.5196 18.8947 20.7071 18.7071C20.8946 18.5196 21 18.2652 21 18V4C21 3.20435 20.6839 2.44129 20.1213 1.87868C19.5587 1.31607 18.7956 1 18 1ZM6 3H18C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8946 3.48043 19 3.73479 19 4V17H6C5.65886 17.0027 5.32067 17.0636 5 17.18V4C5 3.73479 5.10536 3.48043 5.29289 3.29289C5.48043 3.10536 5.73478 3 6 3ZM18 21H6C5.73478 21 5.48043 20.8947 5.29289 20.7071C5.10536 20.5196 5 20.2652 5 20C5 19.7348 5.10536 19.4804 5.29289 19.2929C5.48043 19.1054 5.73478 19 6 19H18V21Z"
        fill={props?.iconColor}
      />
    </>
  );
};

export default BookOutline;

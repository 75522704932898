/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { CustomerEmailResourceCollectionState } from "./customer-email-resource-collection-state";

import { Customeremailresource } from "../../../../../../types/domain/flat-types";
const initialState: CustomerEmailResourceCollectionState = {
  items: undefined,
  status: "idle",
};

export const getCustomerEmailResources = createAsyncThunk(
  "get/customer-email-resources",
  async (options?: { customeremail?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email-resource?customeremail=${options?.customeremail}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createCustomerEmailResource = createAsyncThunk(
    "post/customer-email-resource",
    async (customerEmailResource: Partial<Customeremailresource>) => {
      const client = await fetchClient();
      const { data } = await client.post(`customer-email-resource/`, { customerEmailResource });
      return data;
    }
  );


export const updateCustomerEmailResource = createAsyncThunk(
  "put/customer-email-resource",
  async (customerEmailResource: Partial<Customeremailresource>) => {
    const client = await fetchClient();
    const { data } = await client.put(`customer-email-resource/`, { customerEmailResource });
    return data;
  }
);

export const getCustomerEmailResource = createAsyncThunk(
  "get/customer-email-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`customer-email-resource/${id}`);
    return data;
  }
);

export const deleteCustomerEmailResource = createAsyncThunk(
  "delete/customer-email-resource",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`customer-email-resource/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: CustomerEmailResourceCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].CustomerEmail : undefined;

//   if(currentStateConstraint && actionArgs.customeremail && Number(actionArgs.customeremail) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].CustomerEmail : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const customerEmailResourcesSlice = createSlice({
  name: "customerEmailResources",
  initialState,
  reducers: {
    clearCustomerEmailResourceItems(state: CustomerEmailResourceCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerEmailResources.pending, (state: CustomerEmailResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getCustomerEmailResources.fulfilled, (state: CustomerEmailResourceCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getCustomerEmailResources.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getCustomerEmailResource.pending, (state: CustomerEmailResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteCustomerEmailResource.pending, (state: CustomerEmailResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateCustomerEmailResource.pending, (state: CustomerEmailResourceCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getCustomerEmailResource.fulfilled, (state: CustomerEmailResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteCustomerEmailResource.fulfilled, (state: CustomerEmailResourceCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateCustomerEmailResource.fulfilled, (state: CustomerEmailResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createCustomerEmailResource.pending, (state: CustomerEmailResourceCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createCustomerEmailResource.fulfilled, (state: CustomerEmailResourceCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getCustomerEmailResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteCustomerEmailResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateCustomerEmailResource.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const customerEmailResourceSelector = (id: number) => (state: AppState) => {
  return state.customerEmailResources?.items?.find((o) => o.Id === id);
}; 

export const customerEmailResourceLoading = createSelector(
  (state: AppState) => state.customerEmailResources.status,
  status => status === 'pending'
);

export const customerEmailResourceErrorSelector = createSelector(
  (state: AppState) => state.customerEmailResources,
  status => status.error
);


export const customerEmailResourcesSelector = (customerEmail?: number) => (state: AppState) => {
  if (!customerEmail) {
    return undefined;
  }
  return state.customerEmailResources?.items?.filter((q) => q.CustomerEmail === customerEmail);
}; 

export const customerEmailResourcesLoading = createSelector(
  (state: AppState) => state.customerEmailResources.status,
  status => status === 'pending'
);

export const customerEmailResourcesErrorSelector = createSelector(
  (state: AppState) => state.customerEmailResources,
  status => status.error
);

export const { clearCustomerEmailResourceItems } = customerEmailResourcesSlice.actions;

export default customerEmailResourcesSlice.reducer;

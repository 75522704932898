import React from "react";
import { View, Image } from "react-native";
import { StyledText } from "src/styles/text";
import styles from "./coinTile.styles";

export const CoinTile = ({
  price,
  width,
  remoteIconPath,
}: {
  price: string | number;
  width?: number;
  remoteIconPath?: any;
}) => {
  return (
    <View style={[styles.tile, { width }]}>
      {remoteIconPath && (
        <Image style={styles.icon} source={{ uri: remoteIconPath }} />
      )}
      <View>
        <StyledText variant="body1" textStyle={styles.tilePrice}>
          {price}
        </StyledText>
      </View>
    </View>
  );
};

import { useTheme } from "@react-navigation/native";
import React from "react";
import { ActivityIndicator, StyleSheet, View } from "react-native";

function CustomLoader(props: any) {
  const { color, size } = props;
  const { colors } = useTheme();
  return (
    <View style={[styles.container]}>
      <ActivityIndicator
        size={size ?? "large"}
        color={color ?? colors.primary}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
  },
});

export default CustomLoader;

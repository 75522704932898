/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash'; 

import { SettingsCollectionState } from "./settings-collection-state";

import { Settings } from "../../../../../../types/domain/flat-types";
const initialState: SettingsCollectionState = {
  items: undefined,
  status: "idle",
};

export const getSettingss = createAsyncThunk(
  "get/settingss",
  async (options?: { id?: number, language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`settings?id=${options?.id}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);



  export const createSettings = createAsyncThunk(
    "post/settings",
    async (settings: Partial<Settings>) => {
      const client = await fetchClient();
      const { data } = await client.post(`settings/`, { settings });
      return data;
    }
  );


export const updateSettings = createAsyncThunk(
  "put/settings",
  async (settings: Partial<Settings>) => {
    const client = await fetchClient();
    const { data } = await client.put(`settings/`, { settings });
    return data;
  }
);

export const getSettings = createAsyncThunk(
  "get/settings",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`settings/${id}`);
    return data;
  }
);

export const deleteSettings = createAsyncThunk(
  "delete/settings",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.delete(`settings/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 
// const shouldAddToCollection = (payload: any, state: SettingsCollectionState, actionArgs: any) => {

//     const currentStateConstraint = state.items && state.items[0] ? state.items[0].Id : undefined;

//   if(currentStateConstraint && actionArgs.id && Number(actionArgs.id) !== currentStateConstraint)
//     return false; //we're loading a new collection!

//   //no payload data? - then don't alter the existing collection
//   if(!payload || !payload.length || payload.length === 0) 
//     return true;

//   const payloadConstraint = payload && payload[0] ? payload[0].Id : -1;

//   return payloadConstraint === currentStateConstraint;
// };

const settingssSlice = createSlice({
  name: "settingss",
  initialState,
  reducers: {
    clearSettingsItems(state: SettingsCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSettingss.pending, (state: SettingsCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getSettingss.fulfilled, (state: SettingsCollectionState, action) => {

  
      // if (shouldAddToCollection(action.payload, state, action.meta.arg)) {
        state.items = _.unionBy(action.payload, state.items || [], 'Id');
      // } else {
      //   state.items = action.payload;
      // } 
  

      state.status = "fulfilled";
    });
    builder.addCase(getSettingss.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getSettings.pending, (state: SettingsCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(deleteSettings.pending, (state: SettingsCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(updateSettings.pending, (state: SettingsCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getSettings.fulfilled, (state: SettingsCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(deleteSettings.fulfilled, (state: SettingsCollectionState, action) => {
      state.items = state.items?.filter(i => i.Id !== action.meta.arg);
      state.status = "fulfilled";
    });
    builder.addCase(updateSettings.fulfilled, (state: SettingsCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(createSettings.pending, (state: SettingsCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(createSettings.fulfilled, (state: SettingsCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });

    builder.addCase(getSettings.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(deleteSettings.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });
    builder.addCase(updateSettings.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const settingsSelector = (id: number) => (state: AppState) => {
  return state.settingss?.items?.find((o) => o.Id === id);
}; 

export const settingsLoading = createSelector(
  (state: AppState) => state.settingss.status,
  status => status === 'pending'
);

export const settingsErrorSelector = createSelector(
  (state: AppState) => state.settingss,
  status => status.error
);


export const settingssSelector = (id?: number) => (state: AppState) => {
  if (!id) {
    return undefined;
  }
  return state.settingss?.items?.filter((q) => q.Id === id);
}; 

export const settingssLoading = createSelector(
  (state: AppState) => state.settingss.status,
  status => status === 'pending'
);

export const settingssErrorSelector = createSelector(
  (state: AppState) => state.settingss,
  status => status.error
);

export const { clearSettingsItems } = settingssSlice.actions;

export default settingssSlice.reducer;

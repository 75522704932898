import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerActions, NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  // HeaderStyleInterpolators,
  // CardStyleInterpolators,
  createStackNavigator,
} from "@react-navigation/stack";
import React, { Fragment, useEffect } from "react";
import { Platform, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import ConnectionStatus from "src/components/connectionStatus/connectionStatus";
import { staticCommonStyles } from "src/pages/auth/common.styles";
import LoginScreen from "src/pages/auth/login/login";
import ForgottenPasswordScreen from "src/pages/auth/passwordReset/forgotten-pw-email";
import ResetPWScreen from "src/pages/auth/passwordReset/reset-pw-page";
import ChangeEmailVerificationScreen from "src/pages/auth/signUp/changeEmailVerification";
import EmailVerificationScreen from "src/pages/auth/signUp/emailVerification";
import SignUpScreen1 from "src/pages/auth/signUp/signUp1";
import SignUpScreen2 from "src/pages/auth/signUp/signUp2";
import SignUpConfirmationScreen from "src/pages/auth/signUp/signUpConfirmation";
import { TwoFactor } from "src/pages/auth/twoFactor/twoFactor";
import { HomeScreen } from "src/pages/home/home";
import { forceNav, setForceNav } from "src/redux/features/misc/slice";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { themeDefault } from "src/theme";
import { DrawerNavOptions } from "./header/drawerNavOptions";
import { HeaderLeft } from "./header/headerLeft";
import { StackNavHeader } from "./header/stackNavHeader";
import { CustomDrawerContent } from "./menuFooter";
import { RoleScreens } from "./roles/roleScreens";
import { NavProps } from "./types";
import { useAuthed } from "./useAuthed";
import { hiddenOptions } from "./utils/hiddenMenuItem";

const inactiveMillisecondsLogout = process.env
  .REACT_APP_INACTIVITY_LOGOUT_MILLISECONDS
  ? parseInt(process.env.REACT_APP_INACTIVITY_LOGOUT_MILLISECONDS)
  : 1000 * 60 * 60 * 24;

const Drawer = createDrawerNavigator();
const RootDrawer = createDrawerNavigator();
const RootStack = createStackNavigator();
const Stack = createNativeStackNavigator();

const siteName = "Bumba Academy"; //todo: from config

const stackNavHeader = (props: NavProps) => {
  return (
    <StackNavHeader
      navigation={props.navigation}
      screenProps={props}
      route={props.route}
    />
  );
};

const Navigators = (props: NavProps) => {
  return (
    <RootStack.Navigator
      screenOptions={() => ({
        headerShown: true,
        gestureEnabled: false, //see https://nearshoreagile.atlassian.net/browse/BAMEP-150
        // ...TransitionPresets.SlideFromRightIOS, //looks poor on android
        headerMode: "float",
        header: () => stackNavHeader(props),
      })}
    >
      <RootStack.Group>{RoleScreens({ Drawer, RootStack })}</RootStack.Group>
    </RootStack.Navigator>
  );
};

export const Navigation = () => {
  //WARNING: Do NOT use any selectors here that will cause the entire nav stack to re-render unless its absolutely necessary!
  const { authed, noteActivity } = useAuthed({ inactiveMillisecondsLogout });
  const forceNavigation = useSelector(forceNav);
  const dispatch = useDispatch();
  const { userTheme } = useUserInfo();

  useEffect(() => {
    if (Platform.OS === "web" && authed) {
      window.addEventListener("keypress", noteActivity);
      window.addEventListener("mousedown", noteActivity);
    }
    return () => {
      if (Platform.OS === "web") {
        window.removeEventListener("keypress", noteActivity);
        window.removeEventListener("mousedown", noteActivity);
      }
    };
  }, [authed, noteActivity]);

  if (authed === undefined) {
    return null;
  }

  // for web nav support
  const publicLinking = {
    prefixes: [],
    config: {
      screens: {
        Home: "",
        Login: "login",
        TwoFactor: "two-factor",
        EmailVerification: "email-verification/:token",
        SignUp1: "sign-up/name",
        SignUp2: "sign-up/credentials",
        SignUpConfirmation: "sign-up/confirmation",
        ResetPassword: "reset/:token",
        ForgottenPassword: "forgotten-password",
        ChangeEmailVerification: "change-email-verification/:token",
      },
    },
  };
  const authedLinking = {
    prefixes: [],
    config: {
      screens: {
        Main: "",
        Settings: "settings",
        Theme: "settings/theme",
        PersonalDetails: "settings/personal-details",
        PhoneNumber: "settings/personal-details/phone",
        ChangeEmail: "settings/personal-details/email",
        ChangeUsername: "settings/personal-details/username",
        ConfirmNumber: "settings/personal-details/phone/confirm-number",
        Security: "settings/security",
        ChangePassword: "settings/security/password",
        ChangeEmailVerification: "change-email-verification/:token",
        Lessons: "lessons",
        Dashboard: "dashboard",
        LessonPublishedView: "lesson/:lessonPublishedFullId",
        CategoryPublishedView: "category/:categoryPublishedFullId",
        LessonPublishedByCategoryView:
          "category/:categoryPublishedFullId/lesson/:lessonPublishedByCategoryFullId",
        TopicPublishedView: "topic/:topicPublishedFullId",
        LessonEdit: "lessons/:lessonId",
        // LessonEditTranslation: "lessons/:parentLessonId/:translationLessonId",
        Categories: "categories",
        CategoryEdit: "categories/:categoryId",
        Glossary: "glossary",
        TopicEdit: "topics/:topicId",
        Quizzes: "quizzes",
        QuizEdit: "quiz/:quizId",
        QuizQuestionEdit: "quiz/:quizId/question/:quizQuestionId",
        QuizAnswerEdit:
          "quiz/:quizId/question/:quizQuestionId/answer/:quizAnswerId",
        QuizUserScreen:
          "lesson/:lessonPublishedFullId/quiz/:quizPublishedId/question/:questionId",
        QuizResultScreen:
          "lesson/:lessonPublishedFullId/quiz/:quizPublishedId/result",
        Users: "users",
        UserView: "users/:userId",
        Email: "email",
        CustomerEmailEdit: "email/:customerEmailId",
      },
    },
  };

  //todo: this can be expanded to correctly handle in app deep links too
  // ... as right now we can only link to APP_DEEP_LINK_BASE
  const handleDeepLink = (_e: any, route: any, navigation: any) => {
    if (
      window &&
      window.location &&
      window.location.pathname.startsWith("/change-email-verification/") &&
      route.name !== "ChangeEmailVerification" &&
      route.name !== "Main" &&
      forceNavigation !== true
    ) {
      const jumpToAction = DrawerActions.jumpTo("ChangeEmailVerification", {
        token: window.location.pathname.replace(
          "/change-email-verification/",
          "",
        ),
      });

      navigation.dispatch(jumpToAction);
    }
    if (forceNavigation === true) {
      dispatch(setForceNav(false));
    }
  };

  return (
    <Fragment>
      {authed ? (
        <View
          onTouchStart={noteActivity}
          style={{ flex: 1, overflow: "hidden" }}
        >
          <NavigationContainer
            theme={themeDefault}
            linking={authedLinking}
            documentTitle={{
              enabled: true,
              formatter: () => siteName,
            }}
          >
            <RootDrawer.Navigator
              screenOptions={DrawerNavOptions}
              drawerContent={(props) => CustomDrawerContent(props)}
              key={"DrawerNav"}
              // initialRouteName="Dashboard"
              screenListeners={({
                navigation,
                route,
              }: {
                navigation: any;
                route: any;
              }) => ({
                state: (_e) => {
                  handleDeepLink(_e, route, navigation);
                  //temp pending nav-rewire branch
                  if (Platform.OS === "web") {
                    noteActivity(); //todo: long video will not note actvity !
                  }
                },
              })}
            >
              <RootStack.Screen
                name="Main"
                component={Navigators}
                options={hiddenOptions}
              />
              <RootDrawer.Screen
                name="ChangeEmailVerification"
                component={ChangeEmailVerificationScreen}
                options={{ ...hiddenOptions, headerShown: false }}
              />
            </RootDrawer.Navigator>
          </NavigationContainer>
        </View>
      ) : (
        <NavigationContainer
          theme={themeDefault}
          linking={publicLinking}
          documentTitle={{
            enabled: true,
            formatter: () => siteName,
          }}
        >
          <Stack.Navigator
            screenOptions={(props) => ({
              headerShown: true,
              headerTitle: "",
              headerShadowVisible: false,
              headerTransparent: true, // true removes the header spacing and breaks safe area - keep this false!
              contentStyle: staticCommonStyles.container,
              headerStyle: [staticCommonStyles.container],
              title: "",
              headerLeft: () => HeaderLeft(props),
            })}
          >
            <Stack.Screen
              name="Home"
              component={HomeScreen}
              options={{
                headerShown: false,
              }}
            />
            <Stack.Screen name="Login" component={LoginScreen} />
            <Stack.Screen name="TwoFactor" component={TwoFactor} />
            <Stack.Screen name="SignUp1" component={SignUpScreen1} />
            <Stack.Screen name="SignUp2" component={SignUpScreen2} />
            <Stack.Screen
              name="SignUpConfirmation"
              component={SignUpConfirmationScreen}
            />
            <Stack.Screen
              name="ChangeEmailVerification"
              component={ChangeEmailVerificationScreen}
            />
            <Stack.Screen
              name="EmailVerification"
              component={EmailVerificationScreen}
            />
            <Stack.Screen name="ResetPassword" component={ResetPWScreen} />
            <Stack.Screen
              name="ForgottenPassword"
              component={ForgottenPasswordScreen}
            />
          </Stack.Navigator>
        </NavigationContainer>
      )}

      <ConnectionStatus />
    </Fragment>
  );
};

/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { TopicPublishedFullCollectionState } from "./topic-published-full-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Topicpublishedfull } from "../../../../../../types/domain/flat-types";
const initialState: TopicPublishedFullCollectionState = {
  items: undefined,
  status: "idle",
};

export const getTopicPublishedFulls = createAsyncThunk(
  "get/topic-published-fulls",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-published-full?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchTopicPublishedFulls = createAsyncThunk(
  "get/topic-published-fulls",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`topic-published-full-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getTopicPublishedFull = createAsyncThunk(
  "get/topic-published-full",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`topic-published-full/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const topicPublishedFullsSlice = createSlice({
  name: "topicPublishedFulls",
  initialState,
  reducers: {
    clearTopicPublishedFullItems(state: TopicPublishedFullCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopicPublishedFulls.pending || searchTopicPublishedFulls.pending, (state: TopicPublishedFullCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getTopicPublishedFulls.fulfilled || searchTopicPublishedFulls.fulfilled, (state: TopicPublishedFullCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getTopicPublishedFulls.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getTopicPublishedFull.pending, (state: TopicPublishedFullCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getTopicPublishedFull.fulfilled, (state: TopicPublishedFullCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getTopicPublishedFull.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const topicPublishedFullSelector = (id: number) => (state: AppState) => {
  return state.topicPublishedFulls?.items?.find((o) => o.Id === id);
}; 

export const topicPublishedFullLoading = createSelector(
  (state: AppState) => state.topicPublishedFulls.status,
  status => status === 'pending'
);

export const topicPublishedFullErrorSelector = createSelector(
  (state: AppState) => state.topicPublishedFulls,
  status => status.error
);


export const topicPublishedFullsSelector = createSelector(
  (state: AppState) => state.topicPublishedFulls,
  state => state.items
);

export const topicPublishedFullsLoading = createSelector(
  (state: AppState) => state.topicPublishedFulls.status,
  status => status === 'pending'
);

export const topicPublishedFullsErrorSelector = createSelector(
  (state: AppState) => state.topicPublishedFulls,
  status => status.error
);

export const { clearTopicPublishedFullItems } = topicPublishedFullsSlice.actions;

export default topicPublishedFullsSlice.reducer;

import React from "react";
import { Pressable, View } from "react-native";
import useStyles from "./resourceEditNavOption.styles";
import { StyledText } from "src/styles/text";
import IkImageViewer from "src/imagekit/screens/Fetch/ikImageViewer";

const ResourceEditNavOption = ({
  navigation,
  params,
  destination,
  text,
  showIcon,
  iconPath,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  iconPlaceHolder,
  style,
  subText,
}: {
  navigation: any;
  params?: any;
  destination: string;
  text: string;
  showIcon?: boolean;
  iconPath?: string;
  iconPlaceHolder?: string;
  style?: any;
  // eslint-disable-next-line no-undef
  subText?: string | JSX.Element;
}) => {
  const styles = useStyles();
  return (
    <Pressable
      testID={`navOption_${destination}`}
      style={[styles.navOption, style]}
      onPress={() => navigation.navigate(destination, params)}
    >
      <View style={[styles.row]}>
        {showIcon && (
          <>
            {iconPath && (
              <IkImageViewer
                style={{
                  marginRight: 10,
                  borderRadius: 12,
                  overflow: "hidden",
                }}
                imagePath={iconPath}
                height={100}
                width={100}
                transform
              />
            )}
          </>
        )}
        <View
          style={[
            styles.textView,
            showIcon && iconPath ? {} : styles.textViewPadding,
          ]}
        >
          <StyledText
            variant="linkText"
            textStyle={styles.title}
            numberOfLines={2}
            ellipsizeMode="tail"
          >
            {text}
          </StyledText>
          {subText && <View style={styles.categoryView}>{subText}</View>}
        </View>
      </View>
    </Pressable>
  );
};

export default ResourceEditNavOption;

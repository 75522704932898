/* eslint-disable curly */
/* eslint-disable no-trailing-spaces */
/* eslint-disable prettier/prettier */
import {
  createSelector,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { fetchClient } from "src/api/axios/axiosApi";
import { AppState } from "src/redux/reducer/root-reducer";

import _ from 'lodash';  

import { LessonPublishedFullCollectionState } from "./lesson-published-full-collection-state";
import { Search } from '../../../../../../types/search/search';
import { Lessonpublishedfull } from "../../../../../../types/domain/flat-types";
const initialState: LessonPublishedFullCollectionState = {
  items: undefined,
  status: "idle",
};

export const getLessonPublishedFulls = createAsyncThunk(
  "get/lesson-published-fulls",
  async (options?: { language?: number, offset?: number, limit?: number }) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-full?language=${options?.language}&offset=${options?.offset || 0}&limit=${options?.limit || 100}`);
    return data;
  }
);

export const searchLessonPublishedFulls = createAsyncThunk(
  "get/lesson-published-fulls",
  async ({ search, language, offset, limit, filter }: { search: Search, language?: number, offset?: number, limit?: number, filter?: string }) => {
    const client = await fetchClient();
    const { data } = await client.post(`lesson-published-full-search?language=${language || 1}&offset=${offset || 0}&limit=${limit || 100}&filter=${filter || ''}`, { search });
    return data;
  }
);




export const getLessonPublishedFull = createAsyncThunk(
  "get/lesson-published-full",
  async (id: number) => {
    const client = await fetchClient();
    const { data } = await client.get(`lesson-published-full/${id}`);
    return data;
  }
);


// todo: confirm we really no longer need this - now there is no item slice I don't think we do
// 

const lessonPublishedFullsSlice = createSlice({
  name: "lessonPublishedFulls",
  initialState,
  reducers: {
    clearLessonPublishedFullItems(state: LessonPublishedFullCollectionState) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonPublishedFulls.pending || searchLessonPublishedFulls.pending, (state: LessonPublishedFullCollectionState) => {
      state.status = "pending";
    });
    builder.addCase(getLessonPublishedFulls.fulfilled || searchLessonPublishedFulls.fulfilled, (state: LessonPublishedFullCollectionState, action) => {

  
      state.items = _.unionBy(action.payload, state.items || [], 'Id');
  

      state.status = "fulfilled";
    });
    builder.addCase(getLessonPublishedFulls.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });

    builder.addCase(getLessonPublishedFull.pending, (state: LessonPublishedFullCollectionState) => {
      state.status = "pending";
    });

    builder.addCase(getLessonPublishedFull.fulfilled, (state: LessonPublishedFullCollectionState, action) => {
      state.items = _.unionBy([action.payload], state.items || [], 'Id');
      state.status = "fulfilled";
    });


    builder.addCase(getLessonPublishedFull.rejected, (state, { error }) => {
      state.status = 'rejected';
      state.error = error.message;
    });


  },
});

export const lessonPublishedFullSelector = (id: number) => (state: AppState) => {
  return state.lessonPublishedFulls?.items?.find((o) => o.Id === id);
}; 

export const lessonPublishedFullLoading = createSelector(
  (state: AppState) => state.lessonPublishedFulls.status,
  status => status === 'pending'
);

export const lessonPublishedFullErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedFulls,
  status => status.error
);


export const lessonPublishedFullsSelector = createSelector(
  (state: AppState) => state.lessonPublishedFulls,
  state => state.items
);

export const lessonPublishedFullsLoading = createSelector(
  (state: AppState) => state.lessonPublishedFulls.status,
  status => status === 'pending'
);

export const lessonPublishedFullsErrorSelector = createSelector(
  (state: AppState) => state.lessonPublishedFulls,
  status => status.error
);

export const { clearLessonPublishedFullItems } = lessonPublishedFullsSlice.actions;

export default lessonPublishedFullsSlice.reducer;

import React, { useEffect, useState } from "react";
import { fetchClient } from "../../../api/axios/axiosApi";
import url from "url";
import WebFadeIn from "src/components/webFadeIn/webFadeIn";
import { AuthHeader } from "../layout/auth-header";
import { View } from "react-native";
import { StyledText } from "src/styles/text";
import authStyles from "../layout/auth.styles";
import formStyles from "src/components/forms/authForm.styles";
import { AppOrWebLoginButtons } from "src/components/buttons/appOrWebLoginButtons";
import { PageBody } from "src/pages/layout/pageBody";
import { useTheme } from "@react-navigation/native";

//web only

export default function EmailVerificationScreen({
  navigation,
  route,
}: {
  navigation: any;
  route: any;
}) {
  const token = route?.params?.token;
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [err, setErr] = useState("");
  const { colors } = useTheme();

  const verifyEmail = async () => {
    const tokenValues = url.parse("?" + token, true).query;

    setLoading(true);

    const client = await fetchClient();

    client
      .post(`auth/verify/data`, {
        email: tokenValues.u,
        token: token,
      })
      .then(({ data }) => {
        setLoading(false);
        setShowSuccessMessage(true);
      })
      .catch((e) => {
        console.log(e);
        setErr(e.toString());
      });
  };

  useEffect(() => {
    verifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WebFadeIn>
      <PageBody style={{ margin: "auto", backgroundColor: colors.secondary }}>
        <View style={[authStyles.authPage]} testID="email-verification">
          <View style={[formStyles.form]}>
            <AuthHeader title={"Bumba Academy"} preamble={""} />

            {loading && !err && (
              <StyledText variant="body1">Processing...</StyledText>
            )}
            {showSuccessMessage && (
              <>
                <StyledText variant="body1">
                  You have successfully verified your email.
                </StyledText>
                <AppOrWebLoginButtons navigation={navigation} />
              </>
            )}
            {!!err && (
              <View>
                <StyledText variant="body1">
                  Email verification failed
                </StyledText>
                {/* <StyledText variant="body1">{err.toString()}</StyledText> */}
              </View>
            )}
          </View>
        </View>
      </PageBody>
    </WebFadeIn>
  );
}

import { DrawerItem } from "@react-navigation/drawer";
import React, { Fragment, useEffect, useState } from "react";
import { useUserInfo } from "src/redux/features/userInfo/useUserInfo";
import { isAdmin } from "../utils/roleCheck";
import CustomSvgIcon, {
  IconTypes,
} from "src/uikit/customSvgIcon/customSvgIcon";
import useStyles from "../nav.styles";
import { Text, View, Pressable } from "react-native";
import { vars } from "src/styles";
import { useTranslation } from "src/translations/useTranslation";
import { setRouteName } from "src/redux/features/misc/slice";
import { useDispatch } from "react-redux";

export const AdminMenu = (props: any) => {
  const styles = useStyles();
  const { roles } = useUserInfo();
  const [selected, setSelected] = useState("Dashboard");
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const drawerNavScreens: Array<{
    screen: string;
    screenName: string;
    icon: IconTypes;
    iconSelected: IconTypes;
  }> = [
    {
      screen: "Dashboard",
      screenName: t("menu.dashboard"),
      icon: "home-outline",
      iconSelected: "home-filled",
    },
    {
      screen: "Lessons",
      screenName: t("menu.lessons"),
      icon: "lessons-outline",
      iconSelected: "lessons-filled",
    },
    {
      screen: "Quizzes",
      screenName: t("menu.quizzes"),
      icon: "verify-outline",
      iconSelected: "verify-filled",
    },
    {
      screen: "Categories",
      screenName: t("menu.categories"),
      icon: "apps-outline",
      iconSelected: "apps-filled",
    },
    {
      screen: "Glossary",
      screenName: t("menu.glossary"),
      icon: "book-outline",
      iconSelected: "book-filled",
    },
    {
      screen: "Users",
      screenName: t("menu.users"),
      icon: "user-edit",
      iconSelected: "user-edit",
    },
    {
      screen: "Email",
      screenName: t("menu.email"),
      icon: "email-outline",
      iconSelected: "email-filled",
    },
    {
      screen: "Settings",
      screenName: t("menu.settings"),
      icon: "gear-outline",
      iconSelected: "gear-filled",
    },
    // "Upload File",
  ];

  useEffect(() => {
    let screenName = "";

    if (props?.state && !props.largeScreen) {
      screenName =
        props?.state?.routes[0].state?.routes[
          props?.state?.routes[0].state?.routes?.length -
            (props?.state?.routes[0].state?.routes?.length - 1)
        ]?.name;

      if (
        screenName &&
        selected !== screenName &&
        drawerNavScreens.map((item) => item.screen).indexOf(screenName) !== -1
      ) {
        setSelected(screenName);
      }
    } else if (props?.route) {
      screenName = props?.route?.params?.screen || props?.route?.name;
    }

    if (
      screenName &&
      selected !== screenName &&
      drawerNavScreens.map((item) => item.screen).indexOf(screenName) !== -1
    ) {
      setSelected(screenName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.state?.routes, selected]);

  if (!roles || !isAdmin(roles)) {
    return null;
  }

  return (
    <>
      {drawerNavScreens.map((nav, key) => (
        <Fragment key={key}>
          {!props?.largeScreen ? (
            <DrawerItem
              key={key}
              label={nav.screenName}
              icon={() => (
                <CustomSvgIcon
                  iconType={
                    selected === nav.screen ? nav.iconSelected : nav.icon
                  }
                  iconColor={
                    selected === nav.screen ? vars.primaryColor : vars.black
                  }
                  iconSize={20}
                />
              )}
              onPress={() => {
                dispatch(setRouteName(nav.screen));
                props.navigation.navigate("Main", { screen: nav.screen });
              }}
              labelStyle={[
                styles.menuItemLabel,
                selected === nav.screen ? styles.menuItemLabelSelected : {},
              ]}
              style={[
                styles.menuItem,
                selected === nav.screen ? styles.menuItemSelected : {},
              ]}
            />
          ) : (
            <Pressable
              onPress={() => {
                dispatch(setRouteName(nav.screen));
                props.navigation.navigate("Main", { screen: nav.screen });
              }}
              style={styles.webNavItem}
            >
              <View style={styles.webNavItemIcon}>
                <CustomSvgIcon
                  iconType={
                    selected === nav.screen ? nav.iconSelected : nav.icon
                  }
                  iconColor={
                    selected === nav.screen ? vars.primaryColor : vars.black
                  }
                  iconSize={20}
                />
              </View>
              <Text
                style={[
                  styles.webNavItemLabel,
                  selected === nav.screen ? styles.webNavItemLabelSelected : {},
                ]}
              >
                {nav.screen}
              </Text>
            </Pressable>
          )}
        </Fragment>
      ))}
    </>
  );
};

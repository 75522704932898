import React from "react";
import { useTheme } from "@react-navigation/native";
import { Avatar } from "react-native-paper";

const CustomIcon: React.FunctionComponent<Props> = ({
  iconName,
  size,
  color,
  style,
  ...restProps
}) => {
  const { colors } = useTheme();
  return (
    <Avatar.Icon
      icon={iconName}
      size={size || 30}
      color={color || colors.primary}
      style={
        style || {
          backgroundColor: colors.secondary,
          padding: 5,
          marginLeft: 4,
        }
      }
      {...restProps}
    />
  );
};

export default CustomIcon;

import { StyleSheet } from "react-native";

//https://transform.tools/css-to-js

const styles = StyleSheet.create({
  confirmButton: {},
  code: {
    flexDirection: "row",
  },
  codeInput: {
    width: 44,
    height: 60,
    marginRight: 14,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#DCD1D1",
    fontSize: 30,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 38,
  },
  confirmCode: {
    marginTop: 20,
  },
});

export default styles;
